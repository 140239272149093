import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { ModalService } from 'projects/app-shared/src/public-api';
import { ArticulosGeneral, HistorialPedidos } from 'projects/ecommerce-generico/src/public-api';
import { MarianomGeneral } from '../marianom-general.service';

@Component({
  selector: 'app-mmax-tracking-pedidos',
  templateUrl: './mmax-tracking-pedidos.component.html',
  styleUrls: ['./mmax-tracking-pedidos.component.scss']
})
export class MmaxTrackingPedidosComponent implements OnInit {
  @Input('mode') modo: string;
  @Input() displayFactura: string;
  constructor(private serv: MarianomGeneral, private router: ActivatedRoute, private modal: ModalService) {
    this.modo = this.modo || 'normal';
    this.iconreference = '../../assets/icons/checkpedidos.svg';
    this.calendaretiro = '../../assets/icons/date_range-24px-blue.svg';
    this.ubicaSuc = '../../assets/icons/location-pointer-blue.svg';
    this.horaretiro = '../../assets/icons/alarm-blue.svg';

    this.displayFactura = this.displayFactura || 'none';
  }

  async ngOnInit(): Promise<void> {
    this.router.data.subscribe(data => {
      this.modo = data['mode'] || this.modo || 'normal';

    });

    window.addEventListener("beforeunload", function (e) {

      return window.location.hash = "#/";

    });
    this.serv.setImagenModal(this.jistoria.factura);
    await this.serv.returnImagenModal().toPromise().then((data) => {

      let blob = new Blob([data['body']], { type: "application/pdf" });
      this.imagen = blob;
      // this.imagen = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(data['body']));
    })

    if (this.jistoria.estado == 'Cerrado') {
      this.displayFactura = 'none';
    } else {
      this.displayFactura = 'flex'
    }
    this.serv.returnUsaTurnos().subscribe(data => {
      this.usaTurnos = data;
    });
    this.serv.returnHorasMinimasTurno().subscribe(data => {
      this.horasMinimasTurno = data;
    });
    if (this.jistoria.turnos[0].modo == 0) {
      this.retiroEnvio = "Retirar";
      this.direccionPedido = this.sucursal
    } else {
      this.retiroEnvio = "Envio";
      this.direccionPedido = this.jistoria.turnos[0].ciudad + ', ' + this.jistoria.turnos[0].barrio + ', ' + this.jistoria.turnos[0].calle;
    }
    console.log(this.jistoria);
    
  }

  verFactura() {
    var _url = window.URL.createObjectURL(this.imagen);
    window.open(_url, "_blank");
  }

  get jistoria() {
    if (this.serv.getJistorial() as string == "[object Object]") {
      return this.serv.getJistorial()
    } else {
      return JSON.parse(this.serv.getJistorial() as string);
    }
  }
  get articulos(): ArticulosGeneral[] {
    return this.jistoria.myitemsdetalle;
  }
  get turno() {
    let miturno = this.jistoria.turnos[0];
    miturno.mfecha = moment(miturno.fecha);
    miturno.mfecha.locale('es');
    miturno.dia = miturno.mfecha.format("dddd DD[/]MM");

    let hora: string = miturno.horainicio.toString();
    miturno.hora = '';
    if (hora.length == 3) {

      hora = '0' + hora;
    }
    for (let index = 0; index < 4; index++) {

      miturno.hora += hora.charAt(index) || '';
      if (index == 1) {
        miturno.hora += ':';
      }



    }

    return miturno;
  }
  get sucursal() {
    let sucursal = this.jistoria.turnos[0]['sucursal'];

    if (sucursal) {

      return this.serv.getSucuralPorNumero(sucursal);
    }
    return '';
  }

  backToHistorial() {
    this.serv.gotoURL('historial', this.router);
  }

  calendaretiro: string;
  horaretiro: string;
  ubicaSuc: string;
  iconreference: string;
  imagen: any;
  usaTurnos: string;
  horasMinimasTurno: string;
  retiroEnvio: string;
  direccionPedido: string;
}
