import { EventEmitter, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from 'projects/app-shared/src/public-api';
import { ArticulosGeneral, CategoriasWebMenu, EcommerceGenericoService, HistorialPedidos, PedidosMmaxEccomerce, PromocionesEccomerce, ResultadosDeBusquedaInterface, SucursalEcommerce, TurnosRetiroEccomerce, Usuario } from 'projects/ecommerce-generico/src/public-api';
import { Observable } from 'rxjs';
import { MenuMmaxInterface } from './etc/mmax-menu.interface'
import { MyNavigator } from './etc/navegador-class';
import { PCNavigator } from './etc/pcmode-class';
import { CheckboxSubcategoria } from './etc/categoria-checkbox.interface';
import { TagsEcommerce } from 'projects/ecommerce-generico/src/lib/interfaces/tags-eccomerce.interface';

@Injectable({
  providedIn: 'root'
})
export class MarianomGeneral {

  constructor(private serv: EcommerceGenericoService, private router: Router, private modal: ModalService) {

    this.desplegado = false;
    this.desplegadousa = false;
    this.whitelist = new Map();

    this.rutas = [
      {
        nombre: 'Ofertas y Promociones',
        ruta: 'ofertas',
        activo: false,
      }, {

        nombre: 'Mis Compras',
        ruta: 'historial',
        activo: false,
      },
      {
        nombre: 'Marcas Destacadas',
        ruta: 'marcas',
        activo: false,
      },
      {
        nombre: 'Sucursales',
        ruta: 'sucursales',
        activo: false,
      },
      {
        nombre: 'Ayuda',
        ruta: 'ayudas',
        activo: false,
      },

    ];
    /*
    this.serv.resizeObservable$.subscribe( evt => {
      this.resizeEvent();
    });*/
    this.resizeEvent();
    // this.init();
  }
  initMaster() {
    this.whitelist.set('cerrarpedidoweb', true);
    this.whitelist.set('ordenpagoweb', true);
    this.whitelist.set('planesmediosweb', true);
    // this.whitelist.set('pedidoweb', true);
    //this.whitelist.set('itempedidoweb',false);
    //this.whitelist.set('renew',false);
    return this.serv.initmaster.toPromise();
  }
  getPedidoWeb() {
    return this.serv.getPedidoWeb();
  }
  getNombreSitio() {
    return this.serv.getNombreSitio();
  }
  getPublicKeyMP() {
    return this.serv.getPublicKeyMP();
  }
  llamarSpinner() {
    this.modal.open('spinner-modal');
  }

  cerrarSpinner() {
    this.modal.close('spinner-modal');
  }

  checkoutMercadoPago() {
    return this.serv.inicarMercadoPago()
  }

  setObServa(observacion: string) {
    this.serv.setObservacion(observacion);
  }
  getSucuralPorNumero(sucursal: any) {
    return this.serv.getSucursalPorNumero(sucursal);
  }
  ponerTag(tag: TagsEcommerce) {
    this.serv.filtrarTag(tag)
  }
  cerrartag(tag: TagsEcommerce) {
    this.serv.cerrartag(tag);
  }
  private resizeEvent() {
    this.navigator = new PCNavigator(this.router, this);
  }
  reobtenerHistorial() {
    // this.whitelist.set('pedidoweb', true);
    return this.serv.getHistorialDePedidos();
  }

  cachearImg(articulo: ArticulosGeneral) {
    return this.serv.bajarImgaen(articulo);
  }

  cargarEstePedidoEnCart(jistoriaRecompra: HistorialPedidos) {
    this.serv.recompraDeHistorial(jistoriaRecompra);
  }

  getJistorial() {
    return this.serv.getDetalleElegido();
  }

  ordenarpor(id: string) {
    this.serv.ordenarArticulosPor(id);
  }

  filtrarRangoPrecio(max: number, min: number) {
    return this.serv.filtrarRangoPrecio(max, min);
  }

  hasFilter() {
    return this.serv.hasFilter();
  }

  getBusqueda(busqueda: string) {
    return this.serv.getBusqueda(busqueda);
  }

  opArtConModal() {
    // console.log(this.serv.getCantidadTotal());

    if (this.serv.getCantidadTotal() == 0) {
      this.modal.open('articulo-pc-mmax-modal');


      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
      setTimeout(() => {

        this.modal.close('articulo-pc-mmax-modal');
      }, 3000);
    }

  }

  sendErrorMessage(err) {
    this.serv.sendErrorMessage(err);
  }

  getErrorMessage() {
    return this.serv.getErrorMessage();
  }

  pedidoAVer(detalle: HistorialPedidos) {
    this.serv.verPedido(detalle);
  }
  cancelarPedidoWeb() {
    return this.serv.cancelarPedidoWeb();
  }
  borrarPromoCiones() {
    return this.serv.deletePromosCloud();
  }
  getPedidosHistorial() {
    // this.whitelist.set('pedidoweb', true);
    return this.serv.getHistorialProvider();
  }

  setImagenModal(img) {
    this.serv.setImagenModal(img);
  }

  returnImagenModal() {
    return this.serv.getImagenPeticion();
  }

  vaciarCarro() {
    this.serv.resetEccomerce()
  }

  getMedios() {
    return this.serv.devolverMediosPagos();
  }

  obtenerplanesdemedios(mediopago: number) {
    return this.serv.obtenerPlanosdemedios(mediopago);
  }

  prayandpaidtwo(mediopago: number, plan: number) {
    return this.serv.cerrarFinaleccomerce(mediopago, plan);
  }

  getMovimientosMmax() {
    return this.serv.getMovimientosMmax();
  }

  cambiarDepagina(event: number) {
    this.serv.cambiarPagina(event);
  }
  cambiarDepaginaOferta(event: number) {
    this.serv.cambiarPagina(event);
  }
  getPaginas(): Observable<number> {
    return this.serv.getPaginas();
  }
  getPaginaEmitter(emitter: EventEmitter<number>) {
    this.serv.getPaginaEmitter(emitter);
  }
  getArrayPaginas(paginas: any[]) {
    this.serv.getArrayPaginas(paginas);
  }
  cambiarPaginaElegida() {
    return this.serv.cambiarPaginaElegida();
  }
  getPaginasOfertas(): Observable<number> {
    return this.serv.getPaginasOfertas();
  }
  paginadoSuc() {
    this.serv.paginadoSuc();
  }

  isloged() {
    if (this.serv.getUserSync()) {
      return true;
    }
    else {
      return false;
    }

  }


  getResultadoExtras(): Observable<ResultadosDeBusquedaInterface> {
    return this.serv.getExtraResultadosProvider();
  }
  ordenarHistorialPorFecha(data: HistorialPedidos[]): HistorialPedidos[] {
    return this.serv.ordenarporFechaHistorial(data)
  }
  getCategorias() {
    return this.serv.getCategoriasWeb();
  }
  getMarcas() {
    return this.serv.getMarcasDestacadas();
  }
  getURLMap() {
    return this.serv.getURLMap();
  }
  pendingFalse() {
    this.serv.pendingFalse();
  }
  startLogin(username: any, pass: any,): Observable<any> {
    return this.serv.loginStart(username, pass);
  }
  crearUsuario(username: any, dni: any, email: any, password: any, sucursal: any) {
    return this.serv.crearUsuario(username, dni, email, password, sucursal);
  }
  abrirModalLogin() {
    this.modal.open('login-modal-1');
  }
  loginok() {
    this.modal.closeAll();
  }
  forceError(arg0: string) {
    this.router.navigate[arg0];
  }
  reseteccomerce() {
    this.serv.resetEccomerce();
  }
  salirylimpiar(route: ActivatedRoute) {
    this.serv.limpiarysalir();
    this.router.navigateByUrl('/app', { relativeTo: route });
  }
  guardarDatosDePagos(pedido: PedidosMmaxEccomerce) {
    this.serv.guardarpedido(pedido)
  }

  verUltimoPedido() {
    return this.serv.verUltimoPedido();
  }

  artOpened(art) {
    this.serv.getArtOpened(art);
  }

  returnArtOpened() {
    return this.serv.returnArtOpened();
  }

  actualizarSucursal(sucursalSelec: SucursalEcommerce) {
    //console.log(sucursalSelec);
    //cargar articulos de la sucursal selecionada..
    //comprobar los articulos que esten disponibles tambien ... etc
    /*this.serv.activarSucursal(sucursalSelec.id).subscribe(()=>{
      
    });*/
    // console.log("Sucursal Selec: ", sucursalSelec);

    return this.cambiarSucursalV2(sucursalSelec.sucursal.toString());

  }
  marcarCarroConRemplazo(event: CheckboxSubcategoria) {
    this.serv.getCarrito().forEach(k => {

      k.setRemplazo(Number.parseInt(event.id));
      k.getRemplazos().forEach(k => {
        if (k.modo === event.id) {
          k.activo = true;
        }
        else {
          k.activo = false;
        }
      });

      //console.log(k);

    })

  }
  marcarFaltantesConRemplazo(event: CheckboxSubcategoria) {
    this.serv.getCarrito().forEach(k => {

      if (k.getRemplazo() == -1) {
        k.setRemplazo(Number.parseInt(event.id));
        k.getRemplazos().forEach(d => {
          if (d.modo === event.id) {
            d.activo = true;
          }
          else {
            d.activo = false;
          }
        });
      }
    });

  }

  getDatosUsuario() {
    return this.serv.obtenerdatosdeUsuario();
  }

  getDatosUsuarioV2() {
    return this.serv.obtenerdatosdeUsuarioV2();
  }
  cambiarSucursalV2(idSucursal: string) {
    return this.serv.cambiarSucursalV2(idSucursal)
  }
  pedirCambioPass(email: any) {
    return this.serv.pedirCambioPass(email);
  }
  cambiarContraseña(username: any, password: any, resetId: any) {
    return this.serv.cambiarContraseña(username, password, resetId)
  }
  restorePassMmax(username: any, password: any, resetId: any) {
    return this.serv.restorePassMmax(username, password, resetId)
  }
  gotoURL(url: string, route: ActivatedRoute) {
    this.router.navigateByUrl(url, { relativeTo: route });
  }

  returnRedes() {
    return this.serv.returnRedes();
  }

  goLandPage() {
    this.rutas.forEach(k => {
      k.activo = false;
    });
    //this.router.navigateByUrl('/app', { relativeTo: route });
  }
  abrirTurnos() {
    this.modal.open('turnos-mmax-modal-1')
  }

  getRemplasos() {
    return this.serv.getRemplasosProvider();
  }

  getMontoMinimo() {
    return this.serv.getMontoMinimo();
  }

  getPagoDiferido() {
    return this.serv.getPagoDiferido();
  }

  getSelectorName(nombre) {
    this.selectorName = nombre;
  }

  returnSelectorName() {
    return this.selectorName;
  }

  getIsEnvio(envio) {
    this.envio = envio;
  }

  isEnvio() {
    return this.envio;
  }

  getEnvioHabilitado() {
    return this.serv.getEnvioHabilitado();
  }

  getRegistroHabilitado(): Observable<string> {
    return this.serv.getRegistroHabilitado();
  }

  getSucursalDefecto() {
    return this.serv.getSucursalDefecto();
  }
  getSucursalActiva() {
    return this.serv.getSucursalActiva();
  }

  returnBeneficios(): Observable<string> {
    return this.serv.beneficiosTrue();
  }

  returnUsaTurnos() {
    return this.serv.returnUsaTurnos();
  }

  returnHorasMinimasTurno() {
    return this.serv.returnHorasMinimasTurno();
  }

  getCarrouselSlide(): Observable<any[]> {
    return this.serv.returnSlides();
  }

  sincronizeBackup() {
    this.serv.sincronizeBackup();
  }
  /**
   * 
   * carrito //revisar precios
  */
  getCarrito() {
    return this.serv.getCarrito();
  }
  getTotal() {
    return this.serv.getTotal()
  }
  getCantidadTotal() {
    return this.serv.getCantidadTotal();
  }

  activatethis(menus: MenuMmaxInterface) {

    this.rutas.forEach(k => {
      if (k.nombre === menus.nombre) {
        k.activo = true;
        this.cerrarUI();
      }
      else {
        k.activo = false;
      }
    });


  }

  articuloAmostrar(articulo: ArticulosGeneral) {
    this.articuloEnPopUp = articulo;
  }
  getArticuloEnmodal() {
    return this.articuloEnPopUp;
  }
  setRetiroEnvio(modo: number) {
    this.retiroEnvio = modo;
  }
  returnRetiroEnvio() {
    return this.retiroEnvio;
  }
  prayandpaid() {
    let usaTurno = true;
    this.serv.returnUsaTurnos().subscribe(data => {
      if (data == 'N') {
        usaTurno = false;
      }
    });
    if (usaTurno) {
      return this.serv.cerrarPedido(this.turnoselecionado.dia, this.turnoselecionado.horainicio, this.turnoselecionado.fecha, this.turnoselecionado.modo, this.datosEnvio?.ciudad, this.datosEnvio?.barrio, this.datosEnvio?.calle, this.datosEnvio?.numero)
    } else {
      return this.serv.cerrarPedido(1, 900, '2023-05-20T00:00:00-03:00', this.returnRetiroEnvio(), this.datosEnvio?.ciudad, this.datosEnvio?.barrio, this.datosEnvio?.calle, this.datosEnvio?.numero)

    }
  }
  cerrarPromociones(): Observable<any> {
    return this.serv.cerrarPromos();
  }
  /**
 * 
 *  promociones.get
 */
  getPromos() {
    return this.serv.getPromociones();
  }
  ajustarPromo() {
    this.serv.ajustarrpomo();
  }
  sumarPromo(promocion: ArticulosGeneral, numero: number) {
    this.serv.sumarpromo(promocion, numero);
  }
  eliminarPromos(articulo: ArticulosGeneral) {
    this.serv.eliminarPromos(articulo);
  }

  cerrarPedido(dia: number, horainicio: number, fecha: string, modo: number) {
    return this.serv.cerrarPedido(dia, horainicio, fecha, modo);
  }

  getOferta() {
    return this.serv.getOfertas();
  }

  getNumPagOfertas() {
    return this.serv.getNumPagOfertas();
  }

  /*turnos region*/
  getTurnes() {
    return this.serv.turnosdisponibles();
  }
  getId(dimension: number) {
    return this.serv.generarid(dimension);
  }

  getDatosEnvio(envio) {
    this.datosEnvio = envio;
    this.serv.getDatosEnvio(envio);
  }

  returnDatosEnvio() {
    return this.serv.returnDatosEnvio();
  }

  /*
  go Region
  */



  /*
  get Region
  */
  getproviderSucursales() {
    return this.serv.getSucursalesProviders();
  }

  getSucursalActivada() {
    return this.serv.getActualSucursal();
  }
  getSucursales() {
    return this.serv.getSucursales()
  }
  /*
    Search Region
  */
  getFueBusquedaEscrita() {
    return this.serv.getFueBusquedaEscrita();
  }
  inciarbusqueda(event: string) {
    this.serv.iniciarbuqueda(event);
    this.busqueda = event;
    this.refreshProducts = false;
  }
  returnBusqueda() {
    return this.busqueda;
  }
  goToProducts(bool) {
    this.refreshProducts = bool;
  }
  returnRefreshProducts() {
    return this.refreshProducts;
  }
  busquedaCategorias(categoria: CategoriasWebMenu, categoriaPadre: CategoriasWebMenu) {
    return this.serv.busquedaCategoria(categoria, categoriaPadre);
  }
  busxMarcaCodigo(categoria: CategoriasWebMenu, categoriaPadre: CategoriasWebMenu) {
    return this.serv.iniciarbuquedaPorCodigoMarca(categoria, categoriaPadre);
  }
  busquedaXMarca(categoria: CategoriasWebMenu) {
    return this.serv.busquedaXMarca(categoria);
  }
  getLastSearchProvider() {
    return this.serv.getLastSearchProvider();
  }
  getMinisearch() {
    return this.serv.getMiniSearchProvider();
  }
  getNumBusqueda() {
    return this.serv.getNumBusqueda();
  }

  getAnchoPagina(ancho: number) {
    return this.serv.getAnchoPagina(ancho);
  }
  /*
  User Related Functions
  */
  getUserProvider(): Observable<Usuario> {
    return this.serv.getUsuarioProvider();
  }

  setTurno(turno: TurnosRetiroEccomerce) {
    this.turnoselecionado = turno;
  }
  ultimoTurno() {
    return this.turnoselecionado;
  }
  getLogo() {
    return this.serv.getLogo();
  }

  callCiudades() {
    return this.serv.getCiudades();
  }

  getCiudades() {
    return this.serv.returnCiudades();
  }

  /*UI REGION*/
  cerrarUI() {
    this.desplegadocat = false;
    this.desplegado = false;
    this.desplegadousa = false;
    this.desplegadosearch = false;
  }
  desplegaryCerrar(arg0: boolean) {
    this.desplegadocat = arg0;
    this.desplegado = false;
    this.desplegadousa = false;
    this.desplegadosearch = false;
  }
  abrircerrarCart(arg0: boolean) {
    this.desplegadocat = false;
    this.desplegado = arg0;
    this.desplegadousa = false;
    this.desplegadosearch = false;
  }
  desplegaryCerrarusu(arg0: boolean) {
    this.desplegadocat = false;
    this.desplegado = false;
    this.desplegadousa = arg0;
    this.desplegadosearch = false;
  }
  abriryCerrrarSearch(arg0: boolean) {
    this.desplegadocat = false;
    this.desplegado = false;
    this.desplegadousa = false;
    this.desplegadosearch = arg0;
  }

  private turnoselecionado: TurnosRetiroEccomerce;
  desplegado: boolean;
  rutas: MenuMmaxInterface[];
  desplegadocat: boolean;
  desplegadousa: boolean;
  desplegadosearch: boolean;
  articuloEnPopUp: ArticulosGeneral;
  navigator: MyNavigator;
  email: any;
  whitelist: Map<string, boolean>;
  datosEnvio: any;
  slides: any;
  envio: boolean;
  selectorName: string;
  busqueda: string;
  refreshProducts: boolean;
  retiroEnvio: number;
}
