import { Injectable } from '@angular/core';
import { ModalComponent } from './modal/modal.component';

@Injectable()
export class ModalService {

    constructor() {
        this.modals = [];
    }


    closeAll() {
        if (this.modals.length > 0) {
            this.modals.forEach(k => {
                // console.log(k.id);
                if (k.id != "modal-error") {
                    k.close();
                }
            })
        }
    }

    add(modal: any) {
        // add modal to array of active modals
        this.modals.push(modal);
    }

    remove(id: string) {
        // remove modal from array of active modals
        this.modals = this.modals.filter(x => x.id !== id);
    }

    async open(id: string) {
        // open modal specified by id
        //console.log(id);
        var modalabuscar: ModalComponent = undefined;
        // console.log(this.modals);

        for (let modal of await this.modals) {
            // console.log(modal.id);

            if (modal.id === id) {
                modalabuscar = modal;
            }
        }
        //  console.log(modalabuscar);
        if (modalabuscar != undefined && modalabuscar.isOpen == false) {
            modalabuscar.open();
        }

    }

    close(id: string) {
        // close modal specified by id        
        if (this.modals.length > 0) {
            this.modals.forEach(k => {
                // console.log(k.id);
                if (k.id === id) {
                    k.close();
                }
            })
        }

    }


    private modals: ModalComponent[];
}
