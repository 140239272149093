import { Component, HostListener, OnInit } from '@angular/core';
import { MarianomGeneral } from '../../app-shared/marianom-general.service';
import { MenuMmaxInterface } from '../../app-shared/etc/mmax-menu.interface';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ActivatedRoute } from '@angular/router';
import { Usuario } from 'projects/ecommerce-generico/src/public-api';
import { ModalService } from 'projects/app-shared/src/public-api';


@Component({
  selector: 'mariano-maxtopbar',
  templateUrl: './mariano-maxtopbar.component.html',
  styleUrls: ['./mariano-maxtopbar.component.scss'],
  animations: [
    trigger('openClose', [
      // ...
      state('open', style({
        'border-bottom': 'solid 6px #FD0D1B',

      })),
      state('closed', style({
        'transform': ' scaleX(1)',
      })),

      transition('open => closed', [
        animate('250ms ease-in-out')
      ]),
      transition('closed => open', [
        animate('500ms ease-in-out')
      ]),
    ]),
  ],
})

export class MarianoMaxtopbarComponent implements OnInit {

  constructor(private serv: MarianomGeneral, private route: ActivatedRoute, private modal: ModalService) {
    this.scroll = false;
    this.serv.getUserProvider().subscribe(data => {
      this.usuario = data;

    });
  }

  ngOnInit(): void {
    // new Image().src=this.logosrc;

  }

  get nombreSucursal() {
    if (!this.usuario) {
      return ' Sin Definir'
    }
    if (this.usuario.sucursal === 0) {
      return (" Suc " + this.serv.getSucursalDefecto());
    }
    else {
      return this.usuario?.sucursalNombre;
    }
  }

  cerrarModale(nombre) {
    this.modal.close(nombre);
  }

  menuclick(menus: MenuMmaxInterface) {

    if (localStorage.getItem('currentUser') == null || localStorage.getItem('currentUser') == undefined || !this.serv.isloged()) {
      if (menus.ruta == "historial" || menus.ruta == "sucursales") {
        this.serv.abrirModalLogin();
      } else {
        this.serv.gotoURL("/" + menus.ruta, this.route);
        this.serv.activatethis(menus);
      }
    } else {
      this.serv.gotoURL("/" + menus.ruta, this.route);
      this.serv.activatethis(menus);
    }

  }
  iraLand() {
    for (let i = 0; i < this.menus.length; i++) {
      const element = this.menus[i];
      element.activo = false;
    }
    this.serv.navigator.goLandPage(this.route);
    this.serv.cerrarUI();
  }
  get desplegado() {
    return this.serv.desplegado;
  }
  get menus(): MenuMmaxInterface[] {
    return this.serv.rutas
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (document.body.scrollTop > 40 && this.scroll == false) {
      this.scroll = true;
      // console.log('se activo');

    }
    if (document.body.scrollTop < 40 && this.scroll == true) {
      this.scroll = false;
      // console.log('se desactivo');

    }
  }
  get logosrc() {
    return this.serv.getLogo();
  }
  scroll: boolean;
  usuario: Usuario;
}
