import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalService } from 'projects/app-shared/src/public-api';
import { Usuario } from 'projects/ecommerce-generico/src/public-api';
import { MarianomGeneral } from '../marianom-general.service';

@Component({
  selector: 'mis-datos-mmax',
  templateUrl: './misdatosmmax.component.html',
  styleUrls: ['./misdatosmmax.component.scss']
})
export class MisdatosmmaxComponent implements OnInit {
  @Input('mode') modo: string;

  constructor(private serv: MarianomGeneral, private router: ActivatedRoute, private modal: ModalService) {
    this.whatsappsrc = '../../assets/icons/whatsapp-logo.svg';
    this.correosrc = '../../assets/icons/mail.svg';
    this.serv.getUserProvider().subscribe(data => {
      this.user = data;
    });
    this.serv.getRegistroHabilitado().subscribe(data => {
      this.registroHab = data;
    });
    this.serv.returnBeneficios().subscribe(data => {
      this.beneficios = data;
    })
  }

  ngOnInit(): void {
    this.router.data.subscribe(data => {
      this.modo = data['mode'] || this.modo || 'normal';
    });

  }

  cambiarDatos() {
    this.modal.open('modal-no-implemented')
  }

  resetPass() {
    if (this.registroHab == 'S') {
      this.modal.open('send-reset-pass-modal-1');
    }
    if (this.beneficios == 'S') {
      this.modal.open('restore-pass-modal');
    }
  }

  whatsappsrc: string;
  correosrc: string;
  user: Usuario;
  registroHab: string;
  beneficios: string;
}
