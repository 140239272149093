import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalService } from 'projects/app-shared/src/public-api';
import { Imagens } from '../etc/image.class'

@Component({
  selector: 'mmax-tutoriales',
  templateUrl: './mmax-tutoriales.component.html',
  styleUrls: ['./mmax-tutoriales.component.scss']
})
export class MmaxTutorialesComponent implements OnInit {
  @Input('mode') modo: string;

  constructor(private router: ActivatedRoute, private modal: ModalService) {
    this.icons = [
      { src: "../../assets/icons/truck-fast-outline.svg", label: 'Retiro en sucursal' },
      { src: "../../assets/icons/cart-arrow-down.svg", label: 'Como hacer una compra online' },
      { src: "../../assets/icons/credit-card-outline.svg", label: 'Medios de Pago' },
      { src: "../../assets/icons/book-open-page-variant.svg", label: 'Catálogo' },
    ]
    this.modo = this.modo || 'normal';
  }

  ngOnInit(): void {
    this.router.data.subscribe(data => {
      this.modo = data['mode'] || this.modo || 'normal';
    });
  }

  goToTuto() {
    this.modal.open('modal-no-implemented');
  }

  async cambiarColoresSVG(color: string, ruta: string) {

    var miImagenSVG = await fetch(ruta).then((res) => res.text());

    miImagenSVG = miImagenSVG.replace('#039072', color);

    var miImagen = document.getElementById('bubbleico') as HTMLImageElement;

    miImagen.innerHTML = miImagenSVG;
    console.log(miImagen);
    
  }

  icons: Imagens[];
}

