<div *ngIf="modo==='normal'">

    <section>
        <div class="centro">
            <div class="formpart" [ngStyle]="{'display': form}">
                <div class="lal">
                    Restablecer Contraseña
                </div>

                <div class="subtext">
                    Te enviaremos un mail para realizar el cambio de contraseña.
                </div>
                <div class="resetpass" (click)="cambiarPass()">
                    Si no llego el mail, para volver a mandarlo presione aqui.
                </div>
                <div class="formsection">
                    <div class="formcontainer">
                        <form [formGroup]="resetform">
                            <label class="labelform" *ngIf="email == undefined">
                                <span class="labell">
                                    Mail
                                </span>
                                <input class="inputform" autocomplete="email" type="email"
                                    [formControlName]="'email'" />
                                <div *ngIf="formFields.email.touched" class="tooltip">
                                </div>
                            </label>
                            <label class="labelform">
                                <span class="labell">
                                    Codigo de Verificacion
                                </span>
                                <input class="inputform" type="number" [formControlName]="'resetid'" />
                                <div *ngIf="formFields.resetid.touched && formFields.resetid.invalid" class="tooltip">
                                    <div class="tooltiptext" *ngIf="formFields.resetid.errors.required">
                                        Codigo de Verificacion no puede estar vacio.
                                    </div>
                                </div>

                                <!-- <input class="inputform" type="text" [formControlName]="'number'"
                                    autocomplete="number" />
                                <div *ngIf="formFields.number.touched && formFields.number.invalid" class="tooltip">
                                    <div class="tooltiptext" *ngIf="formFields.number.errors.required">
                                        El codigo no puede estar vacio
                                    </div>
                                    <div class="tooltiptext" *ngIf="formFields.number.errors.minlength">
                                        El codigo no debe ser menor a
                                        {{formFields.number.errors.minlength.requiredLength}}
                                    </div>
                                </div> -->

                            </label>

                            <label class="labelform">
                                <span class="labell">
                                    Nueva Contraseña
                                </span>
                                <input class="inputform" type="password" [formControlName]="'password'"
                                    autocomplete="new-password" />
                                <div *ngIf="formFields.password.touched && formFields.password.invalid" class="tooltip">
                                    <div class="tooltiptext" *ngIf="formFields.password.errors.required">
                                        Contraseña no puede estar vacia
                                    </div>
                                    <div class="tooltiptext" *ngIf="formFields.password.errors.minlength">
                                        Contraseña no debe ser menor a
                                        {{formFields.password.errors.minlength.requiredLength}}
                                    </div>
                                </div>
                            </label>
                            <label class="labelform">
                                <span class="labell">
                                    Repetir Contraseña
                                </span>
                                <input class="inputform" type="password" [formControlName]="'repeatpass'"
                                    autocomplete="new-password" />
                                <div *ngIf="formFields.repeatpass.touched && formFields.repeatpass.invalid"
                                    class="tooltip">
                                    <div class="tooltiptext" *ngIf="formFields.repeatpass.errors.required">
                                        Contraseña no puede estar vacia
                                    </div>
                                    <div class="tooltiptext" *ngIf="formFields.repeatpass.errors.minlength">
                                        Contraseña no debe ser menor a
                                        {{formFields.repeatpass.errors.minlength.requiredLength}}
                                    </div>
                                </div>
                            </label>

                            <button [disabled]="!resetform.valid" class="botning" type="submit"
                                (click)="cambiarPassword()">Siguiente</button>
                        </form>
                    </div>
                </div>


            </div>
            <div class="container-carga" [ngStyle]="{'display': loader}">
                <div class="carga-txt">Enviando Mail nuevamente...</div>
                <div class="carga"></div>
            </div>
            <div class="dibugopart">
                <div class="imagecontainer">
                    <img [src]="imagen">
                </div>

            </div>
        </div>


    </section>

</div>