import { Component, Input, OnInit } from '@angular/core';
import { ModalService } from 'projects/app-shared/src/public-api'
import { ArticulosEcommerce, ImagesInterface } from 'projects/ecommerce-generico/src/public-api';
import { MarianomGeneral } from '../../app-shared/marianom-general.service';


@Component({
  selector: 'app-mmaxhome-landpage',
  templateUrl: './mmaxhome-landpage.component.html',
  styleUrls: ['./mmaxhome-landpage.component.scss']
})
export class MmaxhomeLandpageComponent implements OnInit {

  @Input() ofertasDisplay: string;
  @Input() promosDisplay: string;
  @Input() marcasDisplay: string;
  constructor(private serv: MarianomGeneral, private modalserv: ModalService) {
    this.sucursal = []; // que no es una sucursal jaja
    this.ofertasDisplay = this.ofertasDisplay || 'none';
    this.promosDisplay = this.promosDisplay || 'none';
    this.marcasDisplay = this.marcasDisplay || 'none';
  }

  ngOnInit(): void {

    //this.modalserv.open('custom-modal-1');
  }

  closeModal(text) {
    this.modalserv.close(text);

  }
  get ofertas() {
    let ofertas = this.serv.getOferta();
    if (ofertas.length !== 0) {
      this.ofertasDisplay = 'block';
    } else {
      this.ofertasDisplay = 'none';
    }

    let marcas = this.serv.getMarcas();
    if (marcas.length !== 0) {
      this.marcasDisplay = 'block';
    } else {
      this.marcasDisplay = 'none';
    }

    return this.serv.getOferta();

  }

  get promos() {
    let promos = this.serv.getPromos();
    if (promos.length !== 0) {
      this.promosDisplay = 'block';
    } else {
      this.promosDisplay = 'none';
    }

    return this.serv.getPromos();
  }
  sucursal: ArticulosEcommerce[];
}
