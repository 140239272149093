<carrusel-generico-view></carrusel-generico-view>

<section>

    <mmax-tutoriales></mmax-tutoriales>

</section>


<mmax-landpage-frame [nombre]="'Promociones del mes'" [ngStyle]="{display: promosDisplay}"></mmax-landpage-frame>
<section [ngStyle]="{display: promosDisplay}">

    <div>
        <div class="articulosss">
            <lista-productos-view [articulosIn]="promos" [modelo]="'promos-land'"></lista-productos-view>
        </div>
    </div>

</section>


<mmax-landpage-frame [nombre]="'Ofertas del mes'" [ngStyle]="{display: ofertasDisplay}"></mmax-landpage-frame>
<section [ngStyle]="{display: ofertasDisplay}">

    <div>
        <div class="articulosss">
            <lista-productos-view [articulosIn]="ofertas" [modelo]="'ofertas-land'"></lista-productos-view>
        </div>
    </div>

</section>

<mmax-landpage-frame [nombre]="'Marcas destacadas'" [ngStyle]="{display: marcasDisplay}"></mmax-landpage-frame>

<section [ngStyle]="{display: marcasDisplay}">

    <app-marcas-destmmax [mode]="'pc-land'"></app-marcas-destmmax>

</section>


<mmax-helpfootbar></mmax-helpfootbar>

<foo-mariano-max></foo-mariano-max>