import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MenuMmaxInterface } from '../etc/mmax-menu.interface';
import { MarianomGeneral } from '../marianom-general.service';

@Component({
  selector: 'app-mmax-end-line-cp',
  templateUrl: './mmax-end-line-cp.component.html',
  styleUrls: ['./mmax-end-line-cp.component.scss']
})
export class MmaxEndLineCpComponent implements OnInit {
  @Input('mode') modo: string;

  constructor(private serv: MarianomGeneral, private router: ActivatedRoute) {
    this.modo = this.modo || 'normal';
    this.womencart = '../../assets/misc/MujerCarrito.svg';
  }

  ngOnInit(): void {
    // this.serv.reobtenerHistorial();
    this.disableBack();
    
    let mpModal = document.getElementsByClassName("mp-mercadopago-checkout-wrapper") as HTMLCollectionOf<HTMLElement>;
    // mpModal[0].style.cssText = 'display: none;';
    mpModal[0].outerHTML = '';
    
    let bodyPage = document.querySelector('body');
    bodyPage.style.cssText = '';
    
    this.router.data.subscribe(data => {
      this.modo = data['mode'] || this.modo || 'normal';
    });
  }
  get menus(): MenuMmaxInterface[] {
    return this.serv.rutas
  }
  disableBack() {
    window.onhashchange = function () {

      if (window.location.hash === "#/checkout/finalizar") {

        window.location.hash = "#/checkout/finalizadocp";
      }
    }
  }
  volverAchan() {
    for (let i = 0; i < this.menus.length; i++) {
      const element = this.menus[i];
      element.activo = false;
    }
    this.serv.navigator.goLandPage(this.router);
  }
  womencart: string;
}
