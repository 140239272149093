import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MarianomGeneral } from '../marianom-general.service';

@Component({
  selector: 'paginador-mmax-app',
  templateUrl: './paginador-mmax.component.html',
  styleUrls: ['./paginador-mmax.component.scss']
})
export class PaginadorMmaxComponent implements OnInit, OnChanges {
  @Input() paginas: number;
  @Output() paginaElegida: EventEmitter<number>;
  constructor(private serv: MarianomGeneral) {
    this.arrowleft = '../../assets/icons/arrow-left-blue.svg';
    this.arrowright = '../../assets/icons/arrow-right-blue.svg';
    this.paginaElegida = new EventEmitter();
    this.mispaginas = [];
    this.pagine = 1;
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.mispaginas = [];
    // console.log(this.paginas);

    this.paginas = this.paginas || 0;
    for (let index = 0; index < this.paginas; index++) {
      this.mispaginas.push({
        nombre: (index + 1).toString(),
        numero: index,
        activa: false,
      })
      this.mispaginas[0].activa = true;
      this.pagine = 0;
    }
    if (this.paginas == 0) {
      this.mispaginas.push({
        nombre: '1',
        numero: 1,
        activa: true,
      });
    }
  }
  atraspagina() {
    this.cambiarPagina(-1);
    window.scrollTo(0, 0);
  }
  adelantarpagina() {
    this.cambiarPagina(+1);
    window.scrollTo(0, 0);
  }
  cambiarPagina(numero: number) {
    if ((this.pagine + numero) >= 0 && (this.pagine + numero) <= this.paginas) {
      this.pagine += numero;
      this.activarPagina(this.mispaginas[this.pagine])
    }

  }
  ngOnInit(): void {

    //console.log(this.paginasActivas);



  }


  activarPagina(pagina: MMAXPaginas) {
    this.pagine = 0;
    let index = 0;
    this.mispaginas.forEach(k => {
      k.activa = false;
      if (pagina.numero == k.numero) {
        k.activa = true;
        this.pagine = index;
      }
      index++;
    });
    //pagina.activa=true;
    this.paginaElegida.emit(pagina.numero);

    this.serv.getArrayPaginas(this.mispaginas);
    this.serv.getPaginaEmitter(this.paginaElegida);

  }
  arrowleft: string;
  arrowright: string;
  mispaginas: MMAXPaginas[];
  pagine: number;
}
interface MMAXPaginas {
  nombre: string,
  numero: number,
  activa: boolean
}
