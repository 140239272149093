<div *ngIf="modo==='normal'">
    <div (click)="abrirCerrar(); gotoBusqueda('busqueda')" onclick="window.scrollTo(0,0)" class="catego leftmargin noselect">
        <div class="imagmax"></div>
        <div class="textecat">Productos</div>
    </div>
    <div class="container">
        <div [@openClose]="desplegado ? 'open' : 'closed'" class="categoriascontainer">
            <div class="menuses">

                <div (click)="openLogin()" onclick="window.scrollTo(0,0)" [ngStyle]="{'display': login}" class="prediccion">
                    <div class="login">
                        Iniciar Sesión
                    </div>
                </div>

                <div *ngFor="let menu of menues" onclick="window.scrollTo(0,0)" [ngStyle]="{'display': myData}" class="prediccion">
                    <div class="iconocont" (click)="gotoURL(menu.url)">
                        <img [src]="menu.icourl" class="icomobile" [alt]="menu.nombre">
                        <div class="descrip">
                            {{menu.nombre}}
                        </div>
                    </div>
                    <div class="logout" (click)="cerrarSesion()">
                        Cerrar Sesión
                    </div>
                </div>

                <div *ngFor="let menus of menus" (click)="menuclick(menus)" class="menu">
                    <img class="imgMenus" src="../../assets/icons/check (1).svg" alt="IMG">
                    <p class="txtMenus">
                        {{menus.nombre || 'No Disponible'}}
                    </p>
                </div>

            </div>

            <div class="categMenus">

                <categ-viewgeneric [color]="getColor()" *ngFor="let catego of categorias" [categoria]="catego" 
                    [mode]="modo"></categ-viewgeneric>
            </div>
        </div>
    </div>

</div>
