import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalService } from 'projects/app-shared/src/public-api';
import { MarianomGeneral } from '../../marianom-general.service';

@Component({
  selector: 'modal-seleccione-suc',
  templateUrl: './modal-seleccione-suc.component.html',
  styleUrls: ['./modal-seleccione-suc.component.scss']
})
export class ModalSeleccioneSucComponent implements OnInit {

  constructor(private modal: ModalService, private serv: MarianomGeneral, private router: ActivatedRoute) { }

  ngOnInit(): void {
  }

  aceptar() {
    this.modal.close('modal-seleccione-suc');
    this.serv.gotoURL('sucursales', this.router);
  }
}
