<header>
  <title>{{nombre}}</title>
</header>
<router-outlet></router-outlet>

<modal-open-point id="spinner-modal">
  <div normal class="mybody">
    <div id="container-carga">
      <div id="carga"></div>
    </div>
  </div>
</modal-open-point>

<modal-open-point id="modal-no-implemented">
  <div normal>
    <modal-no-implemented></modal-no-implemented>
  </div>
</modal-open-point>