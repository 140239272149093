import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalService } from 'projects/app-shared/src/public-api';
import { MarianomGeneral } from '../../marianom-general.service';

@Component({
  selector: 'modal-salir',
  templateUrl: './modal-salir.component.html',
  styleUrls: ['./modal-salir.component.scss']
})
export class ModalSalirComponent implements OnInit {

  constructor(private serv: MarianomGeneral, private modal: ModalService, private route: ActivatedRoute) { }

  ngOnInit(): void {
  }

  salir() {
    this.serv.salirylimpiar(this.route);
    this.modal.closeAll()
  }

  cancelar() {
    this.modal.close('modal-salir')
  }

}
