import { Component, OnInit } from '@angular/core';
import { ModalService } from 'projects/app-shared/src/public-api';

@Component({
  selector: 'modal-default-mmaxapp',
  templateUrl: './modal-default-mmax.component.html',
  styleUrls: ['./modal-default-mmax.component.scss']
})
export class ModalDefaultMmaxComponent implements OnInit {

  constructor(private modal: ModalService) { 
    this.background="'../../assets/misc/Olas.svg'";
  }

  ngOnInit(): void {
  }
  
  close(){
    this.modal.closeAll();
  }
  background : string;

}
