import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CheckboxSubcategoria } from '../etc/categoria-checkbox.interface';
import { MarianomGeneral } from '../marianom-general.service';

@Component({
  selector: 'app-envios-form',
  templateUrl: './envios-form.component.html',
  styleUrls: ['./envios-form.component.scss']
})
export class EnviosFormComponent implements OnInit {

  @Input() costoTrue: string;

  constructor(private serv: MarianomGeneral, private formBuilder: FormBuilder) {
    this.costoTrue = this.costoTrue || 'none';
    this.ciudades = [
      {
        nombre: 'Cargando ciudades...',
        categorias: []
      }
    ];
    this.serv.getCiudades().subscribe(data => {
      if (data.length != 0) {
        this.ciudadesArray = data;
        // console.log(this.ciudadesArray);

        this.prepararCiudades();
      }
    });
  }

  ngOnInit(): void {
    this.formEnvio = this.formBuilder.group({
      ciudad: ['', [Validators.required]],
      barrio: ['', [Validators.required]],
      calle: ['', [Validators.required]],
      numero: ['', [Validators.required]]
    });
  }

  prepararCiudades() {
    let i = 0;
    this.ciudades = [
      {
        nombre: 'Elegir ciudad',
        categorias: []
      }
    ];
    this.ciudadesArray.forEach(k => {
      let ciudadDisponible: CheckboxSubcategoria = {
        nombre: '',
        activada: false,
        id: '',
      }
      ciudadDisponible.nombre = k.nombre;
      ciudadDisponible.id = (i).toString();
      this.ciudades[0].categorias.push(ciudadDisponible);
      i++;

    });

  }

  ciudadSeleccionada(filtro: CheckboxSubcategoria) {
    this.ciudades[0].categorias.forEach(k => {
      k.activada = false;
    });
    this.ciudades[0].nombre = filtro.nombre;
    filtro.activada = true;

    this.ciudadesArray.forEach(l => {
      if (this.ciudades[0].nombre == l.nombre) {
        this.costoEnvio = l.costo;
        this.codigoPostal = l.cp;
        this.idCiudad = l.ciudad;
        this.costoTrue = 'flex';
      }
    });
    this.datosEnvio();

  }

  datosEnvio() {
    let ciudad = this.idCiudad;
    let barrio = (<HTMLInputElement>document.getElementById("barrio")).value;
    let calle = (<HTMLInputElement>document.getElementById("calle")).value;
    let numero = (<HTMLInputElement>document.getElementById("numero")).value;

    let envio = {
      ciudad: ciudad,
      barrio: barrio,
      calle: calle,
      numero: numero,
      nombreCiudad: this.ciudades[0].nombre,
      costo: this.costoEnvio,
      cp: this.codigoPostal
    }

    // console.log(envio);
    this.serv.getDatosEnvio(envio);

  }

  formEnvio: FormGroup;
  ciudades: any[];
  ciudadesArray: any[];
  costoEnvio: number;
  codigoPostal: number;
  idCiudad: number;
}
