import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalService } from 'projects/app-shared/src/public-api';
import { GenericoSlideAnim } from '../etc/generico-animation'
import { MarianomGeneral } from '../marianom-general.service';
import { MenuMmaxInterface } from '../../app-shared/etc/mmax-menu.interface';

@Component({
  selector: 'carrito-generico',
  templateUrl: './carrito-generico.component.html',
  styleUrls: ['./carrito-generico.component.scss'],
  animations: [GenericoSlideAnim],
})
export class CarritoGenericoComponent implements OnInit {
  @Input('mode') modo: string;
  constructor(private serv: MarianomGeneral, private route: ActivatedRoute, private modal: ModalService) {

    this.modo = this.modo || 'normal';
  }
  get total() {
    return this.serv.getCantidadTotal();
  }

  get totales() {
    return this.serv.getTotal();
  }

  preguntarVaciar() { 
    if (this.total === 0) {
      this.modal.open('modal-agregar-producto');
    }
    else {
      this.modal.open('vaciar-carro-modal-1');
    }
  }

  preguntarVaciarMobile() {
    this.modal.open('vaciar-carro-modal-2');
  }
  ngOnInit(): void {

  }
  get articulosconcantidad() {
    return this.serv.getCarrito();
  }
  get menus(): MenuMmaxInterface[] {
    return this.serv.rutas
  }

  irAresumenPC() {
    // console.log("Total: ", this.total);
    if (this.total != 0) {
      for (let i = 0; i < this.menus.length; i++) {
        const element = this.menus[i];
        element.activo = false;
      }
      this.abriryCerrar();
      this.serv.navigator.irResumenCompra(this.route)
      
    }
    else{
      this.modal.open('modal-agregar-producto');
    }

  }
  irAresumenMobile() {
    this.serv.navigator.irResumenCompra(this.route)
    //this.serv.gotoURL(,this.route);
  }
  volverAtrasMobile() {
    // this.serv.goMobileHome(this.route);
    this.serv.navigator.goLandPage(this.route);
  }

  abriryCerrar() {
    this.serv.abrircerrarCart(!this.desplegado);
  }
  volverAlMenu() {
    this.serv.goLandPage();
    this.serv.navigator.goLandPage(this.route);
  }
  get desplegado(): boolean {
    return this.serv.desplegado;
  }
}
