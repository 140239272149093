<section>
    <div class="formsup">

        <div class="titulosop">
            Ciudad
        </div>
        <!-- <input type="text" id="ciudad" class="inputSup" placeholder="Ingrese su ciudad de residencia" (change)="datosEnvio()"> -->
        <selector-generico *ngFor="let ciudad of ciudades" (onSelect)="ciudadSeleccionada($event)" [mode]="'selector'"
            [catego]="ciudad"></selector-generico>
        <div class="datosCiudad" [ngStyle]="{'display': costoTrue}">
            <div class="titulosop">
                Costo de Envio: <b style="text-indent: 0.5em">${{costoEnvio}}</b>
            </div>
            <div class="titulosop">
                Codigo Postal: <b style="text-indent: 0.5em">{{codigoPostal}}</b>
            </div>
        </div>
        <div class="titulosop">
            Barrio
        </div>
        <input type="text" id="barrio" class="inputSup" placeholder="Ingrese su barrio de residencia"
            (change)="datosEnvio()">
        <div class="titulosop">
            Calle
        </div>
        <input type="text" id="calle" class="inputSup" placeholder="Ingrese su calle de residencia"
            (change)="datosEnvio()">
        <div class="titulosop">
            Número
        </div>
        <input type="text" id="numero" class="inputSup" placeholder="Ingrese su número/altura de calle"
            (change)="datosEnvio()">

    </div>
</section>