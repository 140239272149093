<div *ngIf="modo==='normal'">
    <div class="title">
        <div class="backButton" (click)="backToHistorial()">←</div>
        Pedido <b style="margin-right: 0.5rem; margin-left: 0.5rem;">{{jistoria.idPedido}}</b>
        -
        {{jistoria.fecha}}
    </div>
    <div class="headcontainer extramargin">

        <estados-traking-mmax></estados-traking-mmax>


        <div class="infoextra" *ngIf="usaTurnos == 'S';else noUsaTurnos">
            <div class="infotitle">
                {{retiroEnvio}}
            </div>
            <div class="info">
                <div class="icoinfo">
                    <img [src]="calendaretiro" alt="ok-step">
                </div>
                <div class="labelinfo">
                    {{turno.dia}}
                </div>
            </div>
            <div class="info">
                <div class="icoinfo">
                    <img [src]="horaretiro" alt="reloj">
                </div>
                <div class="labelinfo">
                    {{turno.hora}}
                </div>
            </div>
            <div class="info">
                <div class="icoinfo">
                    <img [src]="ubicaSuc" alt="ubicacion">
                </div>
                <div class="labelinfo">
                    {{direccionPedido}}
                </div>
            </div>
        </div>
    </div>
    <div class="articuloscontainer extramargin">
        <!--
            itemsreales
        -->
        <lista-productos-view [modelo]="'historialDetail'" [articulosIn]="articulos"></lista-productos-view>


    </div>
    <div class="total">
        <div>
            Total: {{jistoria.monto | currency}}
        </div>

        <div [ngStyle]="{'display': displayFactura}" class="noselect btons conticompra" (click)="verFactura()">
            Ver Factura
        </div>

    </div>

</div>
<ng-template #noUsaTurnos>
    <div class="infoextra">
        <div class="infotitle">
            {{retiroEnvio}}:
        </div>
        <div class="info">
            <div class="icoinfo">
                <img [src]="horaretiro" alt="reloj">
            </div>
            <div class="labelinfo">
                Pasadas las {{horasMinimasTurno}}hs a partir del dia {{jistoria.fecha}}
            </div>
        </div>
        <div class="info">
            <div class="icoinfo">
                <img [src]="ubicaSuc" alt="ubicacion">
            </div>
            <div class="labelinfo">
                {{direccionPedido}}
            </div>
        </div>
    </div>
</ng-template>