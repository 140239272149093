<div class="mybox" *ngIf="mode === 'normal'">
    <div class="anotherbox">
        <div class="boxphone" (click)="openArtModal(); artOpened(articulo)">
            <div class="ofertarealnigga" *ngIf='articulo.isdeOferta()'>

                <div class="labeloferta">
                    Oferta.
                </div>

            </div>
            <div class="ofertarealnigga" *ngIf='articulo.misPromo.length !== 0'>

                <div class="labeloferta">
                    {{articulo.misPromo[0].abv}}
                </div>

            </div>

            <div class="imag">
                <img *ngIf="articulo.getImgDownload()" class="imasrc" id="imagsrc" [src]="articulo.getFotoImagen()"
                    loading="lazy" [alt]="articulo.getNombreMarca()">
            </div>
            <div class="anotherandanother">
                <div class="mindesc">
                    {{articulo.getDescripCion()}}
                </div>
                <div class="tittle">
                    {{articulo.getPresentacion()}}
                </div>
                <div class="preciochico">
                    {{articulo.getPrecioPartido() | currency}} {{articulo.getOtrosPrecios()}}
                </div>
                <!-- <span class="tachado" *ngIf='articulo.misPromo.length !== 0'>{{articulo.getPrecio() | currency}}</span> -->
                <span class="tachado" *ngIf='articulo.isdeOferta()'>{{articulo.getPrecio() | currency}}</span>
                <div class="precio">
                    <div class="preciogrande" *ngIf='articulo.isdeOferta() && articulo.misPromo.length !== 0'>
                        {{articulo.getPrecioOferta() | currency}}
                    </div>
                    <div class="preciogrande" *ngIf='articulo.isdeOferta() && articulo.misPromo.length === 0'>
                        {{articulo.getPrecioOferta() | currency}}
                    </div>
                    <div class="preciogrande" *ngIf='articulo.isdeOferta() === false && articulo.misPromo.length !== 0'>
                        {{articulo.getPrecioAmostrar() | currency}}
                    </div>
                    <div class="preciogrande" *ngIf='articulo.isdeOferta() === false && articulo.misPromo.length === 0'>
                        {{articulo.getPrecio() | currency}}
                    </div>
                    <div class="porcantidad">
                        x un
                    </div>
                </div>
            </div>
        </div>
        <boton-sumador class="sumador" (quantyEvent)="sumaevent($event)" [contador]="articulo.getCantidad()">
        </boton-sumador>

    </div>

</div>
<div *ngIf="mode==='postcheckout'" class="boxcheckout" (click)="artOpened(articulo)">
    <img *ngIf="articulo.getImgDownload()" class="checkoutimagen" [src]="articulo.getFotoImagen()" loading="lazy">
    <div class="detallescheckout">

        <div class="descripcheckout">
            {{articulo.getDescripCion()}}
        </div>

        <div class="marcachekout">
            {{articulo.getPresentacion()}}
        </div>

        <div class="unidadescheckout">
            <div>
                {{ articulo.getCantidad()}} unidades
            </div>
            <div *ngIf="articulo.misPromo.length == 0">
                {{ articulo.getPrecioCalculado() | currency}}
            </div>
            <div
                *ngIf="articulo.misPromo.length != 0 && articulo.getCantidad() % articulo.misPromo[0].cantidadenPromo() != 0">
                {{ articulo.getPrecioCalculado() | currency}}
            </div>
            <div
                *ngIf="articulo.misPromo.length != 0 && articulo.getCantidad() % articulo.misPromo[0].cantidadenPromo() === 0">
                {{ articulo.misPromo[0].getPrecioCalculado() | currency}}
            </div>
        </div>
    </div>

</div>

<div *ngIf="mode === 'slim'" class="slimorden border">

    <div class="myboxslim">

        <img *ngIf="articulo.getImgDownload()" class="minimages myimg" [src]="articulo.getFotoImagen()" loading="lazy"
            [alt]="articulo.getNombreMarca()">

        <div class="newcontainer">
            <div (click)="eliminar()" class="eliminarbtn">
                Quitar <span class="naiz">X</span>
            </div>
            <div class="descmini">
                {{articulo.getPresentacion()}}.
            </div>
            <div class="minititle">
                {{articulo.getDescripCion()}}.
            </div>
            <div class="miyoferta">
                <div class="slimorden">
                    <div *ngFor="let oferta of articulo.getPromosActivas()" class="oferta">
                        {{oferta}}
                    </div>
                </div>

            </div>
            <div class="endline">
                <div class="porunidpc">{{articulo.getPrecio() | currency}} u.</div>
                <div class="botonsun">
                    <boton-sumador (quantyEvent)="sumaevent($event)" [modo]="'detail'"
                        [contador]="articulo.getCantidad()"></boton-sumador>
                </div>
                <div *ngIf="articulo.getPromosActivas().length==0 ; else conpromo" class="preciomini">
                    {{ articulo.getPrecioCalculado() | currency}}
                </div>
                <ng-template #conpromo>

                    <div class="preciomini">
                        <span class="tachado">{{(articulo.getPrecio() * articulo.getCantidad()) | currency}}</span>
                        <div class="preciron"
                            *ngIf="articulo.isdePromo === true && articulo.getCantidad() % articulo.misPromo[0].cantidadenPromo() != 0">
                            {{ articulo.getPrecioCalculado() | currency}}
                        </div>
                        <div class="preciron"
                            *ngIf="articulo.isdePromo === true && articulo.getCantidad() % articulo.misPromo[0].cantidadenPromo() === 0">
                            {{ articulo.misPromo[0].getPrecioCalculado() | currency}}
                        </div>
                        <div class="preciron" *ngIf="articulo.isdePromo === false && articulo.isdeOferta() === true">
                            {{ articulo.getPrecioOferta() * articulo.getCantidad() | currency}}
                        </div>
                    </div>
                </ng-template>
            </div>


        </div>

    </div>




</div>


<div *ngIf="mode==='ultraslim'" class="minibox">
    <div class="descuentodel">
        0% de Descuento
    </div>
    <div class="nombredelprod">
        <span class="resaltado"> {{articulo.getNombreMarca()}}</span> {{articulo.getDescripCion()}}.
        {{articulo.getPresentacion()}}
    </div>
    <div class="preciodeofert">
        {{articulo.getPrecio() | currency}}
    </div>
    <img *ngIf="articulo.getImgDownload()" class="carruimages" [src]="articulo.getFotoImagen()" loading="lazy"
        [alt]="articulo.getNombreMarca()">


</div>

<div *ngIf="mode==='detalletrakin'" class="mytabla2">

    <div class="prod2">

        <img class="myimg2" [src]="articulo.getFotoImagen()" loading="lazy">

        <div class="labelgrande2">
            <span class="descrmarca2">{{articulo.getDescripCion()}}. {{articulo.getPresentacion()}}</span>
        </div>

    </div>
    <div class="containerPrecio">
        <div class="preciu center" *ngIf='articulo.isdeOferta()'>
            <span class="tachado" *ngIf='articulo.isdeOferta()'>{{articulo.getPrecio() | currency}}</span>
            {{articulo.getPrecioOferta() | currency}}
        </div>
        <div class="preciu center" *ngIf='articulo.isdeOferta() === false'>
            {{articulo.getPrecio() | currency}}
        </div>
        <div class="canti2 center">
            x {{articulo.getCantidad()}} unidades
        </div>
        <div class="preciureal center"
            *ngIf="articulo.isdePromo === true && articulo.getCantidad() % articulo.misPromo[0].cantidadenPromo() != 0">
            {{ articulo.getPrecioCalculado() | currency}}
        </div>
        <div class="preciureal center"
            *ngIf="articulo.isdePromo === true && articulo.getCantidad() % articulo.misPromo[0].cantidadenPromo() === 0">
            <span class="tachado">{{(articulo.getPrecio() * articulo.getCantidad()) | currency}}</span>
            {{ articulo.misPromo[0].getPrecioCalculado() | currency}}
        </div>
        <div class="preciureal center" *ngIf="articulo.isdePromo === false">
            {{ articulo.getPrecioCalculado() | currency}}
        </div>
    </div>
    <!-- <div class="preciureal2 center">
        {{ articulo.getPrecioCalculado() | currency}} (precio total)
    </div> -->
    <div class="options2 center">

    </div>

</div>

<div *ngIf="mode==='detalle'" class="mytabla">

    <div class="prod">
        <div class="elimin noselect" (click)="elidestalle()">
            <div>
                <img src="../../assets/icons/borrardetalle.svg" loading="lazy"
                    class="eliminarArt" [alt]="'eliminarArt'">
            </div>
            <div class="icolabel">
                Eliminar
            </div>
        </div>
        <img class="myimg imgajuster" [src]="articulo.getFotoImagen()" loading="lazy">

        <div class="labelgrande">
            <span class="descrmarca">{{articulo.getDescripCion()}}. {{articulo.getPresentacion()}}</span>
        </div>

    </div>

    <div class="preciu center" *ngIf='articulo.isdeOferta()'>
        <span class="tachado" *ngIf='articulo.isdeOferta()'>{{articulo.getPrecio() | currency}}</span>
        {{articulo.getPrecioOferta() | currency}}
    </div>
    <div class="preciu center" *ngIf='articulo.isdeOferta() === false'>
        {{articulo.getPrecio() | currency}}
    </div>
    <div class="canti center">
        <boton-sumador (quantyEvent)="sumaevent($event)" [contador]="articulo.getCantidad()" [modo]="'detail'">
        </boton-sumador>
        Unidades
    </div>
    <div class="preciureal center"
        *ngIf="articulo.isdePromo === true && articulo.getCantidad() % articulo.misPromo[0].cantidadenPromo() != 0">
        {{ articulo.getPrecioCalculado() | currency}}
    </div>
    <div class="preciureal center"
        *ngIf="articulo.isdePromo === true && articulo.getCantidad() % articulo.misPromo[0].cantidadenPromo() === 0">
        <span *ngIf="articulo.isdeOferta() === false" class="tachado">{{(articulo.getPrecio() * articulo.getCantidad()) | currency}}</span>
        <span *ngIf="articulo.isdeOferta() === true" class="tachado">{{(articulo.getPrecioOferta() * articulo.getCantidad()) | currency}}</span>
        {{ articulo.misPromo[0].getPrecioCalculado() | currency}}
    </div>
    <div class="preciureal center" *ngIf="articulo.isdePromo === false">
        {{ articulo.getPrecioCalculado() | currency}}
    </div>
    <div class="options center">
        <mmax-input-selector [opciones]="articulo.getRemplazos()" [item]="remplazoselct"
            (onSelect)="categoriaSelec($event)"></mmax-input-selector>
    </div>

</div>

<div (click)="iraSeach();" onclick="window.scrollTo(0,0)" *ngIf="mode==='buqueda'" class="prediccion">
    <div class="icono">
    </div>
    <div class="descrip">
        {{articulo.getDescripCion()}}.
    </div>
</div>

<div class="anotherbox" *ngIf="mode === 'ofertas'">
    <div (click)="openArtModal(); artOpened(articulo)">

        <div class="ofertarealnigga" *ngIf='articulo.isdeOferta()'>

            <div class="labeloferta">
                Oferta.
            </div>

        </div>

        <div class="imag">
            <img *ngIf="articulo.getImgDownload()" class="imasrc" id="imagsrc" [src]="articulo.getFotoImagen()"
                loading="lazy" [alt]="articulo.getNombreMarca()">
        </div>
        <div class="anotherandanother">
            <div class="mindesc">
                {{articulo.getDescripCion()}}
            </div>
            <div class="tittle">
                {{articulo.getPresentacion()}}
            </div>
            <!-- <div class="preciochico">
                    {{articulo.getPrecioPartido() | currency}} {{articulo.getOtrosPrecios()}}
                </div> -->
            <span class="tachado">{{articulo.getPrecio() | currency}}</span>
            <div class="precio">
                <div class="preciogrande">
                    {{( articulo.getPrecioOferta() * 1)| currency}}
                </div>
                <div class="porcantidad">
                    x un
                </div>
            </div>
        </div>
    </div>
    <boton-sumador (quantyEvent)="sumaevent($event)" [contador]="articulo.getCantidad()"></boton-sumador>


</div>

<div class="anotherboxOfertaLand" *ngIf="mode === 'ofertas-land'">

    <!-- <div class="ofertarealnigga" *ngIf='articulo.isdeOferta()'>

        <div class="labeloferta">
            Oferta.
        </div>

    </div> -->

    <div class="imag">
        <img *ngIf="articulo.getImgDownload()" class="imasrc" id="imagsrc" [src]="articulo.getFotoImagen()"
            loading="lazy" [alt]="articulo.getNombreMarca()">
    </div>
    <div class="anotherandanother">
        <div class="mindesc">
            {{articulo.getDescripCion()}}
        </div>
        <div class="tittle">
            {{articulo.getPresentacion()}}
        </div>
        <!-- <div class="preciochico">
                {{articulo.getPrecioPartido() | currency}} {{articulo.getOtrosPrecios()}}
            </div> -->
        <span class="tachado">{{articulo.getPrecio() | currency}}</span>
        <div class="precio">
            <div class="preciogrande">
                {{(articulo.getPrecioOferta() * 1) | currency}}
            </div>
            <div class="porcantidad">
                x un
            </div>
        </div>
    </div>
    <boton-sumador (quantyEvent)="sumaevent($event)" [contador]="articulo.getCantidad()"></boton-sumador>


</div>

<div class="ofertacard" *ngIf="mode === 'promos' && articulo.getPrecioAmostrar() != 0">
    <div class="contenidoofert">
        <div class="oferlogo">
            {{articulo.getTipoPromo()}}
        </div>
        <div class="ofertext">
            {{articulo.getDescripCion()}}
        </div>
        <div class="imagepr">
            <img class="img-pr" *ngIf="articulo.getImgDownload()" [src]="articulo.getFotoImagen()" loading="lazy"
                [alt]="articulo.getDescripCion()">
        </div>
        <div class="descriofer">
            <div class="descprod">

            </div>
            <div class="cantidadofer">
                <!--$ x Lt. 100-->

            </div>
            <div class="precie">
                {{articulo.getPrecioAmostrar() | currency}}
            </div>
            <div class="btnarena">
                <boton-sumador (quantyEvent)="sumaevent($event)" [contador]="articulo.getCantidad()">
                </boton-sumador>
            </div>

        </div>

    </div>


</div>

<div class="layout-mobile" *ngIf="mode==='precheckout'">
    <div class="remplazosview">
        <img *ngIf="articulo.getImgDownload()" class="myimg" [src]="articulo.getFotoImagen()" loading="lazy">
        <div class="detalles">
            <div class="leyendaprecheck">
                {{articulo.getDescripCion()}}
            </div>
            <div class="presecheckout">
                {{articulo.getPresentacion()}}
            </div>

            <div class="remplazadorchekout">

            </div>
        </div>
        <div class="unidades">
            <div class="preciocheckoutmobile">
                {{ articulo.getPrecioCalculado() | currency}}
            </div>
            <div class="blueboton">
                {{articulo.getCantidad()}} un.
            </div>
        </div>
    </div>
    <div class="sectorremplz">
        Reemplazo:
        <selector-generico [mode]="'inputSel'" [remplazos]="misremplasos" [seleRem]="articulo.getRemplazo()"
            (onSelect)="categoriaSelec($event)"></selector-generico>
    </div>

</div>