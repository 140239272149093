import { Component, Input, OnInit } from '@angular/core';
import { MarianomGeneral } from '../../app-shared/marianom-general.service';
import { CheckboxSubcategoria, CheckboxCategorias } from '../../app-shared/etc/categoria-checkbox.interface';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'mmax-ofertasview',
  templateUrl: './mmax-ofertasview.component.html',
  styleUrls: ['./mmax-ofertasview.component.scss']
})
export class MmaxofertasviewComponent implements OnInit {
  @Input('mode') modo: string;
  @Input() ofertasDisplay: string;
  @Input() promosDisplay: string;
  constructor(private serv: MarianomGeneral, private router: ActivatedRoute) {
    this.modo = this.modo || 'normal';
    this._destroyed$ = new Subject();
    this.numeropaginas = 0;
    this.ofertasDisplay = this.ofertasDisplay || 'none';
    this.promosDisplay = this.promosDisplay || 'none';
   
  }
  
  ngOnInit(): void {
    this.router.data.subscribe(data => {
      this.modo = data['mode'] || this.modo || 'normal';

    })
    console.log();
    
    let congas: CheckboxSubcategoria = {
      nombre: 'Con Gas',
      activada: false,
    }
    let singas: CheckboxSubcategoria = {
      nombre: 'Sin Gas',
      activada: false,
    }
    this.gaseosas = [
      {
        nombre: 'Tipo de productos',
        categorias: [congas, singas]
      }
    ]

  }

  get ofertas() {
    let ofertas = this.serv.getOferta();
    if (ofertas.length !== 0) {
      this.ofertasDisplay = 'block';
    } else {
      this.ofertasDisplay = 'none';
    }

    return this.serv.getOferta();

  }

  get promos() {
    let promos = this.serv.getPromos();
    if (promos.length !== 0) {
      this.promosDisplay = 'block';
    } else {
      this.promosDisplay = 'none';
    }

    return this.serv.getPromos();
  }
  
  gaseosas: CheckboxCategorias[];
  private _destroyed$: Subject<any>;
  numeropaginas: number;

}
