<div *ngIf="modelo==='normal'" class="mycontainer" onload="anchoPag()">

  <div class="uplabel">
    <div class="categoria">
      <div *ngFor="let cate of categoriasClickeables ; index as i" class="mnsjae">

        <div (click)="clickOnCate(cate.categoria,cate.activable)" class="myborder"
          *ngIf='(categoriasClickeables.length-1)!=i'>
          {{cate.nombre}} >
        </div>
        <div class="myborder active" *ngIf='(categoriasClickeables.length-1)==i'>
          {{cate.nombre}}
        </div>

      </div>
    </div>

  </div>
  <div class="uplabel">
    <div class="mnsjae" *ngIf="fueBusquedaEscrita === false">
      {{numBusqueda}} resultados en “{{myExtrasResultado.textodebusqueda}}”
    </div>
    <div class="mnsjae" *ngIf="fueBusquedaEscrita === true">
      {{myExtrasResultado.totaldeArticulos}} resultados en “{{myExtrasResultado.textodebusqueda}}”
    </div>
  </div>

  <div class="contanerselectors">
    <div class="ordenarpor">
      <div class="labelfiltro">
        Ordenar por:
      </div>
      <selector-generico *ngFor="let item of gaseosas" (onSelect)="selecionado($event)" [mode]="'selector'"
        [catego]="item"></selector-generico>
    </div>
    <div class="rangoprecio">
      <div class="labelfiltro">Precio: </div>
      <input class="inputPrecio" id="precioMin" type="number" placeholder="Minimo" (change)="precioMin()">
      <div class="preciosline">
        -
      </div>
      <input class="inputPrecio" id="precioMax" type="number" placeholder="Maximo" (change)="precioMax()">
      <button class="btnPrecio" (click)="rangoPrecio()">
        <p class="labelPrecio" id="labelPrecio">></p>
      </button>
    </div>
  </div>
  <!-- <div class="uplabel">
    <div class="filtradopor">
      Filtrado por:
    </div>
    <div [ngClass]="{'activotag': item.selecionado}" (click)="cerrarTag(item)" *ngFor="let item of tags" class="tag">
      <div> {{item.nombre}} </div>

    </div>
  </div> -->

  <div *ngIf="articulos.length>0; else sinArticulos" class="articulos" id="articulos">

    <productos-view *ngFor="let item of articulos" [articulo]="item"></productos-view>
  </div>

  <ng-template #sinArticulos>
    <div class="contanerselectors articulos">
      <div class="mnsjae">
        No se encontraron resultados para su búsqueda.
      </div>
    </div>


  </ng-template>

  <div class="contanerselectors" style="justify-content: center;">
    <paginador-mmax-app [paginas]="numeropaginas" (paginaElegida)="onPageSelect($event)"> </paginador-mmax-app>
  </div>
</div>

<div *ngIf="modelo==='postcheckout'" class="checkoutblock">
  <productos-view *ngFor="let item of articulos" [articulo]="item" [mode]="'postcheckout'"></productos-view>
</div>

<div *ngIf="modelo==='promos-land'" class="slimarticulos" id="slimarticulosPromos">

  <ng-container *ngFor="let item of articulos ; let i = index">
    <!-- <productos-view class="produc-view" [mode]="'promos'"
      *ngIf="((articulos[(i-1)]!==undefined) && (i-1===nActCarrusel-1))" [articulo]="articulos[i-1]"></productos-view> -->
    <productos-view class="produc-view" [mode]="'promos'" [articulo]="item"></productos-view>
    <!-- <productos-view class="produc-view" [mode]="'promos'"
      *ngIf="((articulos[(i+1)]!==undefined) && (i+1==nActCarrusel+1))" [articulo]="articulos[i+1]"></productos-view>
    <productos-view class="produc-view" [mode]="'promos'"
      *ngIf="((articulos[(i+2)]!==undefined) && (i+2==nActCarrusel+2))" [articulo]="articulos[i+2]"></productos-view> -->
    <!--<productos-view [mode]="modelo" *ngIf="((articulos[(i+3)]!==undefined) && (i+3==nActCarrusel+3))" [articulo]="articulos[i+3]"></productos-view>-->
  </ng-container>
  <button class="control prev" (pointerdown)="onPreviousClickPr()">
    <span class="arrow left"></span>
  </button>
  <button class="control next" (pointerdown)="onNextClickPr()">
    <span class="arrow right"></span>
  </button>

</div>

<div *ngIf="modelo==='ofertas-land'" class="slimarticulos" id="slimarticulosOfertas">

  <ng-container *ngFor="let item of articulos ; let i = index">
    <!-- <productos-view class="produc-view" [mode]="'ofertas'"
    *ngIf="((articulos[(i-2)]!==undefined) && (i-2==nActCarrusel-2))" [articulo]="articulos[i-2]"></productos-view>
    <productos-view class="produc-view" [mode]="'ofertas'"
      *ngIf="((articulos[(i-1)]!==undefined) && (i-1===nActCarrusel-1))" [articulo]="articulos[i-1]"></productos-view> -->
    <productos-view class="produc-view" [mode]="'ofertas-land'" [articulo]="item"></productos-view>
    <!-- <productos-view class="produc-view" [mode]="'ofertas'"
      *ngIf="((articulos[(i+1)]!==undefined) && (i+1==nActCarrusel+1))" [articulo]="articulos[i+1]"></productos-view>
    <productos-view class="produc-view" [mode]="'ofertas'"
      *ngIf="((articulos[(i+2)]!==undefined) && (i+2==nActCarrusel+2))" [articulo]="articulos[i+2]"></productos-view> -->
  </ng-container>
  <button class="control prev" id="prev" (pointerdown)="onPreviousClickOf()">
    <span class="arrow left"></span>
  </button>
  <button class="control next" id="next" (pointerdown)="onNextClickOf()">
    <span class="arrow right"></span>
  </button>

</div>

<div *ngIf="modelo==='resumendetail'">

  <div class="mytabla header">
    <div class="prod center">
      Producto
    </div>
    <div class="preciu center">
      Precio Unitario
    </div>
    <div class="canti center">
      Cantidad
    </div>
    <div class="preciureal center">
      Precio Total
    </div>
    <div class="prodInfo center">
      Productos Info.
    </div>
    <div class="options center" id="options">
      <selector-generico id="selector" [mode]="'selector'" *ngFor="let item of remplasos" [catego]="item"
        (onSelect)="categoriaSelec($event)"></selector-generico>
    </div>
  </div>
  <div class="tablacontainer">
    <productos-view [mode]="'detalle'" *ngFor="let item of articulos" [articulo]="item"></productos-view>
  </div>

</div>

<div *ngIf="modelo==='historialDetail'">

  <div class="mytabladetail header">
    <div class="prod center">
      Producto
    </div>
    <div class="preciu center">
      Precio Unitario
    </div>
    <div class="canti center">
      Cantidad
    </div>
    <div class="preciureal center">
      Precio Total
    </div>
    <div class="prodInfo center">
      Productos Info.
    </div>
    <div class="options center">

    </div>
  </div>
  <div class="tablacontainer">
    <productos-view [mode]="'detalletrakin'" *ngFor="let item of articulos" [articulo]="item"></productos-view>
  </div>

</div>

<div *ngIf="modelo==='oferta'" class="ofertas-capa">
  <div class="ofertas">
    <productos-view *ngFor="let item of ofertas" [articulo]="item" [mode]="'ofertas'" class="promosView" style="margin-bottom: 1em;">
    </productos-view>
  </div>
  <div class="contanerselectors" style="justify-content: center;">
    <paginador-mmax-app [paginas]="numeropaginasOfertas" (paginaElegida)="onPageSelectOferta($event)"> </paginador-mmax-app>
  </div>
</div>

<div *ngIf="modelo==='promociones'" class="promos">
  <productos-view *ngFor="let promos of articulos" [articulo]="promos" [mode]="'promos'" class="promosView">
  </productos-view>
</div>