import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SucursalEcommerce, TurnosRetiroEccomerce, Usuario } from 'projects/ecommerce-generico/src/public-api';
import { MarianomGeneral } from '../marianom-general.service';
import { PedidosMmaxEccomerce } from 'projects/ecommerce-generico/src/public-api';
import { timeStamp } from 'console';
import { ModalService } from 'projects/app-shared/src/public-api';

@Component({
  selector: 'app-mmax-checkout-compra',
  templateUrl: './mmax-checkout-compra.component.html',
  styleUrls: ['./mmax-checkout-compra.component.scss']
})
export class MmaxCheckoutCompraComponent implements OnInit {
  @Input('mode') modo: string;
  @Input() displayEnvio: string;
  @Input() displayRetiro: string;
  @Input() envioHab: string;
  constructor(private router: ActivatedRoute, private serv: MarianomGeneral, private modal: ModalService) {
    this.modo = this.modo || 'normal';
    this.displayEnvio = this.displayEnvio || 'none';
    this.displayRetiro = this.displayRetiro || 'none';
    this.envioHab = this.envioHab || 'none';
    this.desplegado = false;
    this.sucTrue = true;
    this.serv.getUserProvider().subscribe(data => {

      this.user = data;

    });
    this.serv.getSucursalActivada().subscribe(sucs => {
      this.serv.getUserProvider().subscribe(data => {
        if (data.sucursal && sucs.sucursal) {
          this.sucusarl = sucs;
        } else {
          this.sucTrue = false;
        }
      })
    });
    this.serv.getEnvioHabilitado().subscribe(data => {
      this.envioHabilitado = data
      if (this.envioHabilitado === "S") {
        this.envioHab = 'flex';
        this.serv.callCiudades().subscribe(data => {});
      }
      else {
        this.envioHab = 'none';
      }
    });
    this.serv.getPagoDiferido().subscribe(data => {
      this.pagoDiferido = data
    });
    this.serv.returnUsaTurnos().subscribe(data => {
      this.usaTurnos = data;
    });
    this.serv.returnHorasMinimasTurno().subscribe(data => {
      this.horasMinimasTurno = data;
    })
  }

  ngOnInit(): void {
    this.observacion = undefined;
    this.router.data.subscribe(data => {
      this.modo = data['mode'] || this.modo || 'normal';
    });

  }

  setRetiroEnvio(modo: number) {
    this.serv.setRetiroEnvio(modo);
  }

  get articulosconcantidad() {
    return this.serv.getCarrito();
  }

  get totales() {
    return this.serv.getTotal();
  }
  get total() {
    return this.serv.getCantidadTotal();
  }

  turnoselect(turnoselect) {
    this.turnoselecionado = turnoselect;
    this.serv.setTurno(this.turnoselecionado);
  }
  setupExclusiveCheckboxes(checkbox1Id: string, checkbox2Id: string) {
    const checkbox1 = document.getElementById(checkbox1Id) as HTMLInputElement;
    const checkbox2 = document.getElementById(checkbox2Id) as HTMLInputElement;

    checkbox1.addEventListener('change', (event) => {

      const target = event.target as HTMLInputElement;

      if (this.isOn1) {
        checkbox1.checked = true;
      }
      if (target.checked) {
        checkbox2.checked = false;
        this.isOn2 = false;
        this.isOn1 = true;
      }
    });

    checkbox2.addEventListener('change', (event) => {

      const target = event.target as HTMLInputElement;
      if (this.isOn2) {
        checkbox2.checked = true;
      }
      if (target.checked) {
        checkbox1.checked = false;
        this.isOn1 = false;
        this.isOn2 = true;
      }
    });
  }

  envio() {
    this.displayRetiro = 'none';
    this.displayEnvio = 'block';
    this.setupExclusiveCheckboxes('displayEnvio', 'displayRetiro');
    this.isEnvio = true;
    this.serv.getIsEnvio(this.isEnvio);
  }

  retiro() {
    this.displayEnvio = 'none';
    this.displayRetiro = 'block';
    this.setupExclusiveCheckboxes('displayEnvio', 'displayRetiro');
    this.isEnvio = false;
    this.serv.getIsEnvio(this.isEnvio);
  }

  abrirTurnos() {
    this.serv.abrirTurnos()
  }
  cerrarPedido() {
    this.serv.setTurno(this.turnoselecionado);
    this.observaCion();
    this.serv.borrarPromoCiones().subscribe((data) => {

      this.serv.cerrarPromociones().subscribe((data) => {

        this.serv.prayandpaid().subscribe(data => {
          let pedido = data['pedido'] as PedidosMmaxEccomerce;
          // console.log(pedido);
          this.serv.guardarDatosDePagos(pedido);
          // this.serv.reobtenerHistorial();
          if (this.pagoDiferido === "E") {
            this.serv.navigator.endline(this.router);
            this.serv.reseteccomerce();
          }
          else {
            this.serv.navigator.cierreCheckout(this.router);
          }

        });
      });


    });


  }
  observaCion() {
    this.observacion = (<HTMLInputElement>document.getElementById("obs")).value;
    this.serv.setObServa(this.observacion);

  }
  cerrarPedidoMobile() {
    this.observaCion();
    this.serv.setTurno(this.turnoselecionado);
    this.serv.navigator.cierreCheckout(this.router)

  }
  iraLand() {
    this.serv.navigator.irResumenCompra(this.router)
    this.serv.cerrarUI();
  }

  modalCheckout() {
    this.modal.open('confirmar-checkout-modal-1');
  }

  irASuc() {
    this.serv.gotoURL('sucursales', this.router)
  }


  observacion: string;
  turnoselecionado: TurnosRetiroEccomerce;
  user: Usuario;
  sucusarl: SucursalEcommerce;
  desplegado: boolean;
  envioHabilitado: string;
  pagoDiferido: string;
  isOn1: boolean;
  isOn2: boolean;
  isEnvio: boolean;
  usaTurnos: string;
  horasMinimasTurno: string;
  sucTrue: boolean;
}
