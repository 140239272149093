
import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http'
import * as moment from 'moment';
import * as hash from 'hash.js';
import jwtDecode from "jwt-decode";
import { BehaviorSubject, forkJoin, Observable, of, Subscriber, Subscription, timer, empty } from 'rxjs';
import { concatAll, first, map, subscribeOn, tap, switchMap, elementAt, catchError } from 'rxjs/operators';
import { SucursalEcommerce } from './interfaces/sucursales-eccomerce.interface'
import { ArticulosEcommerce } from './interfaces/articulos-ecommerce.interface';
import { Usuario } from './interfaces/usuario.interface';
import { PromocionesEccomerce } from './class/promociones-class';
import { CategoriasWebMenu, MarcasEcommerce, BackUptArticulo } from '../public-api';
import { ArticulosGeneral } from './class/articulos-general.interface';
import { PorcentajePromo } from './class/porcentaje-promo.class';
import { MontoPromo } from './class/monto-calculado-promo';
import { DiferenciaPromo } from './class/diferencia-promo.class';
import { RemplazosMMax } from './interfaces/remplazos-ecommerce.interface';
import { PedidosMmaxEccomerce } from './interfaces/pedidos-eccomerce.interface';
import { ResultadosDeBusquedaInterface } from './interfaces/resultado-busqueda.interface';
import { HistorialPedidos } from './interfaces/historial-ecommerce-mmax';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { OfertasEcommerce } from './interfaces/ofertas-ecommerce.interface';
import { TagsEcommerce } from './interfaces/tags-eccomerce.interface';
import { ModalService } from 'projects/app-shared/src/public-api';
import { Cloudinary, CloudinaryImage } from '@cloudinary/url-gen';
import { Transformation } from '@cloudinary/url-gen';

// Import required actions.
import { thumbnail, scale } from '@cloudinary/url-gen/actions/resize';
import { byRadius } from '@cloudinary/url-gen/actions/roundCorners';
import { sepia } from '@cloudinary/url-gen/actions/effect';
import { source } from '@cloudinary/url-gen/actions/overlay';
import { opacity, brightness } from '@cloudinary/url-gen/actions/adjust';
import { byAngle } from '@cloudinary/url-gen/actions/rotate'

// Import required qualifiers.
import { image } from '@cloudinary/url-gen/qualifiers/source';
import { Position } from '@cloudinary/url-gen/qualifiers/position';
import { compass } from '@cloudinary/url-gen/qualifiers/gravity';
import { focusOn } from "@cloudinary/url-gen/qualifiers/gravity";
import { FocusOn } from "@cloudinary/url-gen/qualifiers/focusOn";

@Injectable()

export class EcommerceGenericoService {
  imgn: any;
  img: CloudinaryImage
  constructor(private httpClient: HttpClient, private sanitizer: DomSanitizer, private modal: ModalService) {
    this.remplazos = [];
    this.sucursales = [];
    this.articulosSucursalDefecto = [];
    this.promocionesEccomerce = [];
    this.sucursalactivatemp = new BehaviorSubject(undefined);
    this.environment = undefined;
    this.environmenturl = undefined;
    this.user = undefined;
    this.userProvider = new BehaviorSubject(undefined);
    this.sucursalestemp = new BehaviorSubject([]);
    this.lastSearch = new BehaviorSubject([]);
    this.minisearch = new BehaviorSubject([]);
    this.paginadorProvider = new BehaviorSubject(1);
    this.paginadorProviderOfertas = new BehaviorSubject(1);
    this.remplasosProvider = new BehaviorSubject([]);
    this.resultadodeBusqueda = new BehaviorSubject(undefined);
    this.contador = 0;
    this.nombreSitio = "";
    this.colores = [];
    this.tareas = [];
    this.ofertas = [];
    this.ofertasEnlazadas = [];
    this.ofertasTemp = new BehaviorSubject([]);
    this.idInterval = window.setInterval(() => { this.syncronizer() }, 12000);
    this.idIntervalForCart = window.setInterval(() => { this.cartSyncronizer() }, 1000);
    this.intervals = [this.idInterval, this.idIntervalForCart];
    this.categoriasWeb = [];
    this.marcasDestacadas = [];
    this.articulosdelServidor = [];
    this.carritoObservables = [];
    this.resultadosDebusqueda = [];
    this.historiaPedidos = [];
    this.tags = [];
    this.tagsActivos = new Map();
    this.historialtemp = new BehaviorSubject(this.historiaPedidos);
    this.fueBusquedaEscrita = true;
    this.oldSort = [];
    this.busqueda = "";
    this.filterOn = false;
    this.lastArtOpened;
    this.articulos2 = [];
    this.carrouselSlide = new BehaviorSubject([]);
    this.registroHabilitado = new BehaviorSubject(undefined);
    this.beneficios = new BehaviorSubject(undefined);
    this.publicKeyMP = undefined;
    this.pagoDiferido = new BehaviorSubject(undefined);
    this.envioHabilitado = new BehaviorSubject(undefined);
    this.ciudades = new BehaviorSubject([]);
    this.usaTurnos = new BehaviorSubject(undefined);
    this.horasMinimasTurno = new BehaviorSubject(undefined);
    this.slidesArray = [];
    this.slidesArrayObservable = new BehaviorSubject([]);
    this.pendingPedidos = false;
    this.pendingTurnos = false;
    this.pendingLogin = false;
    this.pendingBeneficios = false;
    this.pendingRestore = false;
    this.pendingChangePass = false;
  }



  initmaster = new Observable<void>(sus => {
    this.getJSON().subscribe(data => {
      this.environment = { endpoint: data['endpoint'], endpointversion: data['endpointversion'] };
      this.environmenturl = new URL(data['endpointversion'], data['endpoint']);
      localStorage.setItem('environment', this.environmenturl.toString())
      //console.log(this.environmenturl);
      let urlLogo = data['logo_empresa'];
      this.iconLogo = data['icono_empresa'];


      //console.log(data);
      this.descargImgaen(urlLogo).subscribe(data => {
        this.logoSvg = data;
      });
      this.nombreSitio = data['nombre_empresa']
      this.sucursal_defecto = data['sucursal_defecto'];
      this.colores = data['colores'];
      this.publicKeyMP = data['public_key_checkout'];
      this.redes = data['redes'];

      var rootElement = document.documentElement;
      var primerColor = this.colores.primerColor;
      var segundoColor = this.colores.segundoColor;
      var tercerColor = this.colores.tercerColor;
      var textoColor = this.colores.textoColor;

      rootElement.style.setProperty("--primerColor", primerColor);
      rootElement.style.setProperty("--segundoColor", segundoColor);
      rootElement.style.setProperty("--tercerColor", tercerColor);
      rootElement.style.setProperty("--textoColor", textoColor);
      // console.log(this.sucursal_defecto);

      forkJoin([
        this.bajarSucursales(),
        this.configuracionEcommerce(),
        // this.bajarArticulosPorSucursal(this.sucursal_defecto.toString()).toPromise(),
        // this.promocioneswebs(this.sucursal_defecto.toString()).toPromise(),
        // this.ofertaswebec(this.sucursal_defecto.toString()).toPromise(),
        this.barjarMarcas(),
        this.barjarCategoriasdeArticulos(),
        this.barjarCategorias(),
        // this.tagswebGet(),
        // this.tagsarticulosweb(),
        // this.inciarUsuario(),
      ]).subscribe(() => {
        this.tareasFinales().then(() => {
          sus.next();
          sus.complete();
        }).catch((err) => {
          sus.next();
          sus.complete();
        });

      }, err => this.tirarError(err, sus));



    });
  });





  async syncronizer() {
    this.contador++;
    // console.log('Se Inciaron Operaciones internas numero : ' + this.contador);
    forkJoin(this.tareas).pipe(concatAll()).subscribe(() => {

    }, (err) => {
      throw err;
    }, () => {
      // console.log('Se Finalizaron Operaciones internas');
    });
  }
  async cartSyncronizer() {
    if (this.carritoObservables.length > 0) {
      this.carritoObservables[0].subscribe(() => {

      }, (err) => {
        throw err;
      }, () => {
        this.carritoObservables = [];
      });
    }

  }
  getSucursalPorNumero(sucursal: number) {
    //console.log(sucursal);
    let nombre = ''
    this.sucursales.forEach(k => {
      if (sucursal == k.sucursal) {
        nombre = k.nombre;
      }
    })
    return nombre;
  }
  addtask(taks: Observable<void>) {
    this.tareas.push(taks);
  }
  ordenarporFechaHistorial(data: HistorialPedidos[]): HistorialPedidos[] {
    let arrayHistorial = data.sort(this.fechaHistorial);

    if (arrayHistorial.length > 30) {
      // console.log("Es mayor a 30");

      arrayHistorial = arrayHistorial.slice(0, 30);
    }
    // console.log(arrayHistorial);
    return arrayHistorial;
  }
  fechaHistorial = (a: HistorialPedidos, b: HistorialPedidos): number => {

    if (b.iddocumento > a.iddocumento) {
      return 1;
    }
    if (a.iddocumento > b.iddocumento) {
      return -1;
    }
    return 0;

    // if (moment(b.fecha, 'DD/MM/YYYY').isBefore(moment(a.fecha, 'DD/MM/YYYY'))) {
    //   return -1;
    // }
    // if (moment(a.fecha, 'DD/MM/YYYY').isBefore(moment(b.fecha, 'DD/MM/YYYY'))) {
    //   return 1;
    // }
    // return 0;
  }


  menorAmayorPrecio = (a: ArticulosGeneral, b: ArticulosGeneral): number => {
    if (a.getPrecioMayorista() > b.getPrecioMayorista()) {
      return -1;
    }
    if (a.getPrecioMayorista() < b.getPrecioMayorista()) {
      return 1;
    }
    return 0;
  }

  mayorAmenorPrecio = (a: ArticulosGeneral, b: ArticulosGeneral): number => {
    if (a.getPrecioMayorista() > b.getPrecioMayorista()) {
      return 1;
    }
    if (a.getPrecioMayorista() < b.getPrecioMayorista()) {
      return -1;
    }
    return 0;
  }
  ordenAlfabetico = (a: ArticulosGeneral, b: ArticulosGeneral): number => {
    if (a.getDescripCion() > b.getDescripCion()) {
      return -1;
    }
    if (a.getDescripCion() < b.getDescripCion()) {
      return 1;
    }
    return 0;
  }
  ordenAlfabeticoInvertido = (a: ArticulosGeneral, b: ArticulosGeneral): number => {
    if (a.getDescripCion() > b.getDescripCion()) {
      return 1;
    }
    if (a.getDescripCion() < b.getDescripCion()) {
      return -1;
    }
    return 0;
  }

  preOrdenAlfabetico = (a, b): number => {
    if (a.descripcion > b.descripcion) {
      return 1;
    }
    if (a.descripcion < b.descripcion) {
      return -1;
    }
    return 0;
  }

  ordenarArticulosPor(id: string) {
    if (id === '1') {//mayor precio es 1
      this.resultadosDebusqueda.sort(this.menorAmayorPrecio);
      this.paginado();
      this.filterOn = true;

      this.lastSearch.next(this.lastSearch.value);
    }
    if (id === '0') {
      this.resultadosDebusqueda.sort(this.mayorAmenorPrecio);
      this.paginado();
      this.filterOn = true;

      this.lastSearch.next(this.lastSearch.value);
    }
    if (id === 'a') {
      this.resultadosDebusqueda.sort(this.ordenAlfabeticoInvertido);
      this.paginado();
      this.filterOn = true;

      this.lastSearch.next(this.lastSearch.value);

    }
    if (id === 'z') {
      this.resultadosDebusqueda.sort(this.ordenAlfabetico);
      this.paginado();
      this.filterOn = true;

      this.lastSearch.next(this.lastSearch.value);
    }
  }

  filtrarRangoPrecio(max: number, min: number) {

    this.filterOn = true;
    let sort = this.resultadosDebusqueda;
    console.log(this.numBusqueda);
    console.log(this.oldSort);
    // console.log(sort);

    if (this.resultadosDebusqueda.length !== 0) {
      this.oldSort.push(this.resultadosDebusqueda);
    }

    if (max !== 9999999999 && min !== -1) {

      var newSort = [];
      sort.forEach(element => {

        if (element.getPrecioMayorista() < max && element.getPrecioMayorista() > min) {
          newSort.push(element);
        }
      });

      // console.log(newSort);
      this.resultadosDebusqueda = newSort;
      this.numBusqueda = newSort.length;
      // console.log("NUM: ", this.numBusqueda);

      this.paginado();
      this.lastSearch.next(newSort);
    }
    else {
      console.log(this.numBusqueda);
      console.log(this.oldSort);

      this.resultadosDebusqueda = this.oldSort[this.oldSort.length - 2];
      this.numBusqueda = this.oldSort[this.oldSort.length - 2].length;
      // console.log("NUM: ", this.numBusqueda);

      this.paginado();
      this.lastSearch.next(this.lastSearch.value);
    }
  }

  hasFilter() {

    return this.filterOn;
  }

  busquedaPorNombre() {
    var sort = this.resultadosDebusqueda.sort((a, b) => (a.getDescripCion() > b.getDescripCion()) ? 1 : ((b.getDescripCion() > a.getDescripCion()) ? -1 : 0))
    var newSort = [];
    var oldSort = [];
    var flagSort = false;
    var arrFlag = [];

    this.busqueda = this.busqueda.toUpperCase();
    let arrBusqueda = this.busqueda.split("");


    sort.forEach(element => {
      let arrStr = element.getDescripCion().split(" ");
      arrFlag = [];

      for (let i = 0; i < arrBusqueda.length; i++) {
        const element2 = arrBusqueda[i];

        if (element2 === arrStr[0][i]) {
          flagSort = true;
          arrFlag.push(flagSort);

        }
        else {
          flagSort = false;
          arrFlag.push(flagSort);
        }

      }

      for (let i = 0; i < arrFlag.length; i++) {
        const flag = arrFlag[i];

        if (!flag) {
          flagSort = false;
        }

      }


      if (flagSort) {
        newSort.push(element);
      }
      else {
        oldSort.push(element);
      }

    });

    for (let i = 0; i < oldSort.length; i++) {
      const element = oldSort[i];
      newSort.push(element);
    }

    return newSort;

  }

  getBusqueda(busqueda) {
    this.busqueda = busqueda;

  }



  getNewToken(): Observable<any> {
    return this.httpClient.post(this.environment.endpoint + '/jwt/renew', {}, { headers: { "Authorization": this.user.token } });
  }

  limpiarysalir() {
    this.user = undefined;
    this.userProvider.next(undefined);
    this.resetEccomerce();
    localStorage.removeItem('currentUser');
  }

  getValidationToken(token) {
    var decoded = jwtDecode(token);
    var expirationDate = new Date(decoded['exp'] * 1000);
    var daste = moment().local(true).add(5, 'minutes').unix();
    var midate = new Date(daste * 1000);
    /*
    console.log('tiempo de expiracion');
    console.log(expirationDate);
    console.log('tiempo real');
    console.log(new Date());
    console.log('tiempo modificado');
    console.log(midate);
    console.log(expirationDate < midate);
    */
    return !(expirationDate <= midate);
  }

  cerrarPromos(): Observable<any> {
    // console.log(this.promocionesEccomerce);

    let promocionesaGuardar: BackUptArticulo[] = [];
    this.promocionesEccomerce.forEach(k => {
      if (k.counter > 0) {
        promocionesaGuardar.push(
          {
            marca: k.getMarca(),
            codigo: k.getCodigo(),
            cantidad: k.articuloenPromo().getCantidad(),
            precio: k.getPrecio(),
            monto: k.getPrecioCalculado(),
            reemplazo: 0,
            descripcion: ''
          }
        );

      }
    })

    if (promocionesaGuardar.length > 0) {
      return this.postGenericoSeguro('itempedidoweb', { articulos: promocionesaGuardar });
    }
    else {
      return of(0);
    }

  }
  deletePromosCloud(): Observable<any> {
    let promocionesaGuardar: BackUptArticulo[] = [];
    this.promocionesEccomerce.forEach(k => {
      promocionesaGuardar.push(
        {
          marca: k.getMarca(),
          codigo: k.getCodigo(),
          cantidad: 0,
          precio: 0,
          monto: 0,
          reemplazo: 0,
          descripcion: ''
        }
      );
    })

    if (promocionesaGuardar.length > 0) {
      return this.postGenericoSeguro('itempedidoweb', { articulos: promocionesaGuardar });
    }
    else {
      return of(0);
    }
  }

  resetEccomerce() {
    this.sucursalactiva.articulos.forEach(k => {
      this.eliminarPromos(k)
      //k.setCantidadInicial(0);
    });

    this.ajustarrpomo();
  }

  letresetJson() {
    let params = new HttpParams();
    return this.getGenericoSeguro(params, 'resetjson');
  }
  guardarpedido(pedido: PedidosMmaxEccomerce) {
    this.mypedido = pedido;
    this.mypedido.medios.forEach(k => {
      k.id = this.generarid(5);
      k.selecionado = false;

    })
  }

  verUltimoPedido() {
    return this.mypedido;
  }

  getArtOpened(art) {
    this.lastArtOpened = art;
  }

  returnArtOpened() {
    return this.lastArtOpened;
  }

  returnRedes() {
    return this.redes;
  }

  devolverMediosPagos() {
    // console.log(this.mypedido);

    return this.mypedido.medios;
  }

  obtenerPlanosdemedios(mediopago: number) {
    let params = new HttpParams();
    params = params.append('documento', this.mypedido.iddocumento.toString());
    params = params.append('mediopago', mediopago.toString());
    return this.getGenericoSeguro(params, 'planesmediosweb');
  }
  cerrarFinaleccomerce(mediopago: number, plan: number) {

    return this.postGenericoSeguro('ordenpagoweb', {
      documento: this.mypedido.iddocumento,
      mediopago: mediopago,
      plan: plan,
    });
  }

  getUserSync() {
    return this.user;
  }
  tokencheck = new Observable<void>(response => {
    if (this.user != undefined) {
      // console.log('Revisando Credenciales');
      if (this.getValidationToken(this.user.token) == false) {
        this.getNewToken().subscribe(data => {
          // console.log(data);
          localStorage.removeItem('currentUser')
          this.user.token = data.token;
          this.userProvider.next(this.user);
          localStorage.setItem('currentUser', JSON.stringify({ username: this.user.username, token: data.token }));
          console.log('Credenciales Nuevas establecidas!');
        })
      }
      else {
        // console.log('Credenciales en forma');
      }

    }

    response.complete();

  });


  getSucursalesProviders() {
    return this.sucursalestemp.asObservable();
  }
  getJSON(): Observable<any> {
    return this.httpClient.get('../../cfg/app-configs.json');
  }

  pendingFalse() {
    this.pendingLogin = false;
    this.pendingBeneficios = false;
    this.pendingPedidos = false;
    this.pendingTurnos = false;
    this.pendingRestore = false;
    this.pendingChangePass = false;
  }

  loginStart(username: any, password: any) {
    let nonce = Date.now();
    let firstHash = hash.sha256().update(username + ' ' + password).digest('hex');
    let secondHash = hash.sha256().update(nonce + ' ' + firstHash).digest('hex');
    console.log(this.pendingLogin);
    if (!this.pendingLogin) {
      this.pendingLogin = true
      return this.loginPost('/jwt/login', { usuario: username, hash: secondHash, nonce: nonce.toString() }).pipe(
        tap((data) => {
          this.pendingLogin = false;
          // console.log('login Ok');
          localStorage.setItem('currentUser', JSON.stringify({ username: username, token: data['token'] }));
          this.user = { username: username, token: data['token'], sucursal: -1 };
          this.inciarUsuario().toPromise();
          this.restoEcomerceInit(this.user.sucursal);

        })
      )
    }

  }
  async inicarMercadoPago() {
    let url = new URL("http://192.168.0.118:9003/dlr/checkoutpro")
    let data = await this.httpClient.post(url.toString(), {
      description: 'COMPRA MMAX',
      price: this.getTotal()
    }).toPromise().catch(e => {
      console.error(e);

    });
    return data;


    /*
    this.mercado = new MercadoPago(
  
    )
    */
  }

  async cambiarSucursalV2(idSucursal: string) {

    await this.postGenericoSeguro('cambiarsucursal', {
      sucursal: idSucursal.toString(),
    }).toPromise();

    let encontroSucursal = false;
    //busqueda manual offlline de la sucursal
    for (let k of this.sucursales) {
      if (k.sucursal.toString() === idSucursal.toString()) {
        this.sucursalactiva = k;
        //this.sucursalactiva.articulos=[];
        this.user.sucursal = k.sucursal;
        this.user.sucursalNombre = ' ' + k.nombre;
        encontroSucursal = true;
        break;
      }
    }
    if (encontroSucursal) {
      this.sucursal_defecto = idSucursal;

      this.restoEcomerceInit(idSucursal);
    }
    else {
      console.error('No se encontro la sucursal, y no tiene datos la misma');

    }

  }
  private async restoEcomerceInit(idSucursal) {
    //await this.bajarArticulosPorSucursal(idSucursal).toPromise();
    this.reset = true;
    await this.tareasFinales();
  }

  activarSucursal(id: string) {
    return new Observable<void>(subs => {

      for (let k of this.sucursales) {
        if (k.sucursal.toString() === id) {
          this.sucursalactiva = k;
          break;
        }
      }
      this.bajarArticulosPorSucursal(id).subscribe(data => {
        this.iniciarsucursal(data);
        subs.next();
        console.log('la tarea se termino de ejecutar');
        this.guardarSucursalUsuario(id).subscribe(data => {
          console.log(data);

          subs.complete();
        })


      })
    })

  }

  guardarSucursalUsuario(sucursalID: string) {
    return this.putGenericoSeguro('clienteweb', {
      nombre: this.user.nombre || '',
      direccion: this.user.direccion || '',
      telefonos: this.user.telefonos || '',
      dni: this.user.dni || 0,
      fechaNacimiento: this.user.fechaNacimiento || '',
      sucursal: sucursalID,
    });
  }

  crearUsuario(username: any, dni: any, email: any, password: any, sucursal: any): Observable<any> {
    /*
    "nombre":"",
  "direccion":"",
  "telefonos":"",
  "email":"",
  "ciudad":"",
  "dni":0,
  "fechaNacimiento":"1899-31-12",
  "sucursal":0,
  "password":""*/
    return this.postGenerico('clienteweb', {
      nombre: username,
      direccion: "",
      telefonos: "",
      email: email,
      ciudad: "",
      dni: dni,
      fechaNacimiento: "1899-31-12",
      sucursal: sucursal,
      password: password,
    });

  }

  pedirCambioPass(email: any): Observable<any> {
    if (!this.pendingRestore) {
      this.pendingRestore = true;
      return this.loginPost('/jwt/restore', { email: email }).pipe(
        tap((data) => {
          this.pendingRestore = false;
          console.log("Mando MAIL: ", email);
        })
      );
    }
  }

  cambiarContraseña(username: any, password: any, resetId: any) {

    let nonce = Date.now();
    let firstHash = hash.sha256().update(username + ' ' + password).digest('hex');

    if (!this.pendingChangePass) {
      this.pendingChangePass = true;
      return this.loginPost('/jwt/restore', { usuario: username, hash: firstHash, nonce: nonce, resetid: resetId.toString() }).pipe(
        tap((data) => {
          this.pendingChangePass = false;
          console.log("Data:", data);
        })
      );
    }
  }

  restorePassMmax(username: string, password: string, resetId) {
    let nonce = Date.now();
    let firstHash = hash.sha256().update(username.toLowerCase() + ' ' + password).digest('hex');

    return this.loginPost('/jwt/restore', { usuario: username, hash: firstHash, nonce: nonce, resetid: resetId.toString() }).pipe(
      tap((data) => {

        console.log("Restore MMAX:", data);

      })
    );

  }

  sendErrorMessage(err) {
    this.errorMessage = err;
  }

  getErrorMessage() {
    return this.errorMessage;
  }

  getLogo() {
    //console.log(this.logoSvg);

    return this.logoSvg;
  }
  getNombreSitio() {
    return this.nombreSitio;
  }

  getPublicKeyMP() {
    return this.publicKeyMP;
  }


  /*
  get Region
  */


  tirarError = ((err, sus: Subscriber<void>) => {
    sus.error(err);
    //console.log(err);
    this.modal.open('modal-error');

  });
  /*
  "dia":1,
    "horainicio":1000,
    "fecha":"1899-12-31"
  */

  cerrarPedido(dia: number, horainicio: number, fecha: string, modo: number, ciudad?: string, barrio?: string, calle?: string, numero?: number) {
    return this.postGenericoSeguro('cerrarpedidoweb', {
      dia: dia,
      horainicio: horainicio,
      fecha: fecha,
      modo: modo,
      observacion: this.lastObs,
      ciudad: ciudad,
      barrio: barrio,
      calle: calle,
      numero: numero,
    });
  }

  cancelarPedidoWeb() {

    return this.postGenericoSeguro('cancelarpedidoweb', {}).subscribe((data) => {
      console.log(data);

    });
  }

  setObservacion(observacion: string) {
    this.lastObs = observacion || '';
  }
  verPedido(detalle: HistorialPedidos) {
    // console.log(detalle);
    this.detalleElegido = detalle;
    localStorage.setItem('detalle', JSON.stringify(this.detalleElegido))
  }
  getDetalleElegido() {
    if (this.detalleElegido == undefined) {
      return localStorage.getItem('detalle')
    } else {
      return this.detalleElegido;
    }
  }
  private iniciarsucursal(data) {
    this.sucursalactiva.articulos = data;
    this.sucursalactivatemp.next(this.sucursalactiva);
    //console.log(this.sucursalactiva);
    //console.log(data);
  }

  inciarUsuario() {

    return new Observable<void>(obs => {
      // modificar     
      this.user = JSON.parse(localStorage.getItem('currentUser'));

      if (this.user == null) {
        // this.userProvider = new BehaviorSubject(undefined);
        this.userProvider.next(undefined);
        obs.next();
        obs.complete();
        return;
      }
      // console.log(this.user);
      if (!this.getValidationToken(this.user.token)) {
        this.userProvider.next(undefined);
        localStorage.removeItem('currentUser');
        obs.next();
        obs.complete();
        return;
      }
      //usuario valido
      //  console.log(this.user);

      this.obtenerdatosdeUsuario().subscribe(data => {
        this.actualizarUsuario(data);
        this.tareas.push(this.tokencheck);

        obs.next();
        obs.complete();

      }, err => {
        this.userProvider.next(undefined);
        localStorage.removeItem('currentUser');
        obs.next();
        obs.complete();
      });

    });

  }
  /*
  OFERTAS?
  */
  ofertaswebec(sucursal) {
    let httparams = new HttpParams();
    httparams = httparams.append('sucursal', sucursal);
    return this.getGenerico(httparams, 'ofertaswebec').pipe(tap(data => {
      this.ofertasEnlazadas = data['registros'] as OfertasEcommerce[];
    }));
  }

  getOfertAStemp() {
    return this.ofertasTemp.asObservable();
  }
  /*
  OFERTAS?
  */
  /*area de tags*/
  tagswebGet() {
    let httparams = new HttpParams();

    return this.getGenerico(httparams, 'tagsweb').pipe(tap(data => {
      let mydata = data['registros'];
      if (mydata != undefined && Array.isArray(mydata)) {
        mydata.forEach(k => {
          this.tags.push({
            nombre: k['nombre'],
            articulos: [],
            tag: k['tag']
          });
        })
      }
    }))

  };

  tagsarticulosweb() {
    let httparams = new HttpParams();
    return this.getGenerico(httparams, 'tagsarticulosweb').pipe(tap(data => {
      this.tagsRelacion = data['registros'];
    }));
  }
  /*area de tags*/



  turnosdisponibles() {

    let params = new HttpParams();//"sucursal":""
    //params = params.append('sucursal',this.sucursalactiva.sucursal.toString())
    if (!this.pendingTurnos) {
      // this.pendingTurnos = true;
      return this.getGenericoSeguro(params, 'turnosdisponibles').pipe(map(data => {
        this.pendingTurnos = false;
        return data['registros'];
      }));
    }
  }
  /*  tareas compartidas  */
  async tareasFinales() {

    await this.inciarUsuario().toPromise();
    await this.promocioneswebs(this.sucursalactiva.sucursal.toString()).toPromise();
    await this.bajarArticulosPorSucursal(this.sucursalactiva.sucursal.toString()).toPromise();
    await this.ofertaswebec(this.sucursalactiva.sucursal.toString()).toPromise();


    // this.articulosWeb2('1', '', '', 0, '').subscribe(data => {
    //   // console.log(data);
    // })
    // this.articulosWeb2("1", '', "500", 0, 'S').subscribe(data => {
    //   // console.log(data);
    // })

    /* tags - relacion - tags web*/
    if (this.tagsRelacion != undefined && Array.isArray(this.tagsRelacion)) {
      this.tags.forEach(k => {
        for (let tag of this.tagsRelacion) {
          if (k.tag == tag.tag) {
            k.articulos.push(tag);
          }
          else {
            break;
          }
        }
      });

    }
    let registros: ArticulosEcommerce[] = [];
    let registro: ArticulosEcommerce;
    let imgenurl;
    let cloudinaryURL;
    let cloudinaryCloud;
    let cloudinaryID = '';

    this.articulosSucursalDefecto.forEach(k => {
      let remplasoss: RemplazosMMax[] = []

      // console.log(k);

      this.remplazos.forEach(k => {
        let remplaceme = Object.assign({}, k)
        remplaceme.idcss = this.generarid(4);
        remplaceme.activo = false;
        remplasoss.push(remplaceme);
      });

      cloudinaryURL = k['foto'];
      imgenurl = new URL(k['foto'], this.environmenturl).toString();

      if (this.environment.endpoint + this.environment.endpointversion == imgenurl) {
        imgenurl = undefined;
      } else {
        let imgSplit = imgenurl.split("/");
        let imgTransformation = "q_auto:good/c_fit";
        imgenurl = imgSplit[0] + "/" + imgSplit[1] + "/" + imgSplit[2] + "/" + imgSplit[3] + "/" + imgSplit[4] + "/" + imgSplit[5] + "/" + imgTransformation + "/" + imgSplit[6] + "/" + imgSplit[7];
      }

      if (this.environment.endpoint + this.environment.endpointversion == cloudinaryURL) {
        cloudinaryURL = undefined;
      }

      // console.log(imgenurl);

      let cloudSplit = cloudinaryURL.split("/");
      cloudinaryCloud = cloudSplit[3];
      cloudinaryID = cloudSplit[cloudSplit.length - 1];
      // console.log(cloudinaryURL);

      if (cloudinaryCloud != '') {
        const cld = new Cloudinary({
          cloud: {
            cloudName: cloudinaryCloud
          }
        });

        if (cloudinaryID != '') {
          this.img = cld.image(cloudinaryID);
          // console.log(this.img);
          cloudinaryID = '';
        }
      }

      // // Apply the transformation.
      // this.img
      //   .resize(thumbnail().width(150).height(150).gravity(focusOn(FocusOn.face())))  // Crop the image.
      //   .roundCorners(byRadius(20))    // Round the corners.
      //   .effect(sepia())  // Apply a sepia effect.
      //   .overlay(   // Overlay the Cloudinary logo.
      //     source(
      //       image('cloudinary_icon_blue')
      //         .transformation(new Transformation()
      //           .resize(scale(50)) // Resize the logo.
      //           .adjust(opacity(60))  // Adjust the opacity of the logo.
      //           .adjust(brightness(200)))  // Adjust the brightness of the logo.       
      //     )
      //       .position(new Position().gravity(compass('south_east')).offsetX(5).offsetY(5))   // Position the logo.
      //   )
      //   .rotate(byAngle(10))  // Rotate the result.
      //   .format('png');   // Deliver as PNG. */

      registro = new ArticulosEcommerce
        (
          k['marca'],
          k['codigo'],
          k['precio'],
          k['precionormal'],
          k['cmax'],
          k['da'],
          k['descripcion'],
          k['unidadesxb'] | 0,
          k['presentacion'],
          k['pesoxb'] | 0,
          k['pesable'],
          k['pesableporunidad'],
          k['nombremarca'],
          k['marcareal'],
          k['cantidad'],
          k['coeficiente'],
          k['preciopor'],
          k['unidaddmedida'],
          k['cantidadmayorista'],
          k['preciomayorista'],
          k['etiquetamedida'],
          imgenurl,
          -1,
          remplasoss || [],
        )

      //registro.cantiadadpedido=0;
      //registro.setFoto(new URL(k['foto'],this.environmenturl).toString());
      //registro.enlapromo = [];
      //this.armarFoto(registro);

      this.tags.forEach(k => {
        for (let articulo of k.articulos) {
          if (articulo.codigo == registro.getCodigo() && articulo.marca == registro.getMarca()) {
            registro.setTag({ tag: k.tag });

          }
        }
      });

      if (registro.getDescripCion()[0] === "*") {
        let newDesc = registro.getDescripCion().slice(1);
        registro.descripcion = newDesc;
      }



      registros.push(registro)
    });

    this.iniciarsucursal(registros);

    /*OFERTAS*/
    let fecha = new Date();
    this.ofertas = [];
    for (let ofertas of this.ofertasEnlazadas) {
      this.sucursalactiva.articulos.forEach(k => {
        if (k.getCodigo() == ofertas.codigo && k.getMarca() == ofertas.marca) {

          k.setdeOferta(ofertas);
          this.ofertas.push(k);

        }
      });
    }
    this.ofertas = this.ofertas.filter((item, index) => {
      return this.ofertas.indexOf(item) === index;
    });

    let newOfertas1 = [];
    for (let i = 0; i < this.ofertas.length; i++) {
      const element = this.ofertas[i];

      if (element.deOferta.fechahasta > fecha.toISOString().split('T')[0]) {

        newOfertas1.push(element);

      }
    }

    this.ofertas = newOfertas1;

    // if (this.reset === true) {
    //   let newOfertas2 = this.ofertas;
    //   for (let i = 0; i < this.ofertas.length; i++) {
    //     const ofertaInicial = this.ofertas[i];

    //     // newOfertas.splice(i, 1);

    //     for (let index = 0; index < newOfertas2.length; index++) {
    //       const ofertaDuplicada = newOfertas2[index];

    //       if (ofertaInicial === ofertaDuplicada) {
    //         console.log("OI: ", ofertaInicial);
    //         console.log("OD: ", ofertaDuplicada);

    //         newOfertas2.splice(index, 1);
    //         // i = 0;
    //       }
    //     }

    //     console.log("NWOF: ", newOfertas2);


    //   }
    //   this.ofertas = newOfertas2;
    // }

    // console.log("OF: ", this.ofertas);

    this.paginadoOfertas();
    this.calcularPromociones();
    if (this.user != null) {
      let sucursales = this.getSucursales();
      let sucTrue = false;
      for (let i = 0; i < sucursales.length; i++) {
        const element = sucursales[i];
        if (element.sucursal == this.user.sucursal) {
          sucTrue = true;
        }
      }
      console.log(sucTrue);
      if (sucursales.length > 1) {
        if (sucTrue == false) {
          if (localStorage.getItem('currentUser') != null || localStorage.getItem('currentUser') != undefined) {
            this.modal.open('sucursal-modal-1');
          }
        }
      }
      await this.iniciarCarrito().toPromise();
      // this.cleanUnpaidOrders().toPromise();
    }


  }

  /*  END tareas compartidas  */
  actualizarUsuario(response) {
    let data = response['registros'][0];
    this.user.ciudad = data['ciudad'];
    this.user.clienteol = data['clienteol'];
    this.user.direccion = data['direccion'];
    this.user.dni = data['dni'];
    this.user.email = data['email'];
    this.user.empresa = data['empresa'];
    this.user.fechaNacimiento = data['fechanacimiento'];
    this.user.nombre = data['nombre'];
    this.user.sucursal = data['sucursal'];
    this.user.telefonos = data['telefonos'];
    if (this.user.sucursal != undefined && this.user.sucursal != 0) {
      this.sucursales.forEach(k => {
        if (this.user.sucursal == k.sucursal) {
          this.sucursalactiva = k;
          this.user.sucursalNombre = ' ' + k.nombre;
          this.sucursal_defecto = this.user.sucursal.toString();
        }
      })
    }

    this.userProvider.next(this.user);
  }

  obtenerdatosdeUsuario() {
    let params = new HttpParams();
    return this.getGenericoSeguro(params, 'clienteweb');

  }
  obtenerdatosdeUsuarioV2() {
    let params = new HttpParams();
    return this.getGenerico(params, 'clienteweb');

  }
  barjarMarcas() {
    let params = new HttpParams();
    let cloudinaryURL;
    let cloudinaryCloud;
    let cloudinaryID = '';

    return this.getGenerico(params, 'marcasweb').pipe(tap((data) => {
      data['registros'].forEach(element => {
        let imgenurl = new URL(element['imagen'], this.environmenturl).toString();

        cloudinaryURL = element['imagen'];

        if (this.environment.endpoint + this.environment.endpointversion == imgenurl) {
          imgenurl = undefined;
        } else {
          let imgSplit = imgenurl.split("/");
          let imgTransformation = "q_auto:good/c_fit";
          imgenurl = imgSplit[0] + "/" + imgSplit[1] + "/" + imgSplit[2] + "/" + imgSplit[3] + "/" + imgSplit[4] + "/" + imgSplit[5] + "/" + imgTransformation + "/" + imgSplit[6] + "/" + imgSplit[7];
        }

        if (this.environment.endpoint + this.environment.endpointversion == cloudinaryURL) {
          cloudinaryURL = undefined;
        }

        // console.log(imgenurl);

        let cloudSplit = cloudinaryURL.split("/");
        cloudinaryCloud = cloudSplit[3];
        cloudinaryID = cloudSplit[cloudSplit.length - 1];
        // console.log(cloudinaryURL);

        if (cloudinaryCloud != '') {
          const cld = new Cloudinary({
            cloud: {
              cloudName: cloudinaryCloud
            }
          });

          if (cloudinaryID != '') {
            this.img = cld.image(cloudinaryID);
            // console.log(this.img);
            cloudinaryID = '';
          }
        }

        this.marcasDestacadas.push({
          articulos: element['articulos'],
          destacada: element['destacada'],
          imagen: imgenurl,
          marca: element['marca'],
          nombre: element['nombre'],
          urlexterna: element['urlexterna'],
        })
      });
    }));


  }

  getMarcasDestacadas() {
    let marcas: MarcasEcommerce[] = []
    this.marcasDestacadas.forEach(k => {
      marcas.push(k);
    })
    return marcas;
  }

  getOfertas() {
    return this.ofertas;
  }

  getNumPagOfertas() {
    return this.ofertas.length / 30;
  }

  paginadoOfertas() {

    let paginado: ArticulosGeneral[] = [];

    // console.log("LENGTH OFERTAS: ", this.ofertas.length);

    let paginas = this.ofertas.length / 30;
    this.paginadorProviderOfertas.next(paginas);
    //console.log(paginas)
    if (paginas > 1) {
      for (let index = 0; index < 30; index++) {
        // console.log(this.ofertas[index]);        
        paginado.push(this.ofertas[index]);

      }
      // this.ofertas = paginado;
      this.paginaActualOfertas = 0;

    }
    else {
      //this.articulosonPage = this.articulos;
      paginado = this.ofertas;
      // this.ofertas = paginado;
      this.paginaActualOfertas = 0;
    }
    // console.log("PAGINADO: ", paginado);

    // this.lastSearch.next(paginado);
  }
  /**
  * 
  * categorias 
  */
  /**
   *  bajar categorias parte 2
   */
  barjarCategorias() {
    let params = new HttpParams();
    let cloudinaryURL;
    let cloudinaryCloud;
    let cloudinaryID = '';

    return this.getGenerico(params, 'categoriasweb').pipe(tap(data => {
      data['registros'].forEach(element => {
        let imgenurl = new URL(element['imagen'], this.environmenturl).toString();
        cloudinaryURL = element['imagen'];

        if (this.environment.endpoint + this.environment.endpointversion == imgenurl) {
          imgenurl = undefined;
        } else {
          let imgSplit = imgenurl.split("/");
          let imgTransformation = "q_auto:good/c_fit";
          imgenurl = imgSplit[0] + "/" + imgSplit[1] + "/" + imgSplit[2] + "/" + imgSplit[3] + "/" + imgSplit[4] + "/" + imgSplit[5] + "/" + imgTransformation + "/" + imgSplit[6] + "/" + imgSplit[7];
        }

        if (this.environment.endpoint + this.environment.endpointversion == cloudinaryURL) {
          cloudinaryURL = undefined;
        }

        // console.log(imgenurl);

        let cloudSplit = cloudinaryURL.split("/");
        cloudinaryCloud = cloudSplit[3];
        cloudinaryID = cloudSplit[cloudSplit.length - 1];
        // console.log(cloudinaryURL);

        if (cloudinaryCloud != '') {
          const cld = new Cloudinary({
            cloud: {
              cloudName: cloudinaryCloud
            }
          });

          if (cloudinaryID != '') {
            this.img = cld.image(cloudinaryID);
            // console.log(this.img);
            cloudinaryID = '';
          }
        }

        this.categoriasWeb.push({
          articulos: element['articulos'],
          categoria: element['categoria'],
          imagen: imgenurl,
          nombre: element['nombre'],
          orden: element['orden'],
          padre: element['padre'],
          subCategorias: [],
          desplegado: false,
        });
      });
      this.ordenarCategorias();
    }));
  }

  getExtraResultadosProvider() {
    return this.resultadodeBusqueda.asObservable();
  }

  getMovimientosMmax() {
    let params = new HttpParams();

    if (!this.pendingBeneficios) {
      this.pendingBeneficios = true;
      return this.getGenericoSeguro(params, 'ConsultaSaldo').pipe(map(data => {
        this.pendingBeneficios = false
        return data['registros'];
      }));
    }
  }

  /**
   *  bajar categorias parte 1
   */
  barjarCategoriasdeArticulos() {
    let params = new HttpParams();
    let sucursalActiva;
    return this.getGenerico(params, 'categoriasarticulosweb').pipe(tap(data => {
      /*
    categoria: 101
    codigo: 888
    marca: 60
    marca: 3065, codigo: 7, categoria: 8
    */
      //categoria...

      let newDataArt = [];
      for (let i = 0; i < data['registros'].length; i++) {
        const element = data['registros'][i];
        if (!this.containsObject(element, newDataArt)) {
          newDataArt.push(element);
        }

      }


      this.mapaPedidos = new Map();

      let midata: { marca: number, codigo: number, categoria: number, descripcion: string }[] = data['registros'] as any[];
      midata.forEach(k => {

        this.getActualSucursal().subscribe(data => {
          sucursalActiva = data;

          if (sucursalActiva != undefined) {
            sucursalActiva.articulos.forEach(l => {
              if (l.getMarca() == k.marca && l.getCodigo() == k.codigo) {
                if (this.mapaPedidos.has(k.categoria)) {
                  let arra = this.mapaPedidos.get(k.categoria);
                  let art = {
                    marca: k.marca,
                    codigo: k.codigo,
                    descripcion: l.getDescripCion()
                  }

                  if (!this.containsObject(art, arra)) {
                    arra.push(art);
                  }

                  arra.sort(this.preOrdenAlfabetico);
                  this.mapaPedidos.set(k.categoria, arra);
                }
                else {
                  this.mapaPedidos.set(k.categoria, [{ marca: k.marca, codigo: k.codigo, descripcion: l.getDescripCion() }]);
                }
              }
            })
          }
        })

      })
    }))


  }

  containsObject(obj, list) {
    var i;
    for (i = 0; i < list.length; i++) {
      if (list[i].marca == obj.marca && list[i].codigo == obj.codigo) {

        return true;
      }
    }

    return false;
  }


  private async ordenarCategorias() {
    let categorias: CategoriasWebMenu[] = [];

    this.categoriasWeb.forEach(categoria => {
      if (categoria.padre == 0) {
        this.categoriasWeb.forEach(subcategoria => {
          if (categoria.categoria == subcategoria.padre) {
            this.categoriasWeb.forEach(tiercategoriatres => {
              if (subcategoria.categoria == tiercategoriatres.padre) {
                subcategoria.subCategorias.push(tiercategoriatres);
              }
            })
            categoria.subCategorias.push(subcategoria);
          }
        })
        categorias.push(categoria);
      }

    });
    this.categoriasWeb = categorias;
    // console.log(this.categoriasWeb);


  }

  /**
   * 
   * @returns a array of categorias
   */
  getCategoriasWeb() {
    let categorias: CategoriasWebMenu[] = [];
    this.categoriasWeb.forEach(k => {
      if (k.subCategorias.length != 0) {
        k.subCategorias.sort(this.ordenCategorias)
      }
      categorias.push(k);
    })
    categorias.sort(this.ordenCategorias);
    return categorias;
  }

  ordenCategorias = (a: CategoriasWebMenu, b: CategoriasWebMenu): number => {
    if (a.orden > b.orden) {
      return 1;
    }
    if (a.orden < b.orden) {
      return -1;
    }
    return 0;
  }
  /**
  * 
  * end categorias 
  */
  bajarSucursales() {
    let params = new HttpParams();
    var fundentroOtraFun = () => {


      let sucursal = this.sucursales[0];
      if (sucursal) {
        console.log('SE ESTABLECIO UNA SUCURSAL DEFECTO' + ' ' + sucursal.nombre);
        this.sucursal_defecto = sucursal.sucursal.toString();
        this.sucursalactiva = sucursal;
        this.sucursalactiva.articulos = [];
        this.articulosSucursalDefecto = [];
      }
    }
    return this.getGenerico(params, 'sucursalesweb').pipe(tap(data => {
      let suck = data['registros'] as any[];
      // console.log(suck);

      if (suck == undefined || suck.length < 0) {
        // console.log('lal');

        alert('Sin Sucursales');//cambiar pro propio
        //throw // ERROR CRITICO REMPLAZAR por error propio
      }

      let sucu: SucursalEcommerce;
      suck.forEach(sucursal => {
        sucu = sucursal as SucursalEcommerce;
        sucu.articulos = [];
        this.sucursales.push(sucu);
        if (sucu.sucursal.toString() === this.sucursal_defecto.toString()) {
          this.sucursalactiva = sucu;
          this.sucursalactiva.articulos = [];
        }

      })
      if (this.sucursalactiva) {
        if (this.sucursalactiva.articulos) {
          //tenemos sucursal, todo bien 
        }
        else {
          fundentroOtraFun()
        }
      }
      else {
        fundentroOtraFun()
      }





      this.sucursalestemp.next(this.sucursales);
    }))
  }

  configuracionEcommerce() {
    //configuracionecommerce
    let params = new HttpParams();
    return this.getGenerico(params, 'configuracionecommerce').pipe(tap(data => {
      //console.log(data);
      /*
      //sucurusale por fecte
      this.sucursales.forEach(k=>{
      //  console.log(data['sucursal']);
        
        if (k.sucursal.toString()=== data['sucursal']) {
            this.sucursalactiva=k;
            this.sucursalactiva.articulos=[];
            this.promocionesEccomerce=[];
        }
      });*/
      //console.log(data['reemplazos'] );
      console.log("Configuraciones Data: ", data);
      // console.log("Suc: ", this.sucursales);

      this.sucursal_defecto = data['sucursal'];

      let rempla = data['reemplazos'] as RemplazosMMax[];
      if (rempla != undefined) {
        this.remplazos = rempla;
        this.remplasosProvider.next(this.remplazos);
      }

      this.montoMinimo = data['montoMinimo'];
      this.pagoDiferido.next(data['pagoDiferido']);
      this.envioHabilitado.next(data['envioHabilitado']);
      this.registroHabilitado.next(data['registroHabilitado']);
      this.beneficios.next(data['beneficios']);
      this.usaTurnos.next(data['usaTurnos']);
      this.horasMinimasTurno.next(data['horasMinimas']);
      this.codigoFlete = (data['codigoFlete']);
      this.marcaFlete = (data['marcaFlete']);
    }));

  }

  getURLMap() {
    let sucact = this.getSucursalActiva();
    // console.log(sucact);
    if (sucact) {
      this.urlmap = sucact.urlmap;
      return this.urlmap;
    }
    else {
      return '';
    }


  }

  getCiudades() {
    let params = new HttpParams();
    return this.getGenericoSeguro(params, 'ecomciudades').pipe(tap(data => {
      this.ciudades.next(data['registros']);
    }));
  }

  returnCiudades() {
    return this.ciudades.asObservable();
  }

  getDatosEnvio(data) {
    this.datosEnvio = data;
  }

  returnDatosEnvio() {
    return this.datosEnvio;
  }

  cleanUnpaidOrders() {
    let params = new HttpParams();
    return this.getGenericoSeguro(params, 'vaciarCarrito').pipe(tap(data => {
      console.log(data);

    }));
  }

  getRemplasosProvider() {
    return this.remplasosProvider.asObservable();
  }

  getMontoMinimo() {
    return this.montoMinimo;
  }

  getPagoDiferido() {
    return this.pagoDiferido.asObservable();
  }

  getEnvioHabilitado() {
    return this.envioHabilitado.asObservable();
  }

  getRegistroHabilitado() {
    return this.registroHabilitado.asObservable();
  }

  getSucursalDefecto() {
    return this.sucursal_defecto;
  }

  beneficiosTrue() {
    return this.beneficios.asObservable();
  }

  returnUsaTurnos() {
    return this.usaTurnos.asObservable();
  }

  returnHorasMinimasTurno() {
    return this.horasMinimasTurno.asObservable();
  }

  bajarImgaen(articulo: ArticulosGeneral) {
    let params = new HttpParams();

    return this.httpClient.get(articulo.getFotoImagen(), {
      params, observe: 'response', responseType: 'blob'
    }).pipe(tap(data => {
      articulo.setMyBlob(data.body);
      articulo.setFoto(this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(articulo.getMyBlob())));

    }), catchError(this.errorHandler));

  }

  setImagenModal(img) {
    this.imagenModal = img;
  }

  getImagenPeticion() {
    let params = new HttpParams();
    let pathImg = this.imagenModal.split('/')[4];
    return this.httpClient.get(this.environmenturl.toString() + 'imagenes/' + pathImg, {
      headers: { "Authorization": this.user.token }, params, observe: 'response', responseType: 'blob'
    }).pipe(tap(data => {
      // console.log(data);   
    }), catchError(this.errorHandler));
  }

  errorHandler(error: HttpErrorResponse) {
    return new Observable(err => {
      if (error.message) {
        // console.log(error.message);

      }

    });
  }
  descargImgaen(imagensrc: string,) {
    let params = new HttpParams();
    return this.httpClient.get(imagensrc, {
      params, observe: 'response', responseType: 'blob'
    }).pipe(map((data) => {

      return this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(data.body))
    }));

  }
  /**
   * bajar articulos segun sucursal
   */
  bajarArticulosPorSucursal(sucursal: string) {

    let params = new HttpParams();
    params = params.append('sucursal', sucursal);

    return this.getGenerico(params, 'articulosweb').pipe(tap(data => {
      this.articulosSucursalDefecto = data['registros'];
    }));
  }
  articulosWeb2(sucursal: string, art: string, rango: string, categoria: number, ofertas: string) {

    let params = new HttpParams();
    params = params.append('sucursal', sucursal);
    params = params.append('s', art);
    params = params.append('rangoregistros', rango);
    params = params.append('categoria', categoria.toString());
    params = params.append('ofertas', ofertas);

    return this.getGenerico(params, 'articulosweb2').pipe(tap(data => {
      // console.log(data);

      let registro: ArticulosEcommerce;

      data['registros'].forEach(k => {
        let remplasoss: RemplazosMMax[] = []


        this.remplazos.forEach(k => {
          let remplaceme = Object.assign({}, k)
          remplaceme.idcss = this.generarid(4);
          remplaceme.activo = false;
          remplasoss.push(remplaceme);
        });
        let imgenurl = new URL(k['foto'], this.environmenturl).toString();
        registro = new ArticulosEcommerce
          (
            k['marca'],
            k['codigo'],
            k['precio'],
            k['precionormal'],
            k['cmax'],
            k['da'],
            k['descripcion'],
            k['unidadesxb'] | 0,
            k['presentacion'],
            k['pesoxb'] | 0,
            k['pesable'],
            k['pesableporunidad'],
            k['nombremarca'],
            k['marcareal'],
            k['cantidad'],
            k['coeficiente'],
            k['preciopor'],
            k['unidaddmedida'],
            k['cantidadmayorista'],
            k['preciomayorista'],
            k['etiquetamedida'],
            imgenurl,
            -1,
            remplasoss || [],
          )

        //registro.cantiadadpedido=0;
        //registro.setFoto(new URL(k['foto'],this.environmenturl).toString());
        //registro.enlapromo = [];
        //this.armarFoto(registro);

        this.tags.forEach(k => {
          for (let articulo of k.articulos) {
            if (articulo.codigo == registro.getCodigo() && articulo.marca == registro.getMarca()) {
              registro.setTag({ tag: k.tag });

            }
          }
        });

        if (registro.getDescripCion() != undefined) {
          if (registro.getDescripCion()[0] === "*") {
            let newDesc = registro.getDescripCion().slice(1);
            registro.descripcion = newDesc;
          }
        }

        this.articulos2.push(registro);
      });
    }));
  }
  /*
  async bajarTdaslasFotos(myfotos: Observable<any>[]) {
    forkJoin(myfotos).pipe(concatAll()).subscribe(()=>{
      //console.log(data);
      
    });
  }*/

  /**
   * 
   * carrito //revisar precios
   */
  getCarrito() {
    let carrito: ArticulosGeneral[] = [];
    this.sucursalactiva?.articulos.forEach(k => {
      if (k.getCantidad() > 0) {
        carrito.push(k);
        if (this.datosEnvio != undefined) {

          let fleteArt = new ArticulosEcommerce(parseInt(this.marcaFlete), parseInt(this.codigoFlete), this.datosEnvio.costo, this.datosEnvio.costo, 0, 'Flete', 'ENVIO A ' + this.datosEnvio.nombreCiudad, 1, 'Codigo Postal: ' + this.datosEnvio.cp, 0, 0, 0, 'FLETES', parseInt(this.marcaFlete), 1000, 1000, this.datosEnvio.costo, '', 0, this.datosEnvio.costo, 'Flete', undefined, 2, [{ nombre: 'No Reemplazar', modo: '2', idcss: 'c9ft', activo: true }]);
          fleteArt.cantiadadpedido = 1;

          if (!this.containsObject(fleteArt, carrito)) {
            carrito.push(fleteArt);
          }
        }
      }
    })

    return carrito;
  }

  getTotal() {
    let tota = 0;
    this.getCarrito().forEach(k => {
      if (k.getPrecioCalculado() != 0) {
        if (k.misPromo.length != 0) {
          // console.log(k);
          // console.log(k.misPromo);
          if (k.getCantidad() % k.misPromo[0].cantidadenPromo() === 0) {
            tota += k.misPromo[0].getPrecioCalculado();
          } else {
            tota += k.getPrecioCalculado();
          }
        } else {
          tota += k.getPrecioCalculado();
        }
      }
    })

    return tota;
  }

  getCantidadTotal() {
    let cantidad = 0;
    this.getCarrito().forEach(k => {
      cantidad += k.getCantidad();
    });
    return cantidad;
  }
  /**
   * 
   *  promociones.get
   */
  promocioneswebs(sucursal) {
    let params = new HttpParams();
    params = params.append('sucursal', sucursal)
    return this.getGenerico(params, 'promocioneswebec').pipe(tap(data => {

      let cloudinaryURL;
      let cloudinaryCloud;
      let cloudinaryID = '';
      this.promocionesEccomerce = [];
      this.slidesArray = [];

      data['registros'].forEach(element => {

        let imgenurl = new URL(element['imagen'], this.environmenturl).toString();
        cloudinaryURL = element['imagen'];

        if (this.environment.endpoint + this.environment.endpointversion == imgenurl) {
          imgenurl = undefined;
        } else {
          let imgSplit = imgenurl.split("/");
          let imgTransformation = "q_auto:good/c_fit";
          imgenurl = imgSplit[0] + "/" + imgSplit[1] + "/" + imgSplit[2] + "/" + imgSplit[3] + "/" + imgSplit[4] + "/" + imgSplit[5] + "/" + imgTransformation + "/" + imgSplit[6] + "/" + imgSplit[7];
        }

        if (this.environment.endpoint + this.environment.endpointversion == cloudinaryURL) {
          cloudinaryURL = undefined;
        }

        // console.log(imgenurl);

        let cloudSplit = cloudinaryURL.split("/");
        cloudinaryCloud = cloudSplit[3];
        cloudinaryID = cloudSplit[cloudSplit.length - 1];
        // console.log(cloudinaryURL);

        if (cloudinaryCloud != '') {
          const cld = new Cloudinary({
            cloud: {
              cloudName: cloudinaryCloud
            }
          });

          if (cloudinaryID != '') {
            this.img = cld.image(cloudinaryID);
            // console.log(this.img);
            cloudinaryID = '';
          }
        }

        // let imgenurl = new URL(element['imagen'], this.environmenturl).toString();
        if (element['porcentaje'] != 0 && element['tipo'] == "P") {
          this.promocionesEccomerce.push(new PorcentajePromo(
            element['abv'],
            element['cantidad1'],
            element['cantidad2'],
            element['desde'],
            element['hasta'],
            element['idpromocion'],
            imgenurl,
            element['monto'],
            element['nombre'],
            element['orden'],
            element['porcentaje'],
            element['regla1'],
            element['regla2'],
            element['sucursal'],
            element['lunes'],
            element['martes'],
            element['miercoles'],
            element['jueves'],
            element['viernes'],
            element['domingo'],
            element['sabado'],
            element['tipo'],
            this.generarid(5),
            element['marca'],
            element['codigo'],
            false
          ))
        }

        if (element['monto'] > 0 && element['tipo'] == "P") {
          this.promocionesEccomerce.push(new MontoPromo(
            element['abv'],
            element['cantidad1'],
            element['cantidad2'],
            element['desde'],
            element['hasta'],
            element['idpromocion'],
            imgenurl,
            element['monto'],
            element['nombre'],
            element['orden'],
            element['porcentaje'],
            element['regla1'],
            element['regla2'],
            element['sucursal'],
            element['lunes'],
            element['martes'],
            element['miercoles'],
            element['jueves'],
            element['viernes'],
            element['domingo'],
            element['sabado'],
            element['tipo'],
            this.generarid(5),
            element['marca'],
            element['codigo'],
            false
          ))
        }

        if (element['monto'] < 0 && element['tipo'] == "P") {
          this.promocionesEccomerce.push(new DiferenciaPromo(
            element['abv'],
            element['cantidad1'],
            element['cantidad2'],
            element['desde'],
            element['hasta'],
            element['idpromocion'],
            imgenurl,
            element['monto'],
            element['nombre'],
            element['orden'],
            element['porcentaje'],
            element['regla1'],
            element['regla2'],
            element['sucursal'],
            element['lunes'],
            element['martes'],
            element['miercoles'],
            element['jueves'],
            element['viernes'],
            element['domingo'],
            element['sabado'],
            element['tipo'],
            this.generarid(5),
            element['marca'],
            element['codigo'],
            false
          ))
        }

        if (element['tipo'] == "C" && element['imagen'] != "") {
          // console.log(element);
          this.carrouselSlide.next([new DiferenciaPromo(
            element['abv'],
            element['cantidad1'],
            element['cantidad2'],
            element['desde'],
            element['hasta'],
            element['idpromocion'],
            imgenurl,
            element['monto'],
            element['nombre'],
            element['orden'],
            element['porcentaje'],
            element['regla1'],
            element['regla2'],
            element['sucursal'],
            element['lunes'],
            element['martes'],
            element['miercoles'],
            element['jueves'],
            element['viernes'],
            element['domingo'],
            element['sabado'],
            element['tipo'],
            this.generarid(5),
            element['marca'],
            element['codigo'],
            false
          )]);
          this.getCarrouselSlides();
        }

        const cld = new Cloudinary({
          cloud: {
            cloudName: cloudinaryCloud
          }
        });

        // Use the image with public ID, 'front_face'.
        this.img = cld.image(cloudinaryID);
        cloudinaryID = '';

      })
    }
    ));
  }

  getCarrouselSlides() {
    this.carrouselSlide.subscribe(data => {
      if (data.length != 0) {
        if (this.slidesArray.length == 0) {
          this.slidesArray.push(data[0]);
        } else {
          if (!this.containsSlide(data[0], this.slidesArray)) {
            this.slidesArray.push(data[0]);
          }
        }
      }
      this.slidesArrayObservable.next(this.slidesArray);
    });
  }

  returnSlides() {
    return this.slidesArrayObservable.asObservable();
  }

  containsSlide(obj, list) {
    var i;
    for (i = 0; i < list.length; i++) {
      if (list[i].imagen == obj.imagen) {
        return true;
      }
    }
    return false;
  }

  eliminarPromos(articulo: ArticulosGeneral) {
    articulo.setCantidad(0);
    articulo.setModificacionPrecio(0);
    articulo.getPromos().forEach((promo: PromocionesEccomerce) => {
      promo.resetArticulos();
    });
  }
  /**
   *  
   * @deprecated
   */
  async sumarpromo(promocion: ArticulosGeneral, numero: number) {
    promocion.forzarAddPromo(numero);
  }

  async ajustarrpomo() {
    //console.log(this.promocionesEccomerce);
    /*
    let obsear : Observable<void>[]= []
    
    this.promocionesEccomerce.forEach(promo=>{
      obsear.push(promo.validarPromo)
    });
    console.log(this.promocionesEccomerce);
    
    forkJoin(obsear).pipe(concatAll()).subscribe(data=>{
      
    })*/

    this.sincronizeBackup();


  }

  sincronizeBackup() {
    this.carritoObservables.unshift(this.ajustarCuentas())
  }



  iniciarCarrito() {
    let params = new HttpParams();
    return this.getGenericoSeguro(params, 'itempedidoweb').pipe(tap((data) => {
      this.articulosdelServidor = data['registros'] as BackUptArticulo[];
      this.setBackupToMain(this.articulosdelServidor);
    }))
  }
  async setBackupToMain(backup: BackUptArticulo[]) {
    backup.forEach(element => {
      this.sucursalactiva.articulos.forEach(articulo => {
        if (((element as BackUptArticulo).marca == articulo.getMarca()) && ((element as BackUptArticulo).codigo == articulo.getCodigo())) {
          (articulo as ArticulosGeneral).setCantidad((element as BackUptArticulo).cantidad);
          (articulo as ArticulosGeneral).setCantidadInicial((element as BackUptArticulo).cantidad);
          // console.log(this.promocionesEccomerce);

          this.tienePromoArticulo(articulo).forEach(k => {
            articulo.setPromo(k);
          });

          (articulo as ArticulosGeneral).operacionesExtras();
        }
      });
      /*
      this.promocionesEccomerce.forEach(articulo => {
        if (((element as BackUptArticulo).marca==articulo.getMarca()) && ((element as BackUptArticulo).codigo==articulo.getCodigo())) {
          (articulo as ArticulosGeneral).setCantidad((element as BackUptArticulo).cantidad);
          (articulo as ArticulosGeneral).setCantidadInicial((element as BackUptArticulo).cantidad);
        }
      });*/


    });
  }
  ajustarCuentas() {
    // no tiene cantidad y si tiene cantidad en el backup
    //si viene un precio diferente entre el del backup y el que tengo de la sucursal hay que avisar que aumento
    /*if((articulo as ArticulosGeneral).getCantidad()==0 && (element as BackUptArticulo).cantidad>0)
      {
      }   
    */
    return new Observable<void>(sub => {
      let user = this.user;
      if (user == undefined) {
        user = JSON.parse(localStorage.getItem('currentUser'));
      }
      if (user != undefined) {
        let articulosAlcambio = [];
        this.sucursalactiva.articulos.forEach(articulo => {

          if ((articulo as ArticulosGeneral).getCantidadInicial() != (articulo as ArticulosGeneral).getCantidad()) {
            // console.log(articulo as ArticulosGeneral);

            if //hay una modificacion y hay mas de lo que tenia en el backup
              ((articulo as ArticulosGeneral).getCantidad() > (articulo as ArticulosGeneral).getCantidadInicial()) {
              if (articulo.isdeoferta && !articulo.isdePromo) {
                articulosAlcambio.push({
                  marca: (articulo as ArticulosGeneral).getMarca(),
                  codigo: (articulo as ArticulosGeneral).getCodigo(),
                  cantidad: (articulo as ArticulosGeneral).getCantidad(),
                  precio: (articulo as ArticulosGeneral).getPrecioOferta(),
                  monto: (articulo as ArticulosGeneral).getCantidad() * (articulo as ArticulosGeneral).getPrecioOferta(),
                  reemplazo: articulo.getRemplazo(),

                });

              }
              if (articulo.isdePromo && !articulo.isdeoferta) {

                articulosAlcambio.push({
                  marca: (articulo as ArticulosGeneral).getMarca(),
                  codigo: (articulo as ArticulosGeneral).getCodigo(),
                  cantidad: (articulo as ArticulosGeneral).getCantidad(),
                  precio: (articulo as ArticulosGeneral).getPrecioCalculado() / (articulo as ArticulosGeneral).getCantidad(),
                  monto: (articulo as ArticulosGeneral).getPrecioCalculado(),
                  reemplazo: articulo.getRemplazo(),

                });
                // console.log("Array Promo: ", articulosAlcambio);

              }
              if (!articulo.isdeoferta && !articulo.isdePromo) {

                articulosAlcambio.push({
                  marca: (articulo as ArticulosGeneral).getMarca(),
                  codigo: (articulo as ArticulosGeneral).getCodigo(),
                  cantidad: (articulo as ArticulosGeneral).getCantidad(),
                  precio: (articulo as ArticulosGeneral).getPrecio(),
                  monto: (articulo as ArticulosGeneral).getCantidad() * (articulo as ArticulosGeneral).getPrecio(),
                  reemplazo: articulo.getRemplazo(),

                });
              }
              if (articulo.isdePromo && articulo.isdeoferta) {

                articulosAlcambio.push({
                  marca: (articulo as ArticulosGeneral).getMarca(),
                  codigo: (articulo as ArticulosGeneral).getCodigo(),
                  cantidad: (articulo as ArticulosGeneral).getCantidad(),
                  precio: (articulo as ArticulosGeneral).getPrecioCalculado() / (articulo as ArticulosGeneral).getCantidad(),
                  monto: (articulo as ArticulosGeneral).getPrecioCalculado(),
                  reemplazo: articulo.getRemplazo(),

                });
                // console.log("Array Promo: ", articulosAlcambio);

              }
              (articulo as ArticulosGeneral).setCantidadInicial((articulo as ArticulosGeneral).getCantidad());

            }
            else  //cantidad inicial es mayor a la nueva
            {
              if (articulo.isdeoferta && !articulo.isdePromo) {
                articulosAlcambio.push({
                  marca: (articulo as ArticulosGeneral).getMarca(),
                  codigo: (articulo as ArticulosGeneral).getCodigo(),
                  cantidad: (articulo as ArticulosGeneral).getCantidad(),
                  precio: (articulo as ArticulosGeneral).getPrecioOferta(),
                  monto: (articulo as ArticulosGeneral).getCantidad() * (articulo as ArticulosGeneral).getPrecioOferta(),
                  reemplazo: articulo.getRemplazo(),

                });

              }
              if (articulo.isdePromo && !articulo.isdeoferta) {

                articulosAlcambio.push({
                  marca: (articulo as ArticulosGeneral).getMarca(),
                  codigo: (articulo as ArticulosGeneral).getCodigo(),
                  cantidad: (articulo as ArticulosGeneral).getCantidad(),
                  precio: (articulo as ArticulosGeneral).getPrecioCalculado() / (articulo as ArticulosGeneral).getCantidad(),
                  monto: (articulo as ArticulosGeneral).getPrecioCalculado(),
                  reemplazo: articulo.getRemplazo(),

                });
                // console.log("Array Promo2: ", articulosAlcambio);

              }
              if (!articulo.isdeoferta && !articulo.isdePromo) {
                articulosAlcambio.push({
                  marca: (articulo as ArticulosGeneral).getMarca(),
                  codigo: (articulo as ArticulosGeneral).getCodigo(),
                  cantidad: (articulo as ArticulosGeneral).getCantidad(),
                  precio: (articulo as ArticulosGeneral).getPrecio(),
                  monto: (articulo as ArticulosGeneral).getCantidad() * (articulo as ArticulosGeneral).getPrecio(),
                  reemplazo: articulo.getRemplazo(),

                });
              }
              if (articulo.isdePromo && articulo.isdeoferta) {

                articulosAlcambio.push({
                  marca: (articulo as ArticulosGeneral).getMarca(),
                  codigo: (articulo as ArticulosGeneral).getCodigo(),
                  cantidad: (articulo as ArticulosGeneral).getCantidad(),
                  precio: (articulo as ArticulosGeneral).getPrecioCalculado() / (articulo as ArticulosGeneral).getCantidad(),
                  monto: (articulo as ArticulosGeneral).getPrecioCalculado(),
                  reemplazo: articulo.getRemplazo(),

                });
                // console.log("Array Promo: ", articulosAlcambio);

              }
              (articulo as ArticulosGeneral).setCantidadInicial((articulo as ArticulosGeneral).getCantidad());
            }

          }

        });
        /*
        this.promocionesEccomerce.forEach(articulo=>{
          if ( (articulo as ArticulosGeneral).getCantidadInicial()!=(articulo as ArticulosGeneral).getCantidad()) {
            
            if //hay una modificacion y hay mas de lo que tenia en el backup
            ((articulo as ArticulosGeneral).getCantidad()>(articulo as ArticulosGeneral).getCantidadInicial()){
              articulosAlcambio.push({
                marca:(articulo as ArticulosGeneral).getMarca(),
                codigo:(articulo as ArticulosGeneral).getCodigo(),
                cantidad:(articulo as ArticulosGeneral).getCantidad(),
                precio:(articulo as ArticulosGeneral).getPrecio(),
                monto:(articulo as ArticulosGeneral).getCantidad()*(articulo as ArticulosGeneral).getPrecio(),
              });
              (articulo as ArticulosGeneral).setCantidadInicial((articulo as ArticulosGeneral).getCantidad());
              
            }
            else  //cantidad inicial es mayor a la nueva
            {
              articulosAlcambio.push({
                marca:(articulo as ArticulosGeneral).getMarca(),
                codigo:(articulo as ArticulosGeneral).getCodigo(),
                cantidad:(articulo as ArticulosGeneral).getCantidad(),
                precio:(articulo as ArticulosGeneral).getPrecio(),
                monto:(articulo as ArticulosGeneral).getCantidad()*(articulo as ArticulosGeneral).getPrecio(),
              });
              (articulo as ArticulosGeneral).setCantidadInicial((articulo as ArticulosGeneral).getCantidad());
            }
      
         }
        })
        */
        // console.log(articulosAlcambio[0]);
        if (articulosAlcambio.length == 0) {
          sub.next();
          sub.complete();
          return;
        }
        this.postGenericoSeguro('itempedidoweb', { articulos: articulosAlcambio }).subscribe(data => {
          sub.next();
          sub.complete();
        })

      }
      sub.next();
      sub.complete();
    });

  }

  getPedidoWeb() {
    let params = new HttpParams();

    return this.getGenericoSeguro(params, 'itempedidoweb').pipe(tap((data) => { }));
  }

  async calcularPromociones() {
    let marcapromo1 = 0;
    let codigopromo1 = 0;

    let marcapromo2 = 0;
    let codigopromo2 = 0;
    this.promocionesEccomerce.sort(this.ordenarPromos);
    this.promocionesEccomerce?.forEach(promo => {
      if (promo.getregla1().length > 0) {
        marcapromo1 = Number.parseInt(promo.getregla1().split('and')[0].trim().split('=')[1]);
        codigopromo1 = Number.parseInt(promo.getregla1().split('and')[1].trim().split('=')[1]);
      }
      if (promo.getregla2().length > 0) {
        marcapromo2 = Number.parseInt(promo.getregla2().split('and')[0].trim().split('=')[1]);
        codigopromo2 = Number.parseInt(promo.getregla2().split('and')[1].trim().split('=')[1]);
      }
      /*
      console.log({
        marcapromo1:marcapromo1,
        codigopromo1:codigopromo1,
        marcapromo2:marcapromo2,
        codigopromo2:codigopromo2
      });*/

      this.sucursalactiva?.articulos?.forEach(k => {

        if (k.getMarca() == marcapromo1 && k.getCodigo() == codigopromo1) {

          promo.setArticulo1(k as ArticulosEcommerce);

          if (promo.getTipo() == "P") {
            k.setPromo(promo);
          }
          // k.setdeOferta(undefined);
        }
        if (k.getMarca() == marcapromo2 && k.getCodigo() == codigopromo2) {
          promo.setArticulo2(k as ArticulosEcommerce);
          if (promo.getTipo() == "P") {
            k.setPromo(promo);
          }
          // k.setdeOferta(undefined);
        }
        let promos = k.getPromos() as PromocionesEccomerce[];
        promos.sort(this.ordenarPromos);
        //console.log(k);

      });
      /*
              marcapromo1 = 0;
              codigopromo1 =0;
            
              marcapromo2=0;
              codigopromo2=0;
            console.log(promo);
      */
      //console.log(promo);


    })

  }
  ordenarPromos = (a: PromocionesEccomerce, b: PromocionesEccomerce): number => {
    if (a.getOrden() > b.getOrden()) {
      return 1;
    }
    if (a.getOrden() < b.getOrden()) {
      return -1;
    }
    return 0;
  }
  getPromociones() {
    let promociones: ArticulosGeneral[] = []
    this.promocionesEccomerce.forEach(k => {
      if (k['articulo1'] != undefined) {
        promociones.push(k);
      }
    })
    return promociones;
  }

  cambiarPagina(event: number) {
    let paginado: ArticulosGeneral[] = []
    this.paginaActual = event;
    for (let index = this.paginaActual * 60; index < ((this.paginaActual + 1) * 60); index++) {
      if (this.resultadosDebusqueda[index] != undefined) {
        paginado.push(this.resultadosDebusqueda[index]);
      }


    }

    this.lastSearch.next(paginado);

  }
  cambiarPaginaOferta(event: number) {
    let paginado: ArticulosGeneral[] = []
    this.paginaActualOfertas = event;
    for (let index = this.paginaActualOfertas * 30; index < ((this.paginaActualOfertas + 1) * 30); index++) {
      if (this.ofertas[index] != undefined) {
        paginado.push(this.ofertas[index]);
      }


    }

    this.lastSearch.next(paginado);

  }
  getPaginas() {
    return this.paginadorProvider.asObservable();
  }
  getPaginasOfertas() {
    return this.paginadorProviderOfertas.asObservable();
  }
  getArrayPaginas(paginas: any[]) {
    this.misPaginas = paginas;
  }
  getPaginaEmitter(emitter: EventEmitter<number>) {
    this.paginaEmitter = emitter;
  }
  cambiarPaginaElegida() {

    this.paginaElegida = {
      nombre: '1',
      numero: 0,
      activa: true
    }

    if (this.paginaEmitter !== undefined) {

      let pagine = 0;
      let index = 0;
      this.misPaginas.forEach(k => {
        k.activa = false;
        if (this.paginaElegida.numero == k.numero) {
          k.activa = true;
          pagine = index;
        }
        index++;
      });

      return this.paginaEmitter.emit(this.paginaElegida.numero);
    }

  }
  /**
   * 
   * @param tag to filter in actual search
   */
  filtrarTag(tag: TagsEcommerce) {
    console.log(tag);
    if (!this.tagsActivos.has(-100)) {
      this.tagsActivos.set(-100, this.resultadosDebusqueda)
    }


    let refiltrado: ArticulosGeneral[] = [];
    this.resultadosDebusqueda.forEach(k => {
      for (let tags of k.getTag()) {
        if (tags.tag == tag.tag) {
          refiltrado.push(k);
          break;
        }
        else {

        }
      }

    });
    this.tagsActivos.set(tag.tag, refiltrado);
    this.resultadosDebusqueda = refiltrado;
    this.paginado();
    console.log(this.resultadosDebusqueda);
    console.log(this.tagsActivos);


  }
  cerrartag(tag: TagsEcommerce) {
    let tasact: number[] = [];
    let refiltrado: ArticulosGeneral[] = [];

    this.tagsActivos.forEach((art, tagnum) => {
      if (tag.tag == tagnum) {
        if (tag.selecionado) {
          tasact.push(tag.tag);
        }
      }
    });

    this.tagsActivos.get(-100).forEach(art => {
      art.getTag().forEach(tagArt => {
        tasact.forEach(tagsActivos => {
          if (tagArt.tag == tagsActivos) {
            refiltrado.push(art)
          }
        })
      })
    })
    if (tasact.length == 0) {
      this.resultadosDebusqueda = this.tagsActivos.get(-100);
    }
    else {
      this.resultadosDebusqueda = refiltrado;
    }

    this.paginado();

  }

  /**
   * 
   * @param event string come ups and start search 
   */
  iniciarbuqueda(event: string) {
    let myBusquedaExtras: ResultadosDeBusquedaInterface = {
      totaldeArticulos: 0,
      textodebusqueda: event,
      tagsEnbusqueda: new Map(),
    };


    let contador = 0;
    this.paginaActual = 0;
    this.fueBusquedaEscrita = true;
    this.resultadosDebusqueda = [];
    let minirsultados: ArticulosGeneral[] = [];
    this.articulos2 = [];
    if (event.length > 1) {
      // console.log(event);

      this.sucursalactiva?.articulos?.forEach(k => {
        // console.log(k);
        if (k.getDescripCion().toLocaleLowerCase().includes(event.toLocaleLowerCase())) {
          this.resultadosDebusqueda.push(k);
          k.getTag().forEach(tag => {
            this.tags.forEach(tagreal => {
              tagreal.selecionado = false;
              if (tag.tag === tagreal.tag) {
                if (!(myBusquedaExtras.tagsEnbusqueda.has(tagreal.tag))) {

                  myBusquedaExtras.tagsEnbusqueda.set(tagreal.tag, tagreal);
                }
              }

            })

          })

          if (contador < 7) {
            minirsultados.push(k);
          }
          contador++;
          myBusquedaExtras.totaldeArticulos++;
        }
      });

      // this.articulosWeb2("1", event, "500", 0, '').subscribe(data => {
      //   console.log(data);

      //   console.log(this.articulos2);

      //   this.sucursalactiva.articulos?.forEach(k => {

      //     if (k.getDescripCion().toLocaleLowerCase().includes(event.toLocaleLowerCase())) {
      //       this.resultadosDebusqueda.push(k);
      //       k.getTag().forEach(tag => {
      //         this.tags.forEach(tagreal => {
      //           tagreal.selecionado = false;
      //           if (tag.tag === tagreal.tag) {
      //             if (!(myBusquedaExtras.tagsEnbusqueda.has(tagreal.tag))) {

      //               myBusquedaExtras.tagsEnbusqueda.set(tagreal.tag, tagreal);
      //             }
      //           }

      //         })

      //       })

      //       if (contador < 7) {
      //         minirsultados.push(k);
      //       }
      //       contador++;
      //       myBusquedaExtras.totaldeArticulos++;
      //     }
      //   })
      // })

      // console.log("1: ", myBusquedaExtras.totaldeArticulos);
      this.resultadosDebusqueda = this.busquedaPorNombre();
      this.numBusqueda = this.resultadosDebusqueda.length;

      myBusquedaExtras.totaldeArticulos = this.numBusqueda;
      // console.log(myBusquedaExtras.totaldeArticulos);


      this.minisearch.next(minirsultados);
      this.paginado();
      this.resultadodeBusqueda.next(myBusquedaExtras);
      // console.log(myBusquedaExtras.tagsEnbusqueda);

      // console.log(articu);
      // });


    } else {
      this.lastSearch.next([]);
      this.paginado();
      this.resultadodeBusqueda.next({
        totaldeArticulos: 0,
        textodebusqueda: '',
      });
    }
  }
  paginado() {
    let paginado: ArticulosGeneral[] = [];
    let paginas = this.resultadosDebusqueda.length / 60;
    // console.log(paginas);

    this.paginadorProvider.next(paginas);
    if (paginas > 1) {
      for (let index = 0; index < 60; index++) {

        paginado.push(this.resultadosDebusqueda[index]);

      }
      this.paginaActual = 0;

    }
    else {
      //this.articulosonPage = this.articulos;
      paginado = this.resultadosDebusqueda;
      this.paginaActual = 0;
    }

    this.lastSearch.next(paginado);
  }

  paginadoSuc() {
    this.resultadosDebusqueda.sort(this.ordenAlfabetico);
    this.paginado();
    this.filterOn = true;

    this.lastSearch.next(this.lastSearch.value);
  }

  getAnchoPagina(ancho: number) {
    this.anchoPagina = ancho;
    return ancho;
  }
  /*
  
  */

  /**
   * puede tener o marca solo o codigo solo
   * 
   * @param marca a buscar
   * @param codigo a buscar
   */

  async busquedaCategoria(categoria: CategoriasWebMenu, categoriaPadre: CategoriasWebMenu) {
    let myBusquedaExtras: ResultadosDeBusquedaInterface = {
      totaldeArticulos: 0,
      textodebusqueda: categoria.nombre,
      categoria: categoriaPadre,
    };
    let fecha = new Date();
    let newArticulos = [];
    this.paginaActual = 0;
    this.fueBusquedaEscrita = false;
    this.resultadosDebusqueda = [];
    this.numBusqueda = 0;
    // console.log(categoria.subCategorias);

    let array = this.mapaPedidos.get(categoria.categoria) || [];
    if (categoria.subCategorias != undefined) {
      categoria.subCategorias.forEach(k => {
        let kmap = this.mapaPedidos.get(k.categoria);
        if (kmap != undefined) {
          array = array.concat(kmap);
        }
        k.subCategorias.forEach(l => {
          let lmap = this.mapaPedidos.get(l.categoria);
          if (lmap != undefined) {
            array = array.concat(lmap);
          }
        })
      })

    }
    if (array.length > 0) {
      // console.log(array);
      // let contador = 0;
      // for (let i = 0; i < array.length; i++) {
      //   const element = array[i];
      //   if (element != undefined) {
      //     contador += 1;
      //   }

      // }
      // console.log("ARTICULOS TOTALES en Array: ", contador);
      let newArray = [];
      for (let i = 0; i < array.length; i++) {
        const element = array[i];
        if (!this.containsObject(element, newArray)) {
          newArray.push(element);
        }
      }


      newArray.forEach(marcacod => {
        // console.log(marcacod);
        if (marcacod) {
          this.sucursalactiva?.articulos.forEach(k => {

            if (k.getMarca() == marcacod.marca && k.getCodigo() == marcacod.codigo) {
              if (k.isdeoferta === true && k.deOferta.fechahasta > fecha.toISOString().split('T')[0]) {
                newArticulos.push(k);
              }
              if (k.isdeoferta === false) {
                newArticulos.push(k);
              }
              // if (k.isdeoferta === true) {
              //   newArticulos.push(k);
              // }
              this.resultadosDebusqueda = newArticulos;
              // console.log(this.resultadosDebusqueda);

              // this.resultadosDebusqueda.push(k);
              if (this.resultadosDebusqueda.length === 0) {
                myBusquedaExtras.totaldeArticulos;
              }
              else {
                myBusquedaExtras.totaldeArticulos++;
              }
              // console.log(myBusquedaExtras.totaldeArticulos);

            }
          })
        }

      });
    }
    // console.log("2: ", myBusquedaExtras.totaldeArticulos);
    this.numBusqueda = this.resultadosDebusqueda.length;
    myBusquedaExtras.totaldeArticulos = this.numBusqueda;

    this.paginado();
    this.resultadodeBusqueda.next(myBusquedaExtras);

    // this.articulosWeb2("1", '', "500", categoria.categoria, '').subscribe(data => {
    //   console.log(data);
    // })
  }

  async iniciarbuquedaPorCodigoMarca(categoria: CategoriasWebMenu, categoriaPadre: CategoriasWebMenu) {
    let myBusquedaExtras: ResultadosDeBusquedaInterface = {
      totaldeArticulos: 0,
      textodebusqueda: categoria.nombre,
      categoria: categoriaPadre,
    };
    let fecha = new Date();
    let newArticulos = [];
    this.paginaActual = 0;
    this.fueBusquedaEscrita = false;
    this.resultadosDebusqueda = [];
    this.numBusqueda = 0;

    let array = this.mapaPedidos.get(categoria.categoria) || [];

    if (categoria.subCategorias != undefined) {
      categoria.subCategorias.forEach(k => {
        array = array.concat(this.mapaPedidos.get(k.categoria));
      })

    }
    if (array.length > 0) {

      array.forEach(marcacod => {
        // console.log(marcacod);
        if (marcacod) {
          this.sucursalactiva?.articulos.forEach(k => {

            if (k.getMarca() == marcacod.marca && k.getCodigo() == marcacod.codigo) {
              if (k.isdeoferta === true && k.deOferta.fechahasta > fecha.toISOString().split('T')[0]) {
                newArticulos.push(k);
              }
              if (k.isdeoferta === false) {
                newArticulos.push(k);
              }
              // if (k.isdeoferta === true) {
              //   newArticulos.push(k);
              // }
              this.resultadosDebusqueda = newArticulos;
              // console.log(this.resultadosDebusqueda);

              // this.resultadosDebusqueda.push(k);
              if (this.resultadosDebusqueda.length === 0) {
                myBusquedaExtras.totaldeArticulos;
              }
              else {
                myBusquedaExtras.totaldeArticulos++;
              }
              // console.log(myBusquedaExtras.totaldeArticulos);

            }
          })
        }

      });
    }
    // console.log("3: ", myBusquedaExtras.totaldeArticulos);
    this.numBusqueda = this.resultadosDebusqueda.length;
    myBusquedaExtras.totaldeArticulos = this.numBusqueda;
    // console.log(this.numBusqueda);

    // console.log(this.resultadosDebusqueda);

    let paginado: ArticulosGeneral[] = [];
    //console.log('numero de paginas');
    let paginas = this.resultadosDebusqueda.length / 60;
    this.paginadorProvider.next(paginas);
    //console.log(paginas)
    if (paginas > 1) {
      for (let index = 0; index < 60; index++) {
        paginado.push(this.resultadosDebusqueda[index]);
      }
      this.paginaActual = 0;
    }
    else {
      //this.articulosonPage = this.articulos;
      paginado = this.resultadosDebusqueda;
      this.paginaActual = 0;
    }
    this.lastSearch.next(paginado);
    this.resultadodeBusqueda.next(myBusquedaExtras);
  }

  async busquedaXMarca(categoria: CategoriasWebMenu) {
    let myBusquedaExtras: ResultadosDeBusquedaInterface = {
      totaldeArticulos: 0,
      textodebusqueda: categoria.nombre
    };
    let fecha = new Date();
    let newArticulos = [];
    this.paginaActual = 0;
    this.fueBusquedaEscrita = false;
    this.resultadosDebusqueda = [];
    this.numBusqueda = 0;

    this.sucursalactiva?.articulos.forEach(k => {
      if (k.marcareal == categoria.marca) {
        if (k.getMarcaReal() == k.marcareal && k.getCodigo() == k.codigo) {
          if (k.isdeoferta === true && k.deOferta.fechahasta > fecha.toISOString().split('T')[0]) {
            newArticulos.push(k);
          }
          if (k.isdeoferta === false) {
            newArticulos.push(k);
          }
          this.resultadosDebusqueda = newArticulos;

          if (this.resultadosDebusqueda.length === 0) {
            myBusquedaExtras.totaldeArticulos;
          }
          else {
            myBusquedaExtras.totaldeArticulos++;
          }
        }
      }
    })

    this.numBusqueda = this.resultadosDebusqueda.length;
    myBusquedaExtras.totaldeArticulos = this.numBusqueda;

    let paginado: ArticulosGeneral[] = [];
    let paginas = this.resultadosDebusqueda.length / 60;
    this.paginadorProvider.next(paginas);

    if (paginas > 1) {
      for (let index = 0; index < 60; index++) {
        paginado.push(this.resultadosDebusqueda[index]);
      }
      this.paginaActual = 0;
    }
    else {
      paginado = this.resultadosDebusqueda;
      this.paginaActual = 0;
    }
    this.lastSearch.next(paginado);
    this.resultadodeBusqueda.next(myBusquedaExtras);
  }

  getNumBusqueda() {
    return this.numBusqueda;
  }

  generarid(dimencion: number): string {
    let result = '';
    let characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for (let i = 0; i < dimencion; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  /*
   Providers
  */
  getUsuarioProvider() {
    return this.userProvider.asObservable();
  }
  getSucursalesProvider() {
    return this.sucursalestemp.asObservable();
  }
  getActualSucursal() {
    return this.sucursalactivatemp.asObservable();
  }
  getLastSearchProvider() {
    return this.lastSearch.asObservable();
  }
  getMiniSearchProvider() {
    return this.minisearch.asObservable();
  }
  getSucursalActiva() {
    return this.sucursalactiva;
  }
  getSucursales() {
    return this.sucursales;
  }
  getFueBusquedaEscrita() {
    return this.fueBusquedaEscrita;
  }

  //https
  getGenericoSeguro(params: HttpParams, ruta: string) {
    let environment = localStorage.getItem('environment')
    let user = JSON.parse(localStorage.getItem('currentUser'))

    if (this.environmenturl != undefined) {
      return this.httpClient.get(this.environmenturl.toString() + ruta, {
        headers: { "Authorization": this.user.token }, params
      });
    } else {
      return this.httpClient.get(environment + ruta, {
        headers: { "Authorization": user.token }, params
      });
    }
  }

  postGenericoSeguro(url: string, body: object) {
    // console.log("USER: ", this.user);

    return this.httpClient.post(this.environmenturl + url, body, {
      headers: { "Authorization": this.user.token }
    });

  }
  putGenericoSeguro(url: string, body: object) {
    return this.httpClient.put(this.environmenturl + url, body, {
      headers: { "Authorization": this.user.token }
    });

  }
  /*
   Historial Region
  */
  recompraDeHistorial(jistoriaRecompra: HistorialPedidos) {
    this.resetEccomerce();
    jistoriaRecompra.items.forEach(artback => {
      this.sucursalactiva.articulos.forEach(artreal => {
        if (artback.codigo == artreal.getCodigo() && artback.marca == artreal.getMarca()) {
          artreal.setCantidad(artback.cantidad);
          this.tienePromoArticulo(artreal).forEach(k => {
            artreal.setPromo(k);
          })
          artreal.operacionesExtras();
        }

      });
    });
    this.ajustarrpomo();

  }

  getHistorialDePedidos() {
    let httparams = new HttpParams();
    if (!this.pendingPedidos) {
      this.pendingPedidos = true;
      return this.getGenericoSeguro(httparams, 'pedidoweb').pipe(tap(data => {
        this.pendingPedidos = false;
        this.historiaPedidos = data['registros'];
        for (let k of this.historiaPedidos) {
          k.myitemsdetalle = [];
          k.itemsreales = [];

          if (k.monto != 0) {
            for (let arta of k.items) {
              if (arta.precio > 0) {
                if (arta.descripcion[0] === "*") {
                  let newDesc = arta.descripcion.slice(1);
                  arta.descripcion = newDesc;
                }

                let imgenurl = new URL("/assets/logo/promo.png", this.environmenturl).toString();
                let articuloCopy = new ArticulosEcommerce
                  (
                    arta.marca,
                    arta.codigo,
                    arta.precio,
                    arta.precio,
                    0,
                    "",
                    arta.descripcion,
                    0,
                    "",
                    0,
                    0,
                    0,
                    '0',
                    arta.cantidad,
                    0,
                    0,
                    arta.marca,
                    '',
                    0,
                    0,
                    imgenurl,
                    "",
                    -1,
                    [],
                    false,
                    arta.cantidad,

                  );
                articuloCopy.setCantidad(arta.cantidad);
                articuloCopy.setImgDownload(articuloCopy.getImgDownload());
                articuloCopy.setFoto(articuloCopy.getFotoImagen());

                k.myitemsdetalle.push(articuloCopy);
                k.itemsreales.push(articuloCopy);
                // k.monto += articuloCopy.getPrecioCalculado();
                // continue;

              }
              this.sucursalactiva.articulos.forEach(artreal => {
                if (artreal.getMarca() == 2190 && artreal.getCodigo() == 432) {
                  if (artreal.getDescripCion() == "ACEITE CAÑUELAS GIRASOL-OLIVA" && arta.descripcion == "*ACEITE CAÑUELAS GIRASOL-OLIVA") {
                    console.log(arta, artreal);
                  }
                }

                if (arta.codigo == artreal.getCodigo() && arta.marca == artreal.getMarca() && arta.descripcion == artreal.getDescripCion()) {
                  let articuloCopy = new ArticulosEcommerce
                    (
                      artreal.getMarca(),
                      artreal.getCodigo(),
                      arta.precio,
                      arta.precio,
                      artreal.getCantidadMaxima(),
                      artreal.getDescripcionAdicional(),
                      arta.descripcion,
                      0,
                      artreal.getPresentacion(),
                      0,
                      0,
                      0,
                      '0',
                      0,
                      0,
                      0,
                      artreal.getMarcaReal(),
                      '',
                      0,
                      0,
                      artreal.getOtrosPrecios(),
                      artreal.getFotoImagen(),
                      -1,
                      [],
                      false,
                      arta.cantidad
                    );

                  //articuloCopy.operacionesExtras();
                  // artreal.getPromos()
                  /*this.tienePromoArticulo(articuloCopy).forEach(k=>{
                   articuloCopy.setPromo(k);
                  })*/


                  articuloCopy.setCantidad(arta.cantidad);
                  if (artreal.getImgDownload()) {
                    articuloCopy.setImgDownload(artreal.getImgDownload());
                    articuloCopy.setFoto(artreal.getFotoImagen());
                  }


                  k.myitemsdetalle.push(articuloCopy);
                  k.itemsreales.push(articuloCopy);
                }


              })
            }
            let newItemsReales = k.itemsreales;
            let newItemsDetalles = k.myitemsdetalle;
            for (let i = 0; i < k.items.length; i++) {
              const element = k.items[i];
              newItemsReales.splice(i, 1);
              newItemsDetalles.splice(i, 1);
              if (element.marca == parseInt(this.marcaFlete) && element.codigo == parseInt(this.codigoFlete)) {
                let fleteArt = new ArticulosEcommerce(parseInt(this.marcaFlete), parseInt(this.codigoFlete), k.items[k.items.length - 1].monto, k.items[k.items.length - 1].monto, 0, 'FLETE', 'FLETE A DOMICILIO', 1, 'COSTO DE ENVIO', 0, 0, 0, 'FLETES', parseInt(this.marcaFlete), 1000, 1000, k.items[k.items.length - 1].monto, '', 0, k.items[k.items.length - 1].monto, 'FLETE', undefined, 2, [{ nombre: 'No Reemplazar', modo: '2', idcss: 'c9ft', activo: true }]);
                fleteArt.cantiadadpedido = 1;
                k.myitemsdetalle.push(fleteArt);
                k.itemsreales.push(fleteArt);
              }
            }
            // if (k.itemsreales.length < k.items.length) {
            //   let fleteArt = new ArticulosEcommerce(parseInt(this.marcaFlete), parseInt(this.codigoFlete), k.items[k.items.length - 1].monto, k.items[k.items.length - 1].monto, 0, 'FLETE', 'FLETE A DOMICILIO', 1, 'COSTO DE ENVIO', 0, 0, 0, 'FLETES', 1000, 1000, k.items[k.items.length - 1].monto, '', 0, k.items[k.items.length - 1].monto, 'FLETE', undefined, 2, [{ nombre: 'No Reemplazar', modo: '2', idcss: 'c9ft', activo: true }]);
            //   fleteArt.cantiadadpedido = 1;

            //   k.myitemsdetalle.push(fleteArt);
            //   k.itemsreales.push(fleteArt);
            // }
          }
          else {
            this.historiaPedidos = this.historiaPedidos.filter((item) => item.monto !== 0);
          }
        }
        this.historialtemp.next(this.historiaPedidos);
      }));
    } else {
      return empty();
    }
  }
  tienePromoArticulo(artreal: ArticulosGeneral): PromocionesEccomerce[] {
    let mispromos: PromocionesEccomerce[] = [];

    if (artreal.getPromos().length > 0) {
      return [];
    }
    // console.log(this.promocionesEccomerce);

    this.promocionesEccomerce.forEach(promo => {
      if (promo.getCodigoA1() == artreal.getCodigo() && promo.getMarcaA1() == artreal.getMarca()) {
        mispromos.push(promo);
      }
      if (promo.getCodigoA2() == artreal.getCodigo() && promo.getMarcaA2() == artreal.getMarca()) {
        mispromos.push(promo);
      }
    })
    return mispromos;
  }
  getHistorialProvider() {
    return this.historialtemp.asObservable();
  }

  /**
   *  makes a generic get request without headers or security
   * @param params params for Get
   * @param ruta  Url to consult
   */
  //https
  getGenerico(params: HttpParams, ruta: string) {
    return this.httpClient.get(this.environmenturl.toString() + ruta, {
      headers: { "Cache-Control": "max-age=10800" }, params
    });
  }
  postGenerico(url: string, body: object) {
    return this.httpClient.post(this.environmenturl.toString() + url, body);
  }
  putGenerico(url: string, body: object) {
    return this.httpClient.put(this.environmenturl.toString() + url, body);
  }
  loginPost(url: string, body: object) {
    return this.httpClient.post(this.environment.endpoint + url, body);
  }
  private lastObs: string;
  private sucursalactiva: SucursalEcommerce;
  private sucursalactivatemp: BehaviorSubject<SucursalEcommerce>;
  private sucursal_defecto: string;
  private articulosSucursalDefecto: ArticulosGeneral[];

  private sucursales: SucursalEcommerce[];
  private sucursalestemp: BehaviorSubject<SucursalEcommerce[]>;
  private urlmap: string;
  private ciudades: BehaviorSubject<any[]>;

  private environment: { endpoint: string, endpointversion };
  private environmenturl: URL;

  private user: Usuario;
  private userProvider: BehaviorSubject<Usuario>;
  private lastSearch: BehaviorSubject<ArticulosGeneral[]>;
  private minisearch: BehaviorSubject<ArticulosGeneral[]>
  private paginadorProvider: BehaviorSubject<number>
  private paginadorProviderOfertas: BehaviorSubject<number>
  private promocionesEccomerce: PromocionesEccomerce[];
  private contador: number;
  private tareas: Observable<void>[];
  private intervals: number[];
  private idInterval: number;
  private categoriasWeb: CategoriasWebMenu[];
  private marcasDestacadas: MarcasEcommerce[];
  private articulosdelServidor: BackUptArticulo[];
  private carritoObservables: Observable<void>[];
  private idIntervalForCart: number;
  private reset: boolean;
  /*
  busqeuda
  */
  private resultadosDebusqueda: ArticulosGeneral[];
  private numBusqueda: number;
  private paginaActual: number;
  private paginaActualOfertas: number;
  private anchoPagina: number;
  private fueBusquedaEscrita: boolean;

  private remplasosProvider: BehaviorSubject<RemplazosMMax[]>;
  private remplazos: RemplazosMMax[];

  private mypedido: PedidosMmaxEccomerce;
  private mapaPedidos: Map<number, { marca: number, codigo: number, descripcion: string }[]>;
  private resultadodeBusqueda: BehaviorSubject<ResultadosDeBusquedaInterface>

  private montoMinimo: number;
  private pagoDiferido: BehaviorSubject<string>;
  private envioHabilitado: BehaviorSubject<string>;
  private registroHabilitado: BehaviorSubject<string>;
  /*
  historial
  */
  private detalleElegido: HistorialPedidos;
  private historiaPedidos: HistorialPedidos[];
  private historialtemp: BehaviorSubject<HistorialPedidos[]>;
  private imagenModal: string;
  /*
 ofertas
 */
  private ofertasEnlazadas: OfertasEcommerce[];
  private ofertasTemp: BehaviorSubject<OfertasEcommerce[]>;
  private ofertas: ArticulosGeneral[];
  /*
  manejo de pantallas...
  */
  resizeObservable$: Observable<Event>;
  resizeSubscription$: Subscription;
  /*
  tags
  */
  private tags: TagsEcommerce[];
  private tagsRelacion: any[];
  private tagsActivos: Map<number, ArticulosGeneral[]>;
  /*
  mercadolibre
 */

  /*
   personalizacion;
  
  */
  private logoSvg: SafeUrl;
  private iconLogo: string;
  private nombreSitio: string;
  private colores: any;
  private oldSort: any;
  private busqueda: string;
  private filterOn: boolean;
  private paginaEmitter: EventEmitter<number>;
  private misPaginas: any[];
  private paginaElegida: any;
  private lastArtOpened: any;
  private articulos2: ArticulosGeneral[];
  private beneficios: BehaviorSubject<string>;
  private usaTurnos: BehaviorSubject<string>;
  private horasMinimasTurno: BehaviorSubject<string>;
  private carrouselSlide: BehaviorSubject<PromocionesEccomerce[]>;
  private publicKeyMP: string;
  private datosEnvio: any;
  private codigoFlete: string;
  private marcaFlete: string;
  private slidesArray: any[];
  private slidesArrayObservable: BehaviorSubject<any[]>;
  private redes: any[];
  private pendingPedidos: boolean;
  private pendingTurnos: boolean;
  private pendingLogin: boolean;
  private pendingBeneficios: boolean;
  private pendingRestore: boolean;
  private pendingChangePass: boolean;
  private errorMessage: string;
}
