import { Component, Input, OnInit } from '@angular/core';
import { MarianomGeneral } from '../marianom-general.service';

@Component({
  selector: 'foo-mariano-max',
  templateUrl: './foo-mariano-max.component.html',
  styleUrls: ['./foo-mariano-max.component.scss']
})
export class FooMarianoMaxComponent implements OnInit {
  @Input('mode') modo: string;
  constructor(private serv: MarianomGeneral) {
    this.modo = this.modo || 'normal';
  }

  ngOnInit(): void {
  }

  goToRed(red: any) {
    let redes = this.serv.returnRedes();

    var strWindowFeatures = "location=yes,height=570,width=520,scrollbars=yes,status=yes";
    var URL = redes[red] + location.href;
    var win = window.open(URL, "_blank", strWindowFeatures);
  }

}
