
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { GenericoSlideAnim } from '../etc/generico-animation';
import { CheckboxCategorias, CheckboxSubcategoria } from '../etc/categoria-checkbox.interface'
import { RemplazosMMax } from 'projects/ecommerce-generico/src/public-api';
import { MarianomGeneral } from '../marianom-general.service';

@Component({
  selector: 'selector-generico',
  templateUrl: './myselector-generico.component.html',
  styleUrls: ['./myselector-generico.component.scss'],
  animations: [GenericoSlideAnim]
})
export class MyselectorGenericoComponent implements OnInit, OnChanges {
  @Input() catego: CheckboxCategorias;
  @Input() remplazos: RemplazosMMax[];
  @Input() seleRem: number;
  @Input() mode: string;
  @Output() onSelect: EventEmitter<CheckboxSubcategoria>;
  constructor(private serv: MarianomGeneral) {
    this.mode = this.mode || 'normal'
    this.desplegado = this.desplegado || false;

    this.catego = this.catego || { nombre: '', categorias: [] };
    this.onSelect = new EventEmitter();
  }
  ngOnChanges(changes: SimpleChanges): void {
    let mival = changes?.catego?.currentValue;
    if (mival != undefined) {
      this.selecionar(mival);
    }
    //;    
  }
  private selecionar(index: number) {
    if (this.remplazos != undefined) {
      this.remplazos.forEach(k => {
        console.log(k);
        if (k.modo.toString() === index.toString()) {
          // this.nombre = k.nombre;
          this.desplegado = false;
          this.seleRem = index;
        }
      })
    }

  }
  ngOnInit(): void {
    // console.log("\nSELECTOR: ", this.serv.returnSelectorName());
    
    if (this.serv.returnSelectorName() != undefined) {
      this.nombre = this.serv.returnSelectorName();
      // console.log("SE DEFINE UNDEFINED");
      
      this.serv.getSelectorName(undefined);

    } else {
      this.nombre = this.catego.nombre
    }
  }
  onclik(subcate: CheckboxSubcategoria) {    
    this.desplegado = false;
    this.nombre = subcate.nombre;
    this.serv.getSelectorName(this.nombre);
    this.onSelect.emit(subcate);
  }
  onRemplazSelect(event: RemplazosMMax) {
    this.selecionar(Number.parseInt(event.modo));
  }
  desplegar() {
    this.desplegado = !this.desplegado;
  }

  get selecionado() {
    return this.nombre;
  }

  desplegado: boolean
  nombre: string;
}
