<div *ngIf="mode==='normal'">
  <div class="container">
    <div class="carousel">
      <div class="leftSlide" (click)="onPreviousClick()"></div>
      <div class="rightSlide" (click)="onNextClick()"></div>
      <ng-container *ngFor="let slide of slides; let i = index">
        <img *ngIf="i === currentSlide" [src]="slide.src" class="slide" @carouselAnimation />
      </ng-container>

      <div class="noselect mybtn">
        <div *ngFor="let slide of slides; let i = index" class="textocontainer" id="textContainer{{i}}">
          <span *ngIf="i === currentSlide" class="textogrande">
            {{slide.k.nombre}}
          </span>
          <span *ngIf="i === currentSlide" class="textochico">
            {{slide.k.abv}}
          </span>
        </div>
      </div>

    </div>
  </div>
  <div class="roundcontainer">
    <div class="roundedOne" *ngFor="let item of slidePosition">
      <input [id]="item.id" type="checkbox" [checked]="item.activado" (change)="onchange(item)" id="item.id" />
      <label [for]="item.id"></label>
    </div>
  </div>

</div>