import { Component, OnInit } from '@angular/core';
import { ModalService } from 'projects/app-shared/src/public-api';
import { MarianomGeneral } from '../../marianom-general.service';

@Component({
  selector: 'modal-alert-pass',
  templateUrl: './modal-alert-pass.component.html',
  styleUrls: ['./modal-alert-pass.component.scss']
})
export class ModalAlertPassComponent implements OnInit {

  constructor(private modal: ModalService, private serv: MarianomGeneral) { }

  ngOnInit(): void {
  }

  aceptar() {
    this.modal.close('modal-alert-pass');
  }

  get errorMessage() {
    return this.serv.getErrorMessage();
  }

}
