  
<div *ngIf="modo==='normal'">
    
    <div  *ngIf="contador<1; else elseBlock" (click)="subircantidad()" class="incantibtn">
        <div class="textmybtn">
            Agregar
        </div>
    </div>
    
    <ng-template #elseBlock>
        <div class="incantibtn">
            <div  (click)="restarcantidad()" class="menius">
                <div class="icomasmas">
                    -
                </div>
            </div>
            <div class="contado">
                <input type="number" [ngModel]="contador" (keydown)="noPuntoComa($event)" (ngModelChange)="onChange($event)" class="contador"  autocomplete="off"> 
                
            </div>
            <div (click)="subircantidad()" class="plusmin">
                <div class="icomenosmenos">
                    +
                </div>
            </div>
        </div>
    </ng-template>
</div>

<div *ngIf="modo==='detail'">

    <div class="incantibtn detaillbtn">
        <div  (click)="restarcantidad()" class="menius">
            <div class="icomasmas">
                -
            </div>
        </div>
        <div class="contado">
            {{contador}}
        </div>
        <div (click)="subircantidad()" class="plusmin">
            <div class="icomenosmenos">
                +
            </div>
        </div>
    </div>
</div>

<div *ngIf="modo==='promos'">
    
    <div  *ngIf="contador<1; else elseBlock" (click)="subircantidad()" class="incantibtn">
        <div class="textmybtn">
            Agregar
        </div>
    </div>
    
    <ng-template #elseBlock>
        <div  class="incantibtn">
            <div class="textmybtn">
                En Carro.
            </div>
        </div>
    </ng-template>
</div>