import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptorService } from './../app-shared/interceptor.service';
import { EcommerceGenericoModule } from 'projects/ecommerce-generico/src/public-api';
import { GenericoEcommerceModule } from '../app-shared/generico-ecommerce.module';
import { MarianoRoutingModule } from './mariano-max-routing.module';
import { AppSharedModule } from 'projects/app-shared/src/public-api';
import { AppComponent } from './app.component';
import { MmaxGeneralComponent } from './mmax-general/mmax-general.component';
import { MarianoMaxtopbarComponent } from './mariano-maxtopbar/mariano-maxtopbar.component';
import { MmaxhomeLandpageComponent } from './mmaxhome-landpage/mmaxhome-landpage.component';
import { FrecuentesMmaxviewComponent } from './frecuentes-mmaxview/frecuentes-mmaxview.component';
import { MmaxresetPasswordSuccessComponent } from './mmaxreset-password-success/mmaxreset-password-success.component'
import { HelpBarMmaxComponent } from './help-bar-mmax/help-bar-mmax.component';
import {CloudinaryModule} from '@cloudinary/ng';




@NgModule({
  declarations: [
    AppComponent,
    MmaxGeneralComponent,
    MarianoMaxtopbarComponent,
    MmaxhomeLandpageComponent,
    FrecuentesMmaxviewComponent,
    MmaxresetPasswordSuccessComponent,
    HelpBarMmaxComponent
    ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    EcommerceGenericoModule,
    MarianoRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    AppSharedModule,
    GenericoEcommerceModule,
    MarianoRoutingModule,
    CloudinaryModule
  ],
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
