import { Component, OnInit } from '@angular/core';
import { ModalService } from 'projects/app-shared/src/public-api';

@Component({
  selector: 'modal-max-art',
  templateUrl: './modal-max-art.component.html',
  styleUrls: ['./modal-max-art.component.scss']
})
export class ModalMaxArtComponent implements OnInit {

  constructor(private modal: ModalService) { }

  ngOnInit(): void {
  }

  aceptar(){
    this.modal.close('modal-max-art');
  }

}
