<div *ngIf="mode==='normal'">
    <div class="modal">
        <div class="content">
            <ng-content select="[normal]">

            </ng-content>
        </div>
        <div class="backgroundTrue" (click)="closeControl()"></div>
    </div>
</div>

<div *ngIf="mode==='split'">
    <div class="slideactiva">
        <ng-content select="[split]"></ng-content>
    </div>

</div>

<div *ngIf="mode==='noBackground'">
    <div class="modalNotBackground">
        <div class="content">
            <ng-content select="[noBackground]">

            </ng-content>
        </div>
        <div class="backgroundFalse" (click)="closeControl()"></div>
    </div>
</div>