import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MarianomGeneral } from '../../marianom-general.service';
import { ModalService } from 'projects/app-shared/src/public-api';
import { SucursalEcommerce, Usuario } from 'projects/ecommerce-generico/src/public-api';
import { CheckboxSubcategoria } from '../../etc/categoria-checkbox.interface';
import { MenuMmaxInterface } from '../../etc/mmax-menu.interface';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';


@Component({
  selector: 'login-form-mmax',
  templateUrl: './login-form-mmax.component.html',
  styleUrls: ['./login-form-mmax.component.scss']
})
export class LoginFormMmaxComponent implements OnInit {
  @Input('mode') modo: string;
  @Input() registroHab: string;
  @Input() beneficiosTrue: string;
  constructor(private formBuilder: FormBuilder, private modal: ModalService, private serv: MarianomGeneral, private router: ActivatedRoute) {

    this.imagen = '../../assets/misc/Illustración_CompraVIrutal.svg'
    this.icogoogle = '../../assets/icons/google-logo.svg'
    this.icoface = '../../assets/icons/facebook-logo.svg'
    this.preImages = [this.imagen, this.icoface, this.icogoogle];
    this.modo = this.modo || 'normal';
    this.registroHab = this.registroHab || 'none';
    this.beneficiosTrue = this.beneficiosTrue || 'none';
    this.loginok = false;
    this._destroyed$ = new Subject();

  }
  salirMobile() {
    this.serv.navigator.goLandPage(this.router);
  }
  ngOnInit(): void {
    this.router.data.subscribe(data => {
      this.modo = data['mode'] || this.modo || 'normal';

    })
    this.serv.getRegistroHabilitado().pipe(takeUntil(this._destroyed$)).subscribe(data => {
      // console.log(data);

      if (data === "S") {
        this.registroHab = 'flex';
      } else {
        this.registroHab = 'none';
      }
    });

    this.serv.returnBeneficios().subscribe(data => {
      if (data == 'S') {
        this.beneficiosTrue = 'flex';
      } else {
        this.beneficiosTrue = 'none';
      }
    })


    this.preImages.forEach(async k => {
      new Image().src = k;
    });
    this.loginform = this.formBuilder.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]]
    });

  }
  createUser() {
    this.modal.open('create-user-modal-1');
    this.modal.close('login-modal-1');
  }
  resetPass() {
    this.modal.open('send-reset-pass-modal-1');
    this.modal.close('login-modal-1');
  }
  restorePasswordMmax() {
    this.modal.open('restore-pass-modal');
    this.modal.close('login-modal-1');
  }
  cerrarModale(nombre) {
    this.modal.close(nombre);
  }

  submitForm() {

    this.serv.startLogin(this.formFields?.username.value, this.formFields?.password.value).subscribe(data => {
      for (let i = 0; i < this.menus.length; i++) {
        const element = this.menus[i];
        element.activo = false;
      }
      this.loginok = true;
      this.serv.gotoURL('', this.router)
      this.serv.loginok();
      this.openModal();

    }, err => {
      this.serv.pendingFalse();
      this.loginform.setErrors({ incorrect: true })
      this.errorgen = err['error']['mensaje'];
      this.loginok = false;
    });

  }

  openModal() {
    setTimeout(() => {
      // console.log("LOGIN: ", this.loginok);

      if (this.loginok === true && this.serv.getSucursales().length !== 1) {
        setTimeout(() => {
          this.modal.open('sucursal-modal-1');
        }, 1000);

      }
    }, 2000);
  }

  crearCuentea() {
    this.serv.navigator.goCrearAccount(this.router);
  }
  recuperarPassword() {
    this.serv.navigator.goResetPass(this.router);
  }
  get formFields() {
    return this.loginform.controls;
  }
  get menus(): MenuMmaxInterface[] {
    return this.serv.rutas
  }

  loginform: FormGroup;
  preImages: string[];
  imagen: string;
  icoface: string;
  icogoogle: string;
  errorgen: string;
  sucursalesPropias: SucursalEcommerce[];
  sucursalSelecionada: SucursalEcommerce;
  usuario: Usuario;
  loginok: boolean;
  private _destroyed$: Subject<any>;

}
