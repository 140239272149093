import { SafeUrl } from "@angular/platform-browser";
import { OfertasEcommerce } from "../interfaces/ofertas-ecommerce.interface";
import { TagsEcommerce } from "../interfaces/tags-eccomerce.interface";
import { PromocionesEccomerce } from "./promociones-class";

export abstract class ArticulosGeneral {
    nombremarca: string;

    constructor(public marca: number, public codigo: number, public marcareal: number) {

    }
    abstract deOferta: OfertasEcommerce;
    abstract isdeoferta: boolean;
    abstract isdePromo: boolean;
    abstract misPromo: PromocionesEccomerce[];


    getCodigo() {
        return this.codigo;
    }
    getMarca() {
        return this.marca;
    }
    abstract setTag(tag: TagsEcommerce);
    abstract getTag(): TagsEcommerce[];
    abstract operacionesExtras();
    /*imagenes*/
    abstract getImgDownload(): boolean;
    abstract setImgDownload(valor: boolean);

    abstract setMyBlob(body: Blob)
    abstract getMyBlob(): Blob;
    /*todes*/
    abstract getDescripCion();//nombre//marca
    abstract getFotoImagen();
    abstract getCantidad();
    abstract setModificacionPrecio(arg0: number);

    abstract getRemplazo();
    abstract getRemplazos();
    abstract setRemplazo(numero: number);
    /*articulos*/
    abstract setFoto(arg0: SafeUrl);
    abstract getPrecio();
    abstract setdeOferta(ofertas: OfertasEcommerce)
    abstract isdeOferta(): boolean;
    abstract getOtrosPrecios(): string;
    abstract getPrecioPartido(): number;
    abstract getPrecioCalculado(): number;
    abstract getPrecioOferta(): number;

    abstract getPresentacion();
    abstract getNombreMarca();
    abstract getMarcaReal();
    abstract sumarCantPromo(arg0: number);
    abstract agregarCantidad(numero: number);
    abstract setCantidad(arg0: number);
    abstract getPrecioNormal();
    abstract getPrecioMayorista();
    /*promociones*/
    abstract forzarAddPromo(v: number);
    abstract getTipoPromo();
    abstract getTipo();

    abstract setCantidadInicial(v: number);
    abstract getCantidadInicial();
    abstract getPrecioAmostrar();

    abstract getPromos();
    abstract setPromo(k: PromocionesEccomerce);
    abstract getPromosActivas(): string[];
    abstract getDescripcionAdicional();
    abstract getCantidadMaxima();
}