<div normal>
    <modal-default-mmaxapp>
        <div class="tarjetaconfirmar">
            <div class="cerrar" (click)="closeModal()">X</div>
            <div class="anotherbox" *ngIf="articulo !== undefined">
                <div class="boxphone">
                    <div class="ofertarealnigga" *ngIf='articulo.isdeOferta()'>
            
                        <div class="labeloferta">
                            Oferta.
                        </div>
            
                    </div>
                    <div class="ofertarealnigga" *ngIf='articulo.misPromo.length !== 0'>
            
                        <div class="labeloferta">
                            {{articulo.misPromo[0].abv}}
                        </div>
            
                    </div>
            
                    <div class="imag">
                        <img *ngIf="articulo.getImgDownload()" class="imasrc" id="imagsrc" [src]="articulo.getFotoImagen()" loading="lazy"
                            [alt]="articulo.getNombreMarca()">
                    </div>
                    <div class="anotherandanother">
                        <div class="mindesc">
                            {{articulo.getDescripCion()}}
                        </div>
                        <div class="tittle">
                            {{articulo.getPresentacion()}}
                        </div>
                        <div class="preciochico" *ngIf='articulo.misPromo.length !== 0'>
                            {{articulo.getPrecioPartido() | currency}} {{articulo.getOtrosPrecios()}}
                        </div>
                        <!-- <span class="tachado" *ngIf='articulo.misPromo !== undefined'>{{articulo.getPrecio() | currency}}</span> -->
                        <span class="tachado" *ngIf='articulo.isdeOferta()'>{{articulo.getPrecio() | currency}}</span>
                        <div class="precio">
                            <div class="preciogrande" *ngIf='articulo.isdeOferta() && articulo.misPromo.length === 0'>
                                {{articulo.getPrecioOferta() | currency}}
                            </div>
                            <div class="preciogrande" *ngIf='articulo.isdeOferta() && articulo.misPromo.length !== 0'>
                                {{articulo.getPrecioOferta() | currency}}
                            </div>
                            <div class="preciogrande" *ngIf='articulo.misPromo.length !== 0 && articulo.isdeOferta() === false'>
                                {{articulo.getPrecioAmostrar() | currency}}
                            </div>
                            <div class="preciogrande" *ngIf='articulo.isdeOferta() === false && articulo.misPromo.length === 0'>
                                {{articulo.getPrecio() | currency}}
                            </div>
                            <div class="porcantidad">
                                x un
                            </div>
                        </div>
                        <div *ngIf="articulo.getDescripcionAdicional().length != 0" class="descaditional">
                            {{articulo.getDescripcionAdicional()}}
                        </div>
                    </div>
                </div>
                <boton-sumador class="sumador" (quantyEvent)="sumaevent($event)" [contador]="articulo.getCantidad()"></boton-sumador>
        
            </div>
        </div>
    </modal-default-mmaxapp>
</div>