import { trigger, transition, style, animate } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { ImagesInterface } from 'projects/ecommerce-generico/src/public-api';
import { MarianomGeneral } from '../marianom-general.service';
import { Subject } from 'rxjs';
import { takeUntil, first } from 'rxjs/operators';

@Component({
  selector: 'carrusel-generico-view',
  templateUrl: './carrusel-generic-view.component.html',
  styleUrls: ['./carrusel-generic-view.component.scss'],
  animations: [
    trigger('carouselAnimation', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate('300ms', style({ opacity: 1 }))
      ]),
      transition('* => void', [
        animate('300ms', style({ opacity: 0 }))
      ])
    ])
  ],
})
export class CarruselGenericViewComponent implements OnInit {
  @Input() mode: string;

  constructor(private serv: MarianomGeneral) {
    this.mode = this.mode || 'normal';
    this.currentSlide = 0;
    this._destroyed$ = new Subject();
    this.idSlide = 0;
    this.slidePosition = [];
    this.slides = [];
  }
  onswipeleft() {
    //console.log('swipe left');

    this.onPreviousClick();
  }
  onswipeRight() {
    this.onNextClick()
  }
  onPreviousClick() {
    const previous = this.currentSlide - 1;

    this.currentSlide = previous < 0 ? this.slides.length - 1 : previous;
    this.onchange(this.slidePosition[this.currentSlide]);
  }

  onNextClick() {
    const next = this.currentSlide + 1;
    this.currentSlide = next === this.slides.length ? 0 : next;
    this.onchange(this.slidePosition[this.currentSlide]);
  }
  ngOnInit() {
    this.serv.getCarrouselSlide().pipe(takeUntil(this._destroyed$)).subscribe(data => {
      // console.log(data);
      let src;
      data.forEach(k => {
        src = {
          src: k.getFotoImagen(),
          k
        };

        if (src.src != undefined) {
          let imgSplit = src.src.split("/");
          let imgTransformation = "q_auto:good/c_fit";
          src.src = imgSplit[0] + "/" + imgSplit[1] + "/" + imgSplit[2] + "/" + imgSplit[3] + "/" + imgSplit[4] + "/" + imgSplit[5] + "/" + imgTransformation + "/" + imgSplit[8] + "/" + imgSplit[9];
          if (this.slides.length == 0) {
            this.slides.push(src);
            this.preloadImages();
          }
          if (!this.containsObject(src, this.slides)) {
            this.slides.push(src);
            this.preloadImages();
          }
        }
      })
    })

    var automaticSlide = setInterval(() => {
      if (window.location.hash == '#/') {
        this.onNextClick()
      } else {
        clearInterval(automaticSlide)
      }
    }, 10000);

  }

  preloadImages() {
    for (let i = 0; i < this.slides.length; i++) {
      const element = this.slides[i];

      if (element['k'].sucursal != 0) {
        if (element['k'].sucursal != parseInt(this.serv.getSucursalDefecto())) {
          this.slides.splice(i, 1);
        }
      }
    }

    if (this.slides.length != 0) {
      for (const slide of this.slides) {

        new Image().src = slide.src;
        let src = {
          activado: false,
          id: '' + this.idSlide,
          src: slide.src
        }

        if (this.slidePosition.length != 0) {
          for (let index = 0; index < this.slidePosition.length; index++) {
            const element = this.slidePosition[index];

            if (!this.containsObject(src, this.slidePosition)) {
              this.slidePosition.push(src);
              this.idSlide++;
            }
          }
        } else {
          this.slidePosition.push(src);
          this.idSlide++;
        }
      }
      this.slidePosition[0].activado = true;
    }
  }
  onchange(item: SlidePosition) {
    let text = "textContainer";
    let textId;
    let textContainer;
    if (item != undefined) {
      const checkbox = document.getElementById(item.id) as HTMLInputElement;
      if (item.activado) {
        checkbox.checked = true;
      }
    }

    let textArray = [];
    for (let i = 0; i < this.idSlide; i++) {
      textId = text + i;
      textContainer = document.getElementById(textId);
      textArray.push(textContainer);
    }

    textId = text + item.id;
    let textContainerTrue = document.getElementById(textId);

    item.activado = true;
    this.currentSlide = Number.parseInt(item.id);
    this.slidePosition.forEach(k => {
      if (item.id !== k.id) {
        k.activado = false;

        for (let i = 0; i < textArray.length; i++) {
          const element = textArray[i];
          textContainer = element;

          if (element != textContainerTrue) {
            textContainer.style.cssText = "display: none;"
          }
        }
      }
      if (item.id == k.id) {
        textContainerTrue.style.cssText = "display: flex;"
      }
    })

  }

  containsObject(obj, list) {
    var i;
    for (i = 0; i < list.length; i++) {
      if (list[i].src == obj.src) {
        return true;
      }
    }

    return false;
  }

  currentSlide: number;
  slidePosition: SlidePosition[];
  slides: ImagesInterface[];
  private _destroyed$: Subject<any>;
  idSlide: number;
}

interface SlidePosition {
  activado: boolean,
  id: string,
  src: string
}
