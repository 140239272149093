import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ModalService } from 'projects/app-shared/src/public-api';
import { SucursalEcommerce } from 'projects/ecommerce-generico/src/public-api';
import { CheckboxCategorias, CheckboxSubcategoria } from '../../etc/categoria-checkbox.interface';
import { MarianomGeneral } from '../../marianom-general.service';

@Component({
  selector: 'create-user-mmax',
  templateUrl: './create-user-mmax.component.html',
  styleUrls: ['./create-user-mmax.component.scss']
})
export class CreateUserMmaxComponent implements OnInit {
  @Input('mode') modo: string;
  @Input() sucSelector: string;

  constructor(private formBuilder: FormBuilder, private serv: MarianomGeneral, private modal: ModalService, private router: ActivatedRoute) {
    this.imagen = '../../assets/misc/Illustración_CompraVIrutal.svg';
    this.seleccionada = false;
    this.hayErr = false;
    this.sucSelector = this.sucSelector || 'none';
    this.serv.getproviderSucursales().subscribe(data => {
      if (data.length == 1) {
        this.sucSelector = 'none';
      } else {
        this.sucSelector = 'flex';
      }
      let categorias: CheckboxSubcategoria[] = [];
      this.sucursalesPropias = data;
      data.forEach(k => {
        categorias.push({
          nombre: k.nombre,
          activada: false,
          id: k.sucursal.toString(),
        })
      })
      this.sucursales = { categorias: categorias, nombre: 'Sucursales' }
    });
  }

  ngOnInit(): void {
    this.router.data.subscribe(data => {
      this.modo = data['mode'] || this.modo || 'normal';
    });
    new Image().src = this.imagen;

    this.createForm = this.formBuilder.group({
      username: ['', [Validators.minLength(4), Validators.required]],
      dni: ['', [Validators.min(9999), Validators.required]],
      email: ['', [Validators.email, Validators.required]],
      password: ['', [Validators.minLength(8), Validators.required]],
      repeatpass: ['', [Validators.minLength(8), Validators.required]],
      // sucursal: ['', [Validators.requiredTrue]]
    });
    // console.log(this.formFields.dni.errors);


  }
  onSelect(event: CheckboxSubcategoria) {
    this.sucursalSelecionadaSelect = event;
    // console.log("Suc Seleccionada: ", this.sucursalSelecionadaSelect);

    this.sucursalesPropias.forEach(k => {
      if (k.sucursal.toString() === event.id) {
        this.sucursalSelecionada = k;
        // console.log("Sucursal SELECCIONADA: ", this.sucursalSelecionada);

        this.seleccionada = true;
        // console.log(this.formFields.sucursal.value);
      }
    })

  }
  get validScuru(): boolean {
    if (this.sucursalSelecionada == undefined) {
      return true
    }
    else {
      return false;
    }
  }
  salirMobile() {
    this.serv.goLandPage();
    this.serv.navigator.goLandPage(this.router);
  }

  async submitForm() {

    console.log("SE EJECUTA REGISTRO");


    if (this.formFields.repeatpass.value != this.formFields.password.value) {
      console.log("Las contraseñas no coinciden");
      // this.modal.close('create-user-modal-1')
      this.modal.open('modal-pass-error');
    }
    else {
      if (this.seleccionada === false && this.sucSelector == 'flex') {
        this.modal.open('modal-seleccione-suc');
      }
      else {
        let sucursal;
        if (this.sucSelector == 'flex') {
          sucursal = this.sucursalSelecionada.sucursal;
        } else {
          sucursal = this.serv.getSucursalDefecto();
        }

        await this.serv.crearUsuario(this.formFields.username.value, this.formFields.dni.value,
          this.formFields.email.value, this.formFields.password.value, sucursal).toPromise().catch(err => {
            console.log("ERROR REGISTRO");

            console.log(err);
            this.errorForm = err.error.statussalida;
            this.hayErr = true;
            // console.error(err['statusText']);
            this.createForm.reset();
          });

        if (this.hayErr === false) {
          this.serv.goLandPage();
          console.log("Se registro exitosamente!");
          this.modal.close('create-user-modal-1');
          this.modal.open('login-modal-1');
        }
        this.hayErr = false;

      }
    }
  }

  // convenience getter for easy access to form fields
  get formFields() {
    return this.createForm.controls;
  }
  errorForm: string;
  imagen: string;
  createForm: FormGroup;
  sucursalSelecionadaSelect: CheckboxSubcategoria;
  sucursalesPropias: SucursalEcommerce[];
  sucursalSelecionada: SucursalEcommerce;
  sucursales: CheckboxCategorias;
  seleccionada: boolean;
  hayErr: boolean;
}
/*
"nombre":"",
"direccion":"",
"telefonos":"",
"email":"",
"ciudad":"",
"dni":0,
"fechaNacimiento":"1899-31-12",
"sucursal":0,
"password":""

*/