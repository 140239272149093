<div *ngIf="modo==='normal'">

    <section>
        <div class="centro">
            <div class="formpart">
                <div class="anonuncer">
                    <div class="centrado lal">
                        Crear una cuenta.
                    </div>

                    <div class="centrado subtext">
                        Bienvenido a nuestra tienda, para comenzar a disfrutar de nuestros servicios completa el
                        siguiente formulario.
                    </div>
                </div>

                <div class="formsection">
                    <div class="formcontainer">
                        <form [formGroup]="createForm" (ngSubmit)="submitForm()">
                            <label class="labelform">
                                <span class="labell">
                                    Mail
                                </span>
                                <input class="inputform" autocomplete="email" type="email"
                                    [formControlName]="'email'" />
                                <div *ngIf="formFields.email.touched && formFields.email.invalid" class="tooltip">
                                    <div class="tooltiptext" *ngIf="formFields.email.errors.required">
                                        e-mail no puede estar vacio
                                    </div>
                                    <div class="tooltiptext" *ngIf="formFields.email.errors.email">
                                        e-mail invalido
                                    </div>

                                </div>
                            </label>
                            <label class="labelform">
                                <span class="labell">
                                    DNI
                                </span>
                                <input class="inputform" type="number" [formControlName]="'dni'" />
                                <div *ngIf="formFields.dni.touched && formFields.dni.invalid" class="tooltip">
                                    <div class="tooltiptext" *ngIf="formFields.dni.errors.required">
                                        Dni no puede estar vacio.
                                    </div>
                                    <div class="tooltiptext" *ngIf="formFields.dni.errors.min">
                                        este campo debe ser mayor a 6 numeros.
                                    </div>
                                </div>
                            </label>

                            <label class="labelform">
                                <span class="labell">
                                    Nombre Completo
                                </span>
                                <input class="inputform" autocomplete="username" type="text"
                                    [formControlName]="'username'" />
                                <div *ngIf="formFields.username.touched && formFields.username.invalid" class="tooltip">

                                    <div class="tooltiptext" *ngIf="formFields.username.errors.required">
                                        Nombre no puede estar vacio.
                                    </div>

                                    <div class="tooltiptext" *ngIf="formFields.username.errors.minlength">
                                        Nombre debe ser mayor a {{formFields.username.errors.minlength.requiredLength}}
                                        caracteres.
                                    </div>
                                </div>
                            </label>

                            <label class="labelform">
                                <span class="labell">
                                    Contraseña
                                </span>
                                <input class="inputform" type="password" autocomplete="new-password"
                                    [formControlName]="'password'" />
                                <div *ngIf="formFields.password.touched && formFields.password.invalid" class="tooltip">

                                    <div class="tooltiptext" *ngIf="formFields.password.errors.required">
                                        Contraseña no puede estar vacia.
                                    </div>

                                    <div class="tooltiptext" *ngIf="formFields.password.errors.minlength">
                                        Contraseña debe ser mayor a
                                        {{formFields.username.errors.minlength.requiredLength}}.
                                    </div>
                                </div>
                            </label>
                            <label class="labelform">
                                <span class="labell">
                                    Repetir Contraseña
                                </span>
                                <input class="inputform" type="password" autocomplete="new-password"
                                    [formControlName]="'repeatpass'" />
                            </label>
                            <label class="labelform" [ngStyle]="{'display': sucSelector}">
                                <span class="labell">
                                    Elegir Sucursal de preferencia
                                </span>
                                <selector-generico (onSelect)="onSelect($event)" [mode]="'sucursal'"
                                    [catego]="sucursales"></selector-generico>
                            </label>

                            <button [disabled]="!createForm.valid" class="botning" type="submit">Crear Usuario</button>

                        </form>
                    </div>
                </div>
                <label *ngIf="errorForm" class="errorform">*{{errorForm}}</label>
            </div>
            <div class="dibugopart">
                <div class="imagecontainer">
                    <img [src]="imagen">
                </div>

            </div>

        </div>
    </section>
</div>