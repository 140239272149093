<div *ngIf="modo==='normal'">
    <section>
        <div class="contendortablas">

            <div class="tablas">
                <div class="tabla noselect">
                    <div style="height:20px" class="header">
                        Institucional
                    </div>
                    <div class="sub">
                        Nuestra Empresa
                    </div>
                    <div class="sub">
                        Trabajá con nosotros
                    </div>
                    <div class="sub">
                        Proveedores
                    </div>
                </div>
            </div>
            <div class="tablas">
                <div class="tabla noselect">
                    <div style="height:20px" class="header">
                        Institucional
                    </div>
                    <div class="sub">
                        Nuestra Empresa
                    </div>
                    <div class="sub">
                        Trabajá con nosotros
                    </div>
                    <div class="sub">
                        Proveedores
                    </div>
                </div>
            </div>
            <div class="tablas">
                <div class="tabla noselect">
                    <div style="height:20px" class="header">
                        Institucional
                    </div>
                    <div class="sub">
                        Nuestra Empresa
                    </div>
                    <div class="sub">
                        Trabajá con nosotros
                    </div>
                    <div class="sub">
                        Proveedores
                    </div>
                </div>
            </div>
            <div class="tablas">
                <div class="tabla noselect">
                    <div style="height:20px" class="header">
                        Institucional
                    </div>
                    <div class="sub">
                        Nuestra Empresa
                    </div>
                    <div class="sub">
                        Trabajá con nosotros
                    </div>
                    <div class="sub">
                        Proveedores
                    </div>
                </div>
            </div>
            <div class="tablas">
                <div class="tabla noselect">
                    <div style="height:20px" class="header">
                        Institucional
                    </div>
                    <div class="sub">
                        Nuestra Empresa
                    </div>
                    <div class="sub">
                        Trabajá con nosotros
                    </div>
                    <div class="sub">
                        Proveedores
                    </div>
                </div>
            </div>
        </div>
        <div class="socials">
            <div class="header">
                Necesitas Ayuda?
                <div>
                    0800-111-1111
                </div>
            </div>
            <div class="header">
                Escribinos
            </div>

            <div class="socialIcons">
                <div class="icofacebook" (click)="goToRed('facebook')"></div>
                <div class="icoinsta" (click)="goToRed('instagram')"></div>
                <div class="icoyoutube" (click)="goToRed('youtube')"></div>
                <div class="icotwitter" (click)="goToRed('twitter')"></div>
            </div>
        </div>
    </section>
</div>