
import { ArticulosEcommerce } from "../../public-api"
import { ArticulosGeneral } from "./articulos-general.interface";


export abstract class PromocionesEccomerce extends ArticulosGeneral {
    constructor(
        private abv: string,
        private cantidad1: number,
        private cantidad2: number,
        private desde: string,
        private hasta: string,
        private idpromocion: number,
        private imagen: string,
        protected monto: number,
        private nombre: string,
        private orden: number,
        protected porcentaje: number,
        private regla1: string,
        private regla2: string,
        private sucursal: number,
        private lunes: string,
        private martes: string,
        private miercoles: string,
        private jueves: string,
        private viernes: string,
        private domingo: string,
        private sabado: string,
        private tipo: string,
        private idPromo: string,
        marca: number,
        codigo: number,
        protected descargo: boolean,
        protected articulo1?: ArticulosEcommerce,
        protected articulo2?: ArticulosEcommerce,
    ) {
        super(marca, codigo, 0);
        this.counter = 0;
        this.cantidadInicial = 0;
        this.descontado = 0;
    }
    resetArticulos() {
        this.articuloenPromo().promoCiones = [];
        this.articuloenPromo().resetPrecios();
        this.counter = 0;
    }

    getFotoImagen() {
        return this.imagen;
    }

    getMarcaA2() {
        if (this.articulo2 == undefined) {
            return -1;
        }
        return this.articulo2.getMarca() || -1;
    }
    getCodigoA2() {
        if (this.articulo2 == undefined) {
            return -1;
        }
        return this.articulo2.getCodigo() || -1;
    }
    getMarcaA1() {
        if (this.articulo1 == undefined) {
            return -1;
        }
        return this.articulo1.getMarca() || -1;
    }
    getCodigoA1() {
        if (this.articulo1 == undefined) {
            return -1;
        }
        return this.articulo1.getCodigo() || -1;
    }
    abstract getDescontadoTotal(): number;
    abstract getDescontado(): number;
    abstract getPrecio();
    abstract getPrecioNormal();
    abstract getPrecioAmostrar();
    abstract getPrecioOferta(): number;

    abstract getPresentacion();
    abstract preconPromo(precio: number, cantidad: number, modificacionActual: number);

    getCantidadInicial() {
        return this.cantidadInicial;
    }
    protected esDoblePromo(): boolean {
        let esdoble: boolean = true;

        if (this.articulo1 == undefined) {
            esdoble = false;
        }
        if (this.articulo2 == undefined) {
            esdoble = false;
        }
        return esdoble;
    }
    articuloenPromo(): ArticulosEcommerce {
        if (this.articulo1 != undefined) {
            return this.articulo1;
        }
        if (this.articulo2 != undefined) {
            return this.articulo2;
        }
        return undefined;
    }
    cantidadenPromo(): number {
        if (this.articulo1 != undefined) {
            return this.cantidad1;
        }
        if (this.articulo2 != undefined) {
            return this.cantidad2;
        }
    }


    sumarCantPromo(arg0: number) {
        throw new Error("Method not implemented.");
    }
    abstract agregarCantidad(numero: number);

    abstract setCantidad(arg0: number);

    forzarAddPromo(v: number) {
        throw new Error("Method not implemented.");
    }

    setCantidadInicial(v: number) {
        throw new Error("Method not implemented.");
    }
    getPromos() {
        return [];
    }
    abstract getCantidad();
    getDescripCion() {
        return this.nombre || '----------';
    }
    getOrden() {
        return this.orden;
    }

    getTipoPromo() {
        return this.abv;
    }
    getTipo() {
        return this.tipo;
    }
    getregla1() {
        return this.regla1 || '';
    }
    getregla2() {
        return this.regla2 || '';
    }
    getID(): string {
        return this.idPromo;
    }

    setArticulo2(k: ArticulosEcommerce) {
        this.articulo2 = k;
    }
    setArticulo1(k: ArticulosEcommerce) {
        this.articulo1 = k;
    }
    getNombreMarca() {
        let presentacion = '';
        if (this.esDoblePromo()) {
            presentacion += this.articulo1.getNombreMarca();
            presentacion += '/';
            presentacion += this.articulo2.getNombreMarca();
        }
        else {
            presentacion += this.articuloenPromo().getNombreMarca();
        }
        return presentacion;
    }

    abstract hacerCalculos();
    setFoto(arg0: string) {
        this.imagen = arg0;
    }
    protected descontado: number;
    private cantidadInicial: number;
    counter: number;

}
