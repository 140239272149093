import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { TurnosRetiroEccomerce } from 'projects/ecommerce-generico/src/public-api';
import { CheckboxCategorias, CheckboxSubcategoria } from '../etc/categoria-checkbox.interface';
import { MarianomGeneral } from '../marianom-general.service';

@Component({
  selector: 'mmax-turnos-retiro',
  templateUrl: './turnos-retiro.component.html',
  styleUrls: ['./turnos-retiro.component.scss']
})
export class TurnosRetiroComponent implements OnInit {
  @Input('mode') modo: string;
  @Input() displayTurnos: string;
  @Output() turnoSelecionado: EventEmitter<TurnosRetiroEccomerce>;

  constructor(private router: ActivatedRoute, private serv: MarianomGeneral) {

    // this.displayTurnos = this.displayTurnos || 'none';
    this.cargando = true;
    this.turnos = [];
    this.columnas = [];
    this.columnasPaginadas = [];
    this.diasdisponibles = [];
    this.turnoSelecionado = new EventEmitter();
    this.paginero = [];
    this.chebrowleft = '../../assets/icons/chevron-left-outline.svg'
    this.newTurnosDisponibles = [];
    this.newDias = [];
  }

  ngOnInit(): void {
    this.router.data.subscribe(data => {
      this.modo = data['mode'] || this.modo || 'normal';

    });
    //console.log(this.modo);
    this.serv.getTurnes().subscribe(data => {
      let newData = [];

      for (let i = 0; i < data.length; i++) {
        const element = data[i];

        if (element.modo === 0) {
          newData.push(element);
          // this.removeItemFromArr(data, element);
        }
      }

      this.turnos = newData as TurnosRetiroEccomerce[];
      this.pagina = 1;
      this.paginas = this.turnos.length / 2;
      this.prepararturnos();

      this.cargando = false;

    }, err => {
      this.cargando = false;
      this.serv.pendingFalse();
    });


  }
  pasarPagina() {


  }
  private prepararturnos() {
    //console.log(this.turnos);
    //this.columnas = ['09 a 12 hs', '12 a 15 hs', '15 a 18hs'];
    this.columnas = [];
    this.columnasPaginadas = [];
    this.diasdisponibles = [];
    this.turnosDisponibles = [];
    this.dias = [
      {
        nombre: 'Elegir Fecha',
        categorias: []
      }
    ];

    let i = 0;

    this.prepararcolumnas();
    for (let indexe = 0; indexe < this.turnos.length; indexe++) {
      let myday: Turnero = undefined;
      let turnosaso: TurnosRetiroEccomerce[] = [];
      let pagina: DiasTurn[] = [];
      let filaturno: DiasTurn[] = []
      for (let index = 0; index < this.turnos.length; index++) {
        if (this.turnos[indexe].dia == this.turnos[index].dia) {
          turnosaso.push(this.turnos[index]);
        }
      }

      this.columnas.forEach(s => {
        filaturno.push({
          id: this.serv.getId(4),
          dia: s,
          activado: false,
          selecionado: false
        });

      })
      this.columnasPaginadas.forEach(colu => {
        pagina.push({
          id: this.serv.getId(4),
          dia: colu,
          activado: false,
          selecionado: false
        })
      })

      turnosaso.forEach(k => {
        let key = this.horarioToString(k.horainicio) + ' a ' + this.horarioToString(k.horafin);
        filaturno.forEach(f => {
          if (key === f.dia) {
            f.activado = true;
            f.turno = k;
          }
        })
        pagina.forEach(f => {
          if (key === f.dia) {
            f.activado = true;
            f.turno = k;
          }
        })

      })




      myday = { dia: moment(this.turnos[indexe].fecha).locale('es').format('DD[/]MM[/]YYYY'), turnos: filaturno, paginado: pagina };
      if (this.diasdisponibles.length == 0) {
        this.diasdisponibles.unshift(myday);
      }
      else {
        let encontrado: boolean = false;
        this.diasdisponibles.forEach(k => {
          if (k.dia == myday.dia) {
            encontrado = true;
          }
        });
        if (encontrado == false) {
          this.diasdisponibles.unshift(myday);
        }
      }



    }
    this.diasdisponibles.sort(function (a, b) {
      var datesA = a.dia.split("/");
      let numberDatesA = datesA.map(x => parseInt(x));
      var newDatesA = new Date(numberDatesA[2], numberDatesA[1] - 1, numberDatesA[0]);

      var datesB = b.dia.split("/");
      let numberDatesB = datesB.map(x => parseInt(x));
      var newDatesB = new Date(numberDatesB[2], numberDatesB[1] - 1, numberDatesB[0]);

      return newDatesA > newDatesB ? 1 : -1;
    });

    // console.log(this.diasdisponibles);
    

    this.diasdisponibles.forEach(k => {

      k.turnos.forEach(j => {
        if (j.activado == true) {
          this.turnosDisponibles.push(j)
        }
      })

      let diaDisponible: CheckboxSubcategoria = {
        nombre: '',
        activada: false,
        id: '',
      }
      diaDisponible.nombre = k.dia
      diaDisponible.id = (i).toString();
      i++;

      this.dias[0].categorias.push(diaDisponible)
    })

  }

  fechaSeleccionada(filtro: CheckboxSubcategoria) {
    this.newTurnosDisponibles = [];
    this.newDias = [];
    this.dias[0].categorias.forEach(k => {
      k.activada = false;
    });
    this.dias[0].nombre = filtro.nombre;
    filtro.activada = true;

    this.turnosDisponibles.forEach(t => {

      let dia = t.turno.fecha;
      dia = dia.split('-');
      dia = dia[2] + '/' + dia[1] + '/' + dia[0];

      // console.log(dia);

      if (dia == filtro.nombre) {
        // this.displayTurnos = 'flex';
        this.newTurnosDisponibles.push(t);
        this.newDias.push(t.dia);
      }

    });

  }

  cerrarModal() {
    this.serv.loginok();
  }
  onchange(id: DiasTurn) {
    //console.log(this.diasdisponibles);
    id.selecionado = true;
    this.desacTurnos(id);
    this.turnoSelecionado.emit(id.turno);

  }
  async desacTurnos(turnoactivado: DiasTurn) {
    this.diasdisponibles.forEach(k => {
      k.turnos.forEach(turno => {
        if (turnoactivado != turno) {
          turno.selecionado = false;
        }
      })
      if (k.paginado != undefined) {
        k.paginado.forEach(turno => {
          if (turnoactivado != turno) {
            turno.selecionado = false;
          }
        })
      }

    })
  }
  private prepararcolumnas() {
    let index = 0;
    this.turnos.forEach(k => {
      let key = this.horarioToString(k.horainicio) + ' a ' + this.horarioToString(k.horafin);
      if (!this.containsObject(key, this.columnas)) {
        this.columnas.push(key);
      }
    })
    this.columnas.sort();
    
    this.columnas.forEach(keu => {
      if (index < 2) {
        this.columnasPaginadas.push(keu);
      }
      index++;
    })


  }
  /*
  horariosOden = (a:string,b:string): number => {
   return 0
  }*/

  private horarioToString(hora: number) {
    let myhorario = hora.toString();
    let formateado: string = '';
    if (myhorario.length == 3) {//0 1 2 // 900 
      formateado = 0 + myhorario[0];
      if (myhorario[1] !== '0') {
        formateado += ':' + myhorario[1] + myhorario[2];
      }
    }
    if (myhorario.length == 4) {//0 1 2 // 900 
      formateado = myhorario[0] + myhorario[1];
      if (myhorario[2] !== '0') {
        formateado += ':' + myhorario[2] + myhorario[3];
      }
    }


    return formateado
  }

  containsObject(obj, list) {
    var i;
    for (i = 0; i < list.length; i++) {
      if (list[i] === obj) {

        return true;
      }
    }

    return false;
  }

  cargando: boolean;
  turnosDisponibles: any[];
  newTurnosDisponibles: any[];
  turnos: TurnosRetiroEccomerce[];
  diasdisponibles: Turnero[];
  dias: CheckboxCategorias[];
  newDias: any[];
  paginero: Turnero[];
  pagina: number;
  paginas: number;
  columnas: string[];
  columnasPaginadas: string[];
  chebrowleft: string;
  chebrowRight: string;
}
interface Turnero {
  dia: string;
  turnos: DiasTurn[];
  paginado?: DiasTurn[];
}
interface DiasTurn {
  id: string;
  turno?: TurnosRetiroEccomerce,
  activado: boolean,
  selecionado: boolean;
  dia: string,
}