import { Component, OnInit } from '@angular/core';
import { ModalService } from 'projects/app-shared/src/public-api';
import { MarianomGeneral } from '../../marianom-general.service';

@Component({
  selector: 'modal-articulo',
  templateUrl: './modal-articulo.component.html',
  styleUrls: ['./modal-articulo.component.scss']
})
export class ModalArticuloComponent implements OnInit {

  constructor(private serv: MarianomGeneral, private modal: ModalService) {
    this.articuloOpened;
  }

  ngOnInit(): void {
    // console.log(this.articuloOpened);
  }

  get articulo() {
    this.articuloOpened = this.serv.returnArtOpened();
    return this.articuloOpened;
  }

  sumaevent(numero: number) {
     // console.log(this.articulo.getCantidadMaxima(), this.articulo.getCantidad(), numero);
     if (numero > 0) {

      if (this.articulo.getCantidad() == 0) {
        if (!this.serv.isloged()) {
          this.serv.abrirModalLogin();
        }
        else {
          this.articulo.agregarCantidad(numero);
          this.serv.articuloAmostrar(this.articulo);
          // this.serv.navigator.primerArticulo();
        }
      }
      else {

        if (this.articulo.getCantidadMaxima() > this.articulo.getCantidad() || numero < 0 || this.articulo.getCantidadMaxima() == 0 || this.articulo.getCantidadMaxima() > numero && numero != 1) {
       
          if (this.articulo.getCantidadMaxima() == 0 || this.articulo.getCantidadMaxima() > numero) {
            this.articulo.agregarCantidad(numero);
            this.serv.articuloAmostrar(this.articulo);

          } else {
            this.modal.open('modal-max-art');
          }

        } else {
          this.modal.open('modal-max-art');
        }
        // this.serv.navigator.primerArticulo();
      }

    }
    else {

      if (this.articulo.getCantidadMaxima() > this.articulo.getCantidad() || numero < 0 || this.articulo.getCantidadMaxima() == 0 || this.articulo.getCantidadMaxima() > numero && numero != 1) {

        if ((this.articulo.getCantidad() + numero) >= 0 || this.articulo.getCantidadMaxima() > numero) {
          this.articulo.agregarCantidad(numero);
        } else {
          this.modal.open('modal-max-art');
        }

      } else {
        this.modal.open('modal-max-art');
      }

    }
    this.serv.ajustarPromo();
    this.articulo.operacionesExtras();

  }

  closeModal(){
    this.modal.closeAll();
  }

  articuloOpened: any;

}
