import { OfertasEcommerce } from "../interfaces/ofertas-ecommerce.interface";
import { TagsEcommerce } from "../interfaces/tags-eccomerce.interface";
import { PromocionesEccomerce } from "./promociones-class";

export class MontoPromo extends PromocionesEccomerce {
    deOferta: OfertasEcommerce;
    isdeoferta: boolean;
    isdePromo: boolean;
    misPromo: PromocionesEccomerce[];
    sobrante: number;


    setTag(tag: TagsEcommerce) {
        throw new Error("Method not implemented.");
    }
    getTag(): TagsEcommerce[] {
        throw new Error("Method not implemented.");
    }

    getCantidad() {
        return this.counter;
    }
    getRemplazos() {
        throw new Error("Method not implemented.");
    }
    isdeOferta(): boolean {
        return false;
    }
    setdeOferta(ofertas: OfertasEcommerce) {
        throw new Error("Method not implemented.");
    }
    getPromosActivas(): string[] {
        return [];
    }
    getDescripcionAdicional() {
        return ' ';
    };
    getCantidadMaxima() {
        return 0;
    };
    getMarcaReal() {
        return '';
    }

    getDescontadoTotal(): number {
        return this.descontado;
    }
    getDescontado(): number {
        return this.monto;
    }
    preconPromo(precio: number, cantidad: number, modificacionActual: number) {
        let preciototal = precio * cantidad;
        // preciototal-=modificacionActual;
        let arestar = preciototal - this.monto;
        this.descontado += arestar;
        return -arestar;
    }
    operacionesExtras() {
        // console.log('se ejecuto MontoPromo');
    }
    getPrecioCalculado(): number {
        // console.log(this.monto);
        if (this.articuloenPromo() !== undefined) {
            let newMonto = 0;
            let montoTotal;
            if (this.articulo1.isdeOferta() == true) {
                montoTotal = this.articuloenPromo().getPrecioOferta() * this.articuloenPromo().getCantidad();
            } else {
                montoTotal = this.articuloenPromo().getPrecio() * this.articuloenPromo().getCantidad();
            }
            // console.log(this.counter);
            for (let i = 0; i < this.counter; i++) {
                newMonto += this.monto;
            }
            if (this.articuloenPromo().getCantidad() != 0) {
                if ((this.articuloenPromo().getCantidad() % this.cantidadenPromo()) === 0) {
                    // console.log("Sobrante Natural: ", this.articuloenPromo().getCantidad(), this.cantidadenPromo(), (this.articuloenPromo().getCantidad() % this.cantidadenPromo()) === 0);

                    this.sobrante = montoTotal - newMonto;
                } else {
                    let totalArt = 0;
                    for (let i = 0; i < this.cantidadenPromo(); i++) {
                        if (this.articulo1.isdeOferta() == true) {
                            totalArt += this.articuloenPromo().getPrecioOferta();
                        } else {
                            totalArt += this.articuloenPromo().getPrecio();
                        }
                    }
                    if (this.articulo1.isdeOferta() == true) {
                        totalArt = totalArt - this.articuloenPromo().getPrecioOferta();
                    } else {
                        totalArt = totalArt - this.articuloenPromo().getPrecio();
                    }
                    // console.log("Sobrante Artificial: ", montoTotal, totalArt, newMonto);
                    this.sobrante = (montoTotal - totalArt) - newMonto;
                }
                let montoCalculado = montoTotal - this.sobrante;
                // console.log("Monto Calculado: ", montoTotal, this.sobrante, montoCalculado);

                return montoCalculado;
            } else {
                return this.monto;
            }
        }
        else {
            return 0;
        }
    }
    getPrecioMayorista() {
        return 0;
    }

    getPrecioOferta(): number {
        return this.getPrecioOferta();
    }

    setMyBlob(body: Blob) {
        this.myblob = body;
    }
    getMyBlob(): Blob {
        return this.myblob;
    }
    setRemplazo(numero: number) {
        throw new Error("Method not implemented.");
    }
    getOtrosPrecios(): string {
        throw new Error("Method not implemented.");
    }
    getPrecioPartido(): number {
        throw new Error("Method not implemented.");
    }
    getImgDownload(): boolean {
        return this.descargo;
    }
    setImgDownload(valor: boolean) {
        this.descargo = valor;
    }
    setPromo(k: PromocionesEccomerce) {
        throw new Error("Method not implemented.");
    }

    getPrecioNormal() {
        return 0;
    }
    getPrecioAmostrar() {
        return this.getPrecioCalculado();
    }
    getPresentacion() {
        return '';
    }
    agregarCantidad(numero: number) {

        if (this.esDoblePromo()) {
            this.articulo1.agregarCantidad(numero * this.cantidadenPromo())
            this.articulo2.agregarCantidad(numero * this.cantidadenPromo())
        }
        else {
            this.articuloenPromo().agregarCantidad(numero * this.cantidadenPromo())
            this.articuloenPromo().operacionesExtras();
        }
    }
    setCantidad(arg0: number) {
        this.counter = arg0;
    }

    getPrecio() {
        // let cuenta = 0;

        // if (this.esDoblePromo()) {
        //     cuenta = ((this.articulo1.getPrecio()) + (this.articulo2.getPrecio() * this.articulo2.getCantidad())) - this.monto;

        // }
        // else {
        //     if (this.articuloenPromo().getCantidad() > 0) {
        //         cuenta = (this.articuloenPromo().getPrecio() * this.articuloenPromo().getCantidad()) - this.monto * this.counter;
        //     }

        // }
        // // console.log(cuenta*1);
        // console.log(cuenta);

        // return (cuenta / this.counter) * -1;
        return this.getPrecioCalculado() / this.articuloenPromo().getCantidad();
    }

    getRemplazo() {
        throw new Error("Method not implemented.");
    }


    hacerCalculos() {
        let cuenta = 0;
        /*
            if(this.esDoblePromo()) {
                if ((this.articulo1.getCantidad()>0) && (this.articulo2.getCantidad()>0) ) {
                    cuenta = this.monto - (this.articulo1.getPrecio()+this.articulo2.getPrecio()); 
                }   
                
            }
            else{
                if (this.articuloenPromo().getCantidad()>0) {
                    cuenta = this.monto - (this.articuloenPromo().getPrecio()); 
                }
               
            } 
                */
        if (this.esDoblePromo()) {
            cuenta = this.monto * this.counter - ((this.articulo1.getPrecio()) + (this.articulo2.getPrecio() * this.articulo2.getCantidad()));

        }
        else {
            if (this.articuloenPromo().getCantidad() > 0) {
                cuenta = this.monto * this.counter - (this.articuloenPromo().getPrecio() * this.articuloenPromo().getCantidad());
            }

        }
        // console.log(cuenta*1);

        return cuenta;
    }
    setModificacionPrecio(arg0: number) {
        throw new Error("Method not implemented.");
    }
    private myblob: Blob;

}