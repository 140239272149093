import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { MarianomGeneral } from '../marianom-general.service';

@Injectable()
export class NotLoginMmaxGuard implements CanActivate {
  constructor(private serv: MarianomGeneral, private route: ActivatedRoute) {

  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.hasUser();
  }
  hasUser(): Observable<boolean> {

    return new Observable<boolean>(obs => {
      // obs.next(true);

      this.serv.getUserProvider().subscribe(data => {
        //abrir modal.. con el erro de no logearse


        if (data == undefined) {
          obs.next(false);
          // this.serv.navigator.goerror()
          // console.log(window.location);
          // if (window.location.hash == '#/') {
          //   this.serv.navigator.goHistorial(this.route)
          // } else {
          //   this.serv.navigator.notLogin();
          // }
          this.serv.navigator.notLogin();

        }
        else {
          obs.next(true);
        }

      });


    })
  }
}