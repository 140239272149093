<div normal>
    <modal-default-mmaxapp>
        <div class="tarjetaconfirmar">
            <div class="titulo">
                Seguro quiere cerrar sesion?
            </div>
            <div class="botones">
                <button class="buttonpc whibutton" (click)="cancelar()">No</button>
                <button class="buttonpc redbutton spacerRed" (click)="salir()"
                    onclick="window.scrollTo(0, 0)">Si</button>
            </div>
        </div>



    </modal-default-mmaxapp>
</div>