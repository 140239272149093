import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MediosPagos } from 'projects/ecommerce-generico/src/public-api';
import { MenuMmaxInterface } from '../etc/mmax-menu.interface';
import { MarianomGeneral } from '../marianom-general.service';

@Component({
  selector: 'mmax-cierre-carro',
  templateUrl: './mmax-cierre-carro.component.html',
  styleUrls: ['./mmax-cierre-carro.component.scss']
})
export class MmaxCierreCarroComponent implements OnInit {
  @Input('mode') modo: string;
  @Input() displayMedios: string;
  @Input() displayPreMedios: string;
  @Input() displayMP: string;
  @Input() displayPago: string;
  constructor(private serv: MarianomGeneral, private router: ActivatedRoute) {
    this.modo = this.modo || 'normal';
    this.pagosActuales = [];
    this.mercadoP = false;
    this.serv.getPagoDiferido().subscribe(data => {
      this.pagoDiferido = data
    });
    this.displayMedios = this.displayMedios || 'none';
    this.displayMP = this.displayMP || 'none';
    this.displayPago = this.displayPago;

    this.ruta = window.location.hash;
    this.flagDeshabilitado = false;
    this.btnMP = false;
    this.beneficiosTrue = false;
    this.saldoActual = 0;
    this.conSaldo = false;
    this.errorSaldo = false;

    this.serv.returnBeneficios().subscribe(data => {
      if (data == 'S') {
        this.beneficiosTrue = true;
        this.serv.getMovimientosMmax().subscribe(data => {
          this.saldoActual = data[0].saldo_real;
          this.conSaldo = true;
        }, error => {
          this.errorSaldo = true;
          this.serv.pendingFalse();
        })
      }
    })
  }


  ngOnInit(): void {
    // this.deshabilitarF5();
    window.addEventListener("beforeunload", function (e) {

      var confirmationMessage = "LOL";
      e.returnValue = confirmationMessage;
      return confirmationMessage;

    });
    this.deshabilitarBack();

    this.router.data.subscribe(data => {
      this.modo = data['mode'] || this.modo || 'normal';
      // console.log(this.modo);
    });

    if (this.pagoDiferido == "S") {
      this.displayMedios = 'none';
      this.displayPreMedios = 'block';
    }

    if (this.pagoDiferido == "N") {
      this.displayMedios = 'block';
      this.displayPreMedios = 'none';
    }

  }

  deshabilitarBack() {

    // if (window.location.hash != "#/checkout/finalizar") {
    //   window.location.hash = "#/checkout/finalizar";
    // }

    window.onhashchange = function () {
      if (window.location.hash != "#/checkout/finalizar") {
        this.flagDeshabilitado = true;
        window.location.hash = "#/checkout/finalizar";
      }
      return this.flagDeshabilitado;
    }

    if (this.flagDeshabilitado === true) {
      this.serv.navigator.cierreCheckout(this.router);

    }
  }

  volverAtras() {
    // history.go(-1);
    for (let i = 0; i < this.menus.length; i++) {
      const element = this.menus[i];
      element.activo = false;
    }
    this.serv.vaciarCarro();
    this.serv.navigator.goLandPage(this.router);
    this.serv.cerrarUI();
  }

  get menus(): MenuMmaxInterface[] {
    return this.serv.rutas
  }

  siPagar() {

    this.displayMedios = 'block';
    this.displayPreMedios = 'none';

  }

  noPagar() {
    this.serv.navigator.endline(this.router);
    this.serv.reseteccomerce();
  }

  activarMercadoPago(idMP) {
    let publicKey = this.serv.getPublicKeyMP();

    this.mercadoP = true;
    this.displayPago = "none";
    this.displayMP = "flex";
    const mp = new MercadoPago(publicKey, {
      locale: 'es-AR'
    });
    if (!this.btnMP) {
      this.btnMP = true;
      mp.checkout({
        preference: {
          id: idMP
        },
        render: {
          container: '#containerMP',
          label: 'Pagar $' + this.totales.toFixed(2),
          type: 'wallet',
        }
      });
    }
  }
  get articulosconcantidad() {
    return this.serv.getCarrito();
  }
  activarPlan(plan: PagosMMaxPlanes) {
    this.pagosActuales.forEach(k => {

      if (plan != k) {
        k.seleccionado = false;
      }
    })
    plan.seleccionado = true;
    this.myPlan = plan;

  }
  activarMediodepago(medio: MediosPagos) {
    // console.log(this.serv.getMedios());


    this.displayPago = "flex";
    this.displayMP = "none";
    this.serv.getMedios().forEach(k => {
      k.selecionado = false;
    })
    this.mercadoP = false;
    medio.selecionado = true;

    this.mymedios = medio;

    this.serv.obtenerplanesdemedios(this.mymedios.mediopago).subscribe(data => {
      // console.log(data);
      let planes = data['planes'] as PagosMMaxPlanes[];
      this.pagosActuales = [];
      if (planes != undefined) {
        planes.forEach(element => {
          if (element.idcheckout != undefined) {
            this.activarMercadoPago(element.idcheckout)
          }
          this.pagosActuales.push(
            {
              cuotas: element?.cuotas,
              montoCuota: element?.montoCuota,
              nombre: element?.nombre,
              plan: element?.plan,
              id: this.serv.getId(5),
              seleccionado: false,
            }
          )
        });
        if (this.pagosActuales[0] != undefined) {
          this.pagosActuales[0].seleccionado = true;
        }
        this.myPlan = this.pagosActuales[0];
        if (this.myPlan != undefined) {
          this.myPlan.seleccionado = true
        }
      }


    });

  }
  get mysmedios() {
    return this.serv.getMedios() || [];
  }
  get totales() {
    let montoTotal = this.serv.getTotal();
    let arts = this.serv.getCarrito();
    arts.forEach(k => {
      if (k.nombremarca == 'FLETES') {
        montoTotal = montoTotal + k.getPrecio();
      }
    });
    return montoTotal;
  }
  get total() {
    let cantTotal = this.serv.getCantidadTotal();
    let arts = this.serv.getCarrito();
    arts.forEach(k => {
      if (k.nombremarca == 'FLETES') {
        cantTotal = cantTotal + 1;
      }
    });
    return cantTotal;
  }
  pagarYcontinuar() {
    this.error = undefined;
    this.serv.prayandpaidtwo(this.mymedios.mediopago, this.myPlan.plan).subscribe(data => {
      this.serv.navigator.endline(this.router);
      this.serv.reseteccomerce();
    }, err => {

      this.error = err['error']['statussalida'];
    })


  }
  error: string;
  pagosActuales: PagosMMaxPlanes[];
  mymedios: MediosPagos;
  myPlan: PagosMMaxPlanes;
  mercadoP: boolean;
  pagoDiferido: string;
  ruta: string;
  flagDeshabilitado: boolean;
  btnMP: boolean;
  beneficiosTrue: boolean;
  saldoActual: number;
  conSaldo: boolean;
  errorSaldo: boolean;
}
declare var MercadoPago: any;

interface PagosMMaxPlanes {
  cuotas: number,
  montoCuota: number,
  nombre: string,
  plan: number,
  id?: string,
  seleccionado?: boolean,
  idcheckout?: string,
}
