import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HammerModule } from '@angular/platform-browser';
import { AppSharedModule } from 'projects/app-shared/src/public-api';
import { SucursalesmmaxComponent} from './forms/sucursalesmmax/sucursalesmmax.component'
import { LoginFormMmaxComponent} from './forms/login-form-mmax/login-form-mmax.component';
import { CreateUserMmaxComponent} from './forms/create-user-mmax/create-user-mmax.component';
import { ResetPassMmaxComponent} from './forms/reset-pass-mmax/reset-pass-mmax.component';
import { ListaProductosViewComponent } from './lista-productos-view/lista-productos-view.component';
import { ProductosViewComponent } from './productos-view/productos-view.component';
import { UsuarioBudgetMmaxComponent} from './usuario-budget-mmax/usuario-budget-mmax.component'
import { BotonSumadorComponent } from './boton-sumador/boton-sumador.component';
import { CarritoGenericoComponent } from './carrito-generico/carrito-generico.component';
import { CategoriasGenericoComponent } from './categorias-generico/categorias-generico.component';
import { CategViewgenericComponent } from './categ-viewgeneric/categ-viewgeneric.component';
import { SearchViewGenericComponent } from './search-view-generic/search-view-generic.component';
import { MyselectorGenericoComponent } from './myselector-generico/myselector-generico.component';
import { HistorialGenericWiewComponent } from './historial-generic-wiew/historial-generic-wiew.component';
import { CarruselGenericViewComponent} from './carrusel-generic-view/carrusel-generic-view.component';
import { MmaxofertasviewComponent } from './mmax-ofertasview/mmax-ofertasview.component';
import { AyudayPregummaxComponent } from './ayuday-pregummax/ayuday-pregummax.component';
import { HelpfootbarComponent } from './help-foot-bar/helpfootbar.component';
import { MmaxCatalogoComponent } from "./mmax-catalogo/mmax-catalogo.component";
import { MmaxTrackingPedidosComponent } from './mmax-tracking-pedidos/mmax-tracking-pedidos.component';
import { MmaxCheckoutCompraComponent } from './mmax-checkout-compra/mmax-checkout-compra.component';
import { MarcasDestmmaxComponent} from './marcas-destmmax/marcas-destmmax.component';
import { MisdatosmmaxComponent } from './misdatosmmax/misdatosmmax.component';
import { FooMarianoMaxComponent } from './foo-mariano-max/foo-mariano-max.component';
import { MmaxTutorialesComponent } from './mmax-tutoriales/mmax-tutoriales.component';
import { MmaxSearchResultsComponent } from './mmax-search-results/mmax-search-results.component';
import { MmaxLandpageFrameComponent } from './mmax-landpage-frame/mmax-landpage-frame.component';
import { ResumenComprammaxComponent } from './resumen-comprammax/resumen-comprammax.component';
import { TurnosRetiroComponent } from './turnos-retiro/turnos-retiro.component';
import { TurnosRepartoComponent } from './turnos-reparto/turnos-reparto.component';
import { MmaxCierreCarroComponent } from './mmax-cierre-carro/mmax-cierre-carro.component';
import { MmaxEndLineComponent } from './mmax-end-line/mmax-end-line.component';
import { PaginadorMmaxComponent } from './paginador-mmax/paginador-mmax.component';
import { VaciarCarritoModalMmaxComponent } from './modales/vaciar-carrito-modal-mmax/vaciar-carrito-modal-mmax.component';
import { ModalDefaultMmaxComponent } from './modales/modal-default-mmax/modal-default-mmax.component';
import { CambiarsucursalMmaxModalComponent } from './modales/cambiarsucursal-mmax-modal/cambiarsucursal-mmax-modal.component';
import { RemplazoProductosMmaxModalComponent } from './modales/remplazo-productos-mmax-modal/remplazo-productos-mmax-modal.component';
import { NotLoginMmaxGuard } from './etc/not-login-mmax.guard';
import { VaciarMobileMmaxModalComponent } from './modales/vaciar-mobile-mmax-modal/vaciar-mobile-mmax-modal.component';
import { HistorialMMaxComprasComponent } from './historial-mmax-compras/historial-mmax-compras.component';
import { EstadosTrakingMmaxComponent } from './estados-traking-mmax/estados-traking-mmax.component';
import { MmaxInputSelectorComponent } from './mmax-input-selector/mmax-input-selector.component';
import { EnviosFormComponent } from './envios-form/envios-form.component';
import { SendResetPassComponent } from './forms/send-reset-pass/send-reset-pass.component';
import { ConfirmarCheckoutModalComponent } from './modales/confirmar-checkout-modal/confirmar-checkout-modal.component';
import { ModalErrorComponent } from './modales/modal-error/modal-error.component';
import { ModalCompletarPagoComponent } from './modales/modal-completar-pago/modal-completar-pago.component';
import { ModalAgregarProductoComponent } from './modales/modal-agregar-producto/modal-agregar-producto.component';
import { ModalMontoMinimoComponent } from './modales/modal-monto-minimo/modal-monto-minimo.component';
import { ModalAlertPassComponent } from './modales/modal-alert-pass/modal-alert-pass.component';
import { ModalSeleccioneSucComponent } from './modales/modal-seleccione-suc/modal-seleccione-suc.component';
import { ModalPassErrorComponent } from './modales/modal-pass-error/modal-pass-error.component';
import { ModalNoImplementedComponent } from './modales/modal-no-implemented/modal-no-implemented.component';
import { ModalArticuloComponent } from './modales/modal-articulo/modal-articulo.component';
import { ModalMaxArtComponent } from './modales/modal-max-art/modal-max-art.component';
import { MmaxEndLineCpComponent } from './mmax-end-line-cp/mmax-end-line-cp.component';
import { ModalSalirComponent } from './modales/modal-salir/modal-salir.component';
import { BeneficiosMmaxComponent } from './beneficios-mmax/beneficios-mmax.component';
import { RestorePassMmaxComponent } from './forms/restore-pass-mmax/restore-pass-mmax.component';


@NgModule({
  declarations: [
    ListaProductosViewComponent,
    ProductosViewComponent,
    BotonSumadorComponent,
    CarritoGenericoComponent,
    CategoriasGenericoComponent,
    CategViewgenericComponent,
    SearchViewGenericComponent,
    MyselectorGenericoComponent,
    HistorialGenericWiewComponent,
    CarruselGenericViewComponent,
    UsuarioBudgetMmaxComponent,
    LoginFormMmaxComponent,
    ResetPassMmaxComponent,
    CreateUserMmaxComponent,
    SucursalesmmaxComponent,
    MmaxofertasviewComponent,
    AyudayPregummaxComponent,
    HelpfootbarComponent,
    MmaxCatalogoComponent,
    MmaxTrackingPedidosComponent,
    MmaxCheckoutCompraComponent,
    MarcasDestmmaxComponent,
    MisdatosmmaxComponent,
    FooMarianoMaxComponent,
    MmaxTutorialesComponent,
    MmaxSearchResultsComponent,
    MmaxLandpageFrameComponent,
    ResumenComprammaxComponent,
    TurnosRetiroComponent,
    TurnosRepartoComponent,
    MmaxCierreCarroComponent,
    MmaxEndLineComponent,
    PaginadorMmaxComponent,
    VaciarCarritoModalMmaxComponent,
    ModalDefaultMmaxComponent,
    CambiarsucursalMmaxModalComponent,
    RemplazoProductosMmaxModalComponent,
    VaciarMobileMmaxModalComponent,
    HistorialMMaxComprasComponent,
    EstadosTrakingMmaxComponent,
    MmaxInputSelectorComponent,
    EnviosFormComponent,
    SendResetPassComponent,
    ConfirmarCheckoutModalComponent,
    ModalErrorComponent,
    ModalCompletarPagoComponent,
    ModalAgregarProductoComponent,
    ModalMontoMinimoComponent,
    ModalAlertPassComponent,
    ModalSeleccioneSucComponent,
    ModalPassErrorComponent,
    ModalNoImplementedComponent,
    ModalArticuloComponent,
    ModalMaxArtComponent,
    MmaxEndLineCpComponent,
    ModalSalirComponent,
    BeneficiosMmaxComponent,
    RestorePassMmaxComponent,
    
           
  ],
  imports: [
    CommonModule ,
    FormsModule,
    HammerModule,
    ReactiveFormsModule,
    AppSharedModule,
  ],
  exports:[ 
    ProductosViewComponent,
    ListaProductosViewComponent,
    CarritoGenericoComponent,
    CategoriasGenericoComponent,
    SearchViewGenericComponent,
    MyselectorGenericoComponent,
    HistorialGenericWiewComponent,
    CarruselGenericViewComponent,
    UsuarioBudgetMmaxComponent,
    LoginFormMmaxComponent,
    ResetPassMmaxComponent,
    CreateUserMmaxComponent,
    SucursalesmmaxComponent,
    MmaxofertasviewComponent,
    AyudayPregummaxComponent,
    HelpfootbarComponent,
    MmaxCatalogoComponent,
    MmaxTrackingPedidosComponent,
    MmaxCheckoutCompraComponent,
    MarcasDestmmaxComponent,
    MisdatosmmaxComponent,
    FooMarianoMaxComponent,
    MmaxTutorialesComponent,
    MmaxSearchResultsComponent,
    MmaxLandpageFrameComponent,
    ResumenComprammaxComponent,
    TurnosRetiroComponent,
    TurnosRepartoComponent,
    VaciarCarritoModalMmaxComponent,
    VaciarMobileMmaxModalComponent,
    HistorialMMaxComprasComponent,
    ModalErrorComponent,
    ModalNoImplementedComponent,
    ModalArticuloComponent,
    ModalMaxArtComponent,
    ModalSalirComponent,
    BeneficiosMmaxComponent,
    RestorePassMmaxComponent
  ],
  providers : [
    NotLoginMmaxGuard
  ]
})
export class GenericoEcommerceModule { }


