import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MarianomGeneral } from '../marianom-general.service';
import { HistorialPedidos } from 'projects/ecommerce-generico/src/public-api';
import { ModalService } from 'projects/app-shared/src/public-api';

@Component({
  selector: 'historial-mmax-compras',
  templateUrl: './historial-mmax-compras.component.html',
  styleUrls: ['./historial-mmax-compras.component.scss']
})
export class HistorialMMaxComprasComponent implements OnInit {
  @Input() modo: string;
  @Input() loader: string;
  @Input() noCompras: string;
  constructor(private serv: MarianomGeneral, private router: ActivatedRoute, private modal: ModalService) {

    this.modo = this.modo || 'normal';
    this.loader = this.loader || 'none';
    this.noCompras = this.noCompras || 'none';
    this.zeroOrders = false;
    this.serv.getPedidosHistorial().subscribe(data => {

      if (data.length === 0 && this.zeroOrders) {
        this.loader = 'none';
      }
      if (data.length === 0 && !this.zeroOrders) {
        this.loader = 'block';
        this.noCompras = 'block';
        this.zeroOrders = true;
      }
      if (data.length != 0) {
        this.loader = 'none';
        this.noCompras = 'none';
      }

      this.historial = this.serv.ordenarHistorialPorFecha(data);

    });
  }

  async ngOnInit() {
    if (localStorage.getItem('currentUser') == null) {
      this.serv.navigator.goLandPage(this.router);
    } else {

      await this.serv.reobtenerHistorial().toPromise().catch(() => {
        this.serv.pendingFalse();
      });

      this.router.data.subscribe(data => {
        this.modo = data['mode'] || this.modo || 'normal';
      })
    }
  }

  reload() {
    this.serv.reobtenerHistorial().toPromise().catch(() => {
      this.serv.pendingFalse();
    });
  }

  recompra(jistoriaRecompra: HistorialPedidos) {
    this.serv.cargarEstePedidoEnCart(jistoriaRecompra);
    this.serv.navigator.irResumenCompra(this.router)
  }
  verDetalle(detalle: HistorialPedidos) {
    this.serv.pedidoAVer(detalle);
    this.serv.navigator.goDetallePedido(this.router);
  }
  historial: HistorialPedidos[];
  zeroOrders: boolean;
}
