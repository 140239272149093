import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MenuMmaxInterface } from '../etc/mmax-menu.interface';
import { MarianomGeneral } from '../marianom-general.service';

@Component({
  selector: 'app-mmax-end-line',
  templateUrl: './mmax-end-line.component.html',
  styleUrls: ['./mmax-end-line.component.scss']
})
export class MmaxEndLineComponent implements OnInit {
  @Input('mode') modo: string;

  constructor(private serv: MarianomGeneral, private router: ActivatedRoute) {
    this.modo = this.modo || 'normal';
    this.womencart = '../../assets/misc/MujerCarrito.svg';
  }

  ngOnInit(): void {
    this.disableBack();
    this.router.data.subscribe(data => {
      this.modo = data['mode'] || this.modo || 'normal';
    });
  }
  get menus(): MenuMmaxInterface[] {
    return this.serv.rutas
  }
  disableBack(){
    window.onhashchange = function () {
      console.log(window.location.hash);
      
      if (window.location.hash === "#/checkout/finalizar") {   
        console.log("Es igual");
        
        window.location.hash = "#/checkout/finalizado";
      }
    }
  }
  volverAchan() {
    for (let i = 0; i < this.menus.length; i++) {
      const element = this.menus[i];
      element.activo = false;
    }
    // this.serv.reobtenerHistorial();
    this.serv.navigator.goLandPage(this.router);
  }
  womencart: string;
}
