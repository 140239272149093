import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
@Component({
  selector: 'boton-sumador',
  templateUrl: './boton-sumador.component.html',
  styleUrls: ['./boton-sumador.component.scss']
})
export class BotonSumadorComponent implements OnInit {
  @Input() contador: number;
  @Output() quantyEvent: EventEmitter<number>;
  @Input() modo: string;

  constructor() {
    this.quantyEvent = new EventEmitter();
    //this.contador = 0;
    this.modo = this.modo || 'normal'
  }

  ngOnInit(): void {
  }

  subircantidad() {
    // let cantidad = (<HTMLInputElement>document.getElementById('contador'));
    // cantidad.value = (parseInt(cantidad.value) + 1).toString();
    this.quantyEvent.emit(+1);

  }
  onChange(evnt) {
    if (evnt > 0) { this.quantyEvent.emit(evnt - this.contador); }
    else { this.contador = this.contador }
    return;


  }
  restarcantidad() {
    // let cantidad = (<HTMLInputElement>document.getElementById('contador'));
    // cantidad.value = (parseInt(cantidad.value) - 1).toString();
    this.quantyEvent.emit(-1);

  }

  noPuntoComa(event) {

    var e = event || window.event;
    var key = e.keyCode || e.which;

    if (key === 110 || key === 190 || key === 188) {

      e.preventDefault();
    }
  }

  cantidad() {
    let cantidad = (<HTMLInputElement>document.getElementById('contador')).value;
    console.log(<HTMLInputElement>document.getElementById('contador'));

    console.log("RESTA Cont: ", this.contador);
    console.log("SUMA Cant: ", cantidad);

    this.quantyEvent.emit(-this.contador);
    this.quantyEvent.emit(parseInt(cantidad));
    this.contador = parseInt(cantidad);


  }
}
