import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalService } from 'projects/app-shared/src/public-api';
import { ArticulosGeneral, RemplazosMMax } from 'projects/ecommerce-generico/src/public-api';
import { CheckboxCategorias, CheckboxSubcategoria } from '../etc/categoria-checkbox.interface';
import { MarianomGeneral } from '../marianom-general.service';


@Component({
  selector: 'productos-view',
  templateUrl: './productos-view.component.html',
  styleUrls: ['./productos-view.component.scss']
})
export class ProductosViewComponent implements OnInit {
  @Input() articulo: ArticulosGeneral;
  @Input() mode: string;

  @Output() closeGap: EventEmitter<boolean>;

  constructor(private serv: MarianomGeneral, private route: ActivatedRoute, private modal: ModalService) {
    this.mode = this.mode || 'normal';
    this.closeGap = new EventEmitter();

  }

  async ngOnInit(): Promise<void> {

    if (!this.articulo.getImgDownload()) {
      await this.serv.cachearImg(this.articulo).toPromise().then(() => {
        this.articulo.setImgDownload(true);
      })
    }
    this.serv.getRemplasos().subscribe(data => {
      this.misremplasos = data;
    });

    // if (this.mode !== 'buqueda') {
    //   console.log(this.articulo);

    //   let imgArt = document.getElementById('imagsrc') as HTMLImageElement;
    //   console.log(imgArt);


    //   if (imgArt !== null) {

    //     imgArt.addEventListener('load', function () {
    //       console.log("Render OnLoad: " + this.clientWidth + 'x' + this.clientHeight);

    //       if (imgArt.clientWidth > 270) {
    //         console.log("Acomodando imagen...");

    //         imgArt.width = 265;
    //         imgArt.height = 100;
    //       }
    //     })

    //   }

    // }

  }

  iraSeach() {
    this.closeGap.emit(true);

  }
  iraSeachmobile() {
    //this.closeGap.emit(true);
    this.serv.navigator.goSearch(this.route);
  }
  categoriaSelec(event: any) {
    //  this.articulo.setRemplazo(Number.parseInt(event.id));
    console.log(event);

  }
  eliminar() {
    this.serv.eliminarPromos(this.articulo);
    this.serv.sincronizeBackup();

  }
  sumaevent(numero: number) {
    // console.log(this.articulo.getCantidadMaxima(), this.articulo.getCantidad(), numero);

    if (numero > 0) {

      if (this.articulo.getCantidad() == 0) {
        if (!this.serv.isloged()) {
          this.serv.abrirModalLogin();
        }
        else {
          this.articulo.agregarCantidad(numero);
          this.serv.articuloAmostrar(this.articulo);
          // this.serv.navigator.primerArticulo();
        }
      }
      else {

        if (this.articulo.getCantidadMaxima() > this.articulo.getCantidad() || numero < 0 || this.articulo.getCantidadMaxima() == 0 || this.articulo.getCantidadMaxima() > numero && numero != 1) {
          
          if (this.articulo.getCantidadMaxima() > numero || this.articulo.getCantidadMaxima() == 0) {
            this.articulo.agregarCantidad(numero);
            this.serv.articuloAmostrar(this.articulo);

          } else {
            this.modal.open('modal-max-art');
          }

        } else {
          this.modal.open('modal-max-art');
        }
        // this.serv.navigator.primerArticulo();
      }

    }
    else {

      if (this.articulo.getCantidadMaxima() > this.articulo.getCantidad() || numero < 0 || this.articulo.getCantidadMaxima() == 0 || this.articulo.getCantidadMaxima() > numero && numero != 1) {
        
        if ((this.articulo.getCantidad() + numero) >= 0 || this.articulo.getCantidadMaxima() > numero) {
          this.articulo.agregarCantidad(numero);
        } else {
          this.modal.open('modal-max-art');
        }

      } else {
        this.modal.open('modal-max-art');
      }

    }
    this.serv.ajustarPromo();
    this.articulo.operacionesExtras();

  }
  /*
  sumaeventPromo(numero : number){
    //console.log(numero);
    
    //this.serv.sumarPromo(numero,this.promocion);
    //this.articulo.agregarCantidad(numero);

    this.serv.sumarPromo(this.articulo,numero);
    
    //this.serv.ajustarPromo();
  }
  */
  preloadImages() {


    //  console.log(this.articulo.foto);

    //new Image().src = this.articulo.foto;

    // this.articulo.foto
    //mover esto al servicio 
  }

  elidestalle() {
    this.eliminar();
    //this.serv.ajustarPromo();

  }
  //

  openArtModal() {
    this.modal.open("modal-articulo");
  }

  artOpened(art) {
    console.log(art);

    this.serv.artOpened(art);
  }



  misremplasos: RemplazosMMax[];
  remplazoselct: RemplazosMMax;
  gaseosas: CheckboxCategorias[];
  preload: boolean;
}
