<mariano-maxtopbar> </mariano-maxtopbar>
<div>
  <modal-open-point [mode]="'split'" id="articulo-pc-mmax-modal">
    <div split>
      <div class="pepebackground">

      </div>
      <div class="cardcontainer">
        <div class="card">
          <div class="cardcontent">
            <img class="imagen myimg" [src]="imagesrc" loading="lazy">
            <div class="descripcioncard">

              <div class="productoinfo">
                {{presentacion}}.
              </div>
              <div class="infomodal">
                Producto agregado al carrito
              </div>
            </div>

            <div class="unidades">
              {{cantidad}}un
            </div>
          </div>
        </div>

      </div>

    </div>

  </modal-open-point>

  <modal-open-point id="modal-error">
    <div normal>
      <modal-error></modal-error>
    </div>
  </modal-open-point>
  

  <categ-viewgeneric *ngFor="let catego of categorias" [categoria]="catego"></categ-viewgeneric>
  <div class="normalicedscreen" (click)="closeExtras()">

    <router-outlet></router-outlet>
  </div>
</div>