import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalService } from 'projects/app-shared/src/public-api';
import { SucursalEcommerce, Usuario } from 'projects/ecommerce-generico/src/public-api';
import { CheckboxCategorias, CheckboxSubcategoria } from '../../etc/categoria-checkbox.interface';
import { MenuMmaxInterface } from '../../etc/mmax-menu.interface';
import { MarianomGeneral } from '../../marianom-general.service';

@Component({
  selector: 'sucursales-mmax',
  templateUrl: './sucursalesmmax.component.html',
  styleUrls: ['./sucursalesmmax.component.scss']
})
export class SucursalesmmaxComponent implements OnInit {
  @Input('mode') modo: string;
  @Input() oneSuc: string;
  @Input() loader: string;

  constructor(private serv: MarianomGeneral, private router: ActivatedRoute, private modal: ModalService) {
    this.modo = this.modo || 'normal';
    this.nombreSucursal = "Sin Definir";
    this.oneSuc = this.oneSuc || 'none';
    this.sucZero = false;
    this.loader = this.loader || 'none';
    this.serv.getUserProvider().subscribe(data => {
      this.usuario = data;
      if (this.usuario != undefined) {
        let sucursales = this.serv.getSucursales();
        let sucTrue = false;
        for (let i = 0; i < sucursales.length; i++) {
          const element = sucursales[i];
          if (element.sucursal == this.usuario.sucursal) {
            sucTrue = true;
          }
        }
        if (sucTrue == false) {
          this.sucZero = true;
          this.nombreSucursal = (" Sin Definir");
        }
        else {
          this.nombreSucursal = this.usuario.sucursalNombre || ' Sin Definir'
        }
      }
    });

    this.serv.getproviderSucursales().subscribe(data => {
      if (data.length == 1) {
        this.oneSuc = 'none';
      } else {
        this.oneSuc = 'block';
      }
      let categorias: CheckboxSubcategoria[] = [];
      this.sucursalesPropias = data;
      data.forEach(k => {
        categorias.push({
          nombre: k.nombre,
          activada: false,
          id: k.sucursal.toString(),
        })
        // if (k != this.serv.getSucursalActiva()) {
        //   categorias.push({
        //     nombre: k.nombre,
        //     activada: false,
        //     id: k.sucursal.toString(),
        //   })
        // }
      })
      this.sucursales = { categorias: categorias, nombre: 'Sucursales' };
    });

  }

  volvermobile() {
    this.serv.navigator.goLandPage(this.router);
  }
  ngOnInit(): void {
    this.router.data.subscribe(data => {
      this.modo = data['mode'] || this.modo || 'normal';
    });
    this.URLmap();
  }
  irASuc() {
    this.serv.gotoURL('sucursales', this.router);
    this.modal.closeAll();
  }

  URLmap() {
    this.urlmap = this.serv.getURLMap();
    setTimeout(() => {
      if (document.getElementById('map') != null) {
        document.getElementById('map').innerHTML = this.urlmap;
      }
    }, 50);
  };
  onSelect(event: CheckboxSubcategoria) {
    this.sucursalSelecionadaSelect = event;
    this.sucursalesPropias.forEach(k => {
      if (k.sucursal.toString() === event.id) {
        this.sucursalSelecionada = k;
        if (document.getElementById('map') != null) {
          document.getElementById('map').innerHTML = this.sucursalSelecionada.urlmap;
        }
      }
    })
    this.serv.paginadoSuc();
  }

  get menus(): MenuMmaxInterface[] {
    return this.serv.rutas
  }

  get validScuru(): boolean {
    if (this.sucursalSelecionada == undefined) {
      return true;
    }
    else {
      return false;
    }
  }
  async cambiarSucursal() {
    //Hacer todas las validaciones en caso de cambio
    if (localStorage.getItem('currentUser') != null || localStorage.getItem('currentUser') != undefined || this.serv.isloged() == true) {
      if (this.sucursalSelecionada === undefined) {
        this.modal.open('modal-seleccione-suc');
      }
      else {
        this.loader = 'block';
        for (let i = 0; i < this.menus.length; i++) {
          const element = this.menus[i];
          element.activo = false;
        }
        console.log(this.sucursalSelecionada);

        document.getElementById('map').innerHTML = this.sucursalSelecionada.urlmap;
        await this.serv.actualizarSucursal(this.sucursalSelecionada);
        this.loader = 'none';
        this.serv.navigator.goLandPage(this.router);
        this.modal.close('sucursal-modal-1');

      }
    } else {
      this.serv.abrirModalLogin();
    }
  }
  /**/
  sucursalSelecionadaSelect: CheckboxSubcategoria;
  sucursales: CheckboxCategorias;
  sucursalesPropias: SucursalEcommerce[];
  sucursalSelecionada: SucursalEcommerce;
  urlmap: string;
  usuario: Usuario;
  nombreSucursal: string;
  sucZero: boolean;
}
