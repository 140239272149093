<div *ngIf="modo==='normal'">

    <div *ngIf='usuario; else notlogin' class="usuario noselect">
        <div class="icouser pointers" (click)="closebudget()">
            <div class="svgdiv"></div>
        </div>
        <div class="cliente pointers" (click)="closebudget()">
            <div class="clientenombre">
                {{usuario.nombre || usuario.username}}
            </div>
            <div class="puntos pointers">
                <span class="blooded">Retirar en</span>{{ nombreSucursal}}
            </div>
        </div>

        <div *ngIf='desplegado; else elseBlock' class="triangul pointers" (click)="closebudget()"></div>

        <ng-template #elseBlock>
            <div class="inversetriangule pointers" (click)="closebudget()"></div>
        </ng-template>
        <div [@openClose]="desplegado ? 'open' : 'closed'" class="extendible">
            <div class="predictions">
                <div *ngFor="let menu of menus"
                    (click)="closebudget();gotoURL(menu.url, menu.nombre);ejecutar(menu.function)"
                    (click)="indexWindow(menu.url)" class="prediccion">
                    <div class="iconocont">
                        <img [src]="menu.icourl" class="icomobile" [alt]="menu.nombre">
                    </div>
                    <div class="descrip">
                        {{menu.nombre}}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ng-template #notlogin>
        <div (click)="loginStart()" class="iniciodes noselect" [ngStyle]="{'display': login}">
            <div class="icouser pointers">
                <div class="svgdiv"></div>
            </div>
            <div class="clientenombre">
                Iniciar Sesión
            </div>
        </div>

        <div class="container-carga" [ngStyle]="{'display': loader}">
            <div class="carga"></div>
        </div>

    </ng-template>



</div>

<modal-open-point id="login-modal-1">

    <div normal class="mybody">
        <login-form-mmax></login-form-mmax>
        <div class="cerrarLogin" (click)="cerrarModale('login-modal-1')">X</div>
    </div>

</modal-open-point>
<modal-open-point id="modal-salir" class="modal-salir">

    <div normal class="mybody">
        <modal-salir></modal-salir>
    </div>

</modal-open-point>